import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GestionFournisseursRoutingModule} from './gestion-fournisseurs-routing.module';
import {SharedModule} from '../shared/shared.module';
import {CoreModule} from '../core/core.module';
import {GridFournisseurComponent} from './grillefournisseurs/grid-fournisseur.component';
import {ToolbarComponent} from './grillefournisseurs/toolbar/toolbar.component';

import {FournisseurFicheIdentiteComponent} from './fournisseur/fournisseur-sidebar/fournisseur-fiche-identite/fournisseur-fiche-identite.component';
import {CatalogueAchatComponent} from './articles/catalogue-achat/catalogue-achat.component';
import {ArticlesComponent} from './articles/articles.component';
import {CatalogueAchatNonAffecteComponent} from './articles/catalogue-achat-non-affecte/catalogue-achat-non-affecte.component';
import {CatalogueAchatNonAffecteService} from '../core/services/gestion-fournisseurs/catalogue-achat-non-achat.service';
import {DialogPanierComponent} from './articles/catalogue-achat-non-affecte/dialog-panier/dialog-panier.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GestionproduitsModule} from '../gestion-produits/gestionproduits.module';
import {CreerArticleComponent} from './articles/catalogue-achat/creer-article/creer-article.component';

import {
  DxCheckBoxModule,
  DxContextMenuModule,
  DxDataGridModule,
  DxDropDownBoxModule,
  DxLookupModule,
  DxMultiViewModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxProgressBarModule,
  DxScrollViewModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTooltipModule,
  DxTreeViewModule,
} from 'devextreme-angular';
import {FournisseurFilialeGridComponent} from './fournisseur/fournisseur-sidebar/fournisseur-filiale-grid/fournisseur-filiale-grid.component';
import {FilialeFormDialogComponent} from './fournisseur/fournisseur-filiales/filiale-form-dialog/filiale-form-dialog.component';
import {FournisseurSiderbarComponent} from './fournisseur/fournisseur-sidebar/fournisseur-siderbar.component';
import {TableModule} from 'primeng/table';

import {MenuModule} from 'primeng/menu';
import {CheckboxModule} from 'primeng/checkbox';
import {CardModule} from 'primeng/card';
import {TabMenuModule} from 'primeng/tabmenu';
import {MultiSelectModule} from 'primeng/multiselect';
import {SliderModule} from 'primeng/slider';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';

import {StepsModule} from 'primeng/steps';
import {GestionFournisseurService} from '../core/services/gestion-fournisseurs/gestion-fournisseur.service';
import {CatalogueAchatFilialeComponent} from './catalogue-achat-filiale/catalogue-achat-filiale.component';
import {FournisseurCatalogueArticleComponent} from './fournisseur/fournisseur-sidebar/fournisseur-catalogue-article/fournisseur-catalogue-article.component';
import {GridGroupeAchatComponent} from "./groupe-achat/grid-groupe-achat/grid-groupe-achat.component";
import {FicheIdentiteGroupeAchatComponent} from "./groupe-achat/groupe-achat-sidebar/fiche-identite-groupe-achat/fiche-identite-groupe-achat.component";
import {GroupeAchatSidebarComponent} from "./groupe-achat/groupe-achat-sidebar/groupe-achat-sidebar.component";
import {MembreGroupeAchatComponent} from "./groupe-achat/groupe-achat-sidebar/membre-groupe-achat/membre-groupe-achat.component";
import {DialogAddMembreComponent} from "./groupe-achat/groupe-achat-sidebar/membre-groupe-achat/dialog-add-membre/dialog-add-membre.component";
import {FounisseurGroupeAchatComponent} from "./groupe-achat/groupe-achat-sidebar/founisseur-groupe-achat/founisseur-groupe-achat.component";
import {DialogAddFournisseurComponent} from "./groupe-achat/groupe-achat-sidebar/founisseur-groupe-achat/dialog-add-fournisseur/dialog-add-fournisseur.component";
import {RippleModule} from "primeng/ripple";
import {InputTextModule} from "primeng/inputtext";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {DxoScrollBarModule} from "devextreme-angular/ui/nested";


@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    GestionFournisseursRoutingModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TableModule,

    MenuModule,
    CheckboxModule,
    CardModule,
    TabMenuModule,
    MultiSelectModule,
    SliderModule,
    OverlayPanelModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    RippleModule,

    StepsModule,
    DxDataGridModule,
    DxLookupModule,
    DxTagBoxModule,
    GestionproduitsModule,
    DxDataGridModule,
    DxNumberBoxModule,
    ProgressSpinnerModule,
    DxProgressBarModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxScrollViewModule,
    DxPopupModule,
    DxToolbarModule,
    DxCheckBoxModule,
    DxContextMenuModule,
    DxTabsModule,
    DxTooltipModule,
    DxMultiViewModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxoScrollBarModule
  ],
  declarations: [
    GridFournisseurComponent,
    ToolbarComponent, FournisseurSiderbarComponent, FournisseurFicheIdentiteComponent,
    ArticlesComponent,
    CatalogueAchatComponent,
    CatalogueAchatNonAffecteComponent,
    DialogPanierComponent,
    FournisseurFilialeGridComponent,
    FilialeFormDialogComponent,
    CreerArticleComponent,
    CatalogueAchatFilialeComponent,
    FournisseurCatalogueArticleComponent,
    GridGroupeAchatComponent,
    FicheIdentiteGroupeAchatComponent,
    GroupeAchatSidebarComponent,
    MembreGroupeAchatComponent,
    DialogAddMembreComponent,
    FounisseurGroupeAchatComponent,
    DialogAddFournisseurComponent
  ],
  providers: [
    CatalogueAchatNonAffecteService,
    GestionFournisseurService
  ]
})
export class GestionFournisseursModule {

}
