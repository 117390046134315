import {Tournee} from "./tournee-dto";
import {PointDeLivraisonDTO} from "./point-de-livraison-d-t-o";
import {RepasDTO} from "./repas-dto";
import {ContratMenuConviveDTO} from "./contratmenuconvive-dto";
import {ProduitDeclinaisonDTO} from "./produit-declinaison-dto";

export class InfosRoundDto {

  tournee: Tournee;

  order: number;

  pointLivraison: PointDeLivraisonDTO;

  deliveryDay: string;

  consumptionDay: string;

  repas: RepasDTO;

  prestation: ContratMenuConviveDTO;

  famillesPlats: any[] = [];

  plats: ProduitDeclinaisonDTO[] = [];

  equals = (other: InfosRoundDto): boolean => {
    if (!other) return false;
    let sameFamillesPlats: boolean = true, samePlats: boolean = true;
    if (this.famillesPlats?.length && other.famillesPlats?.length && this.famillesPlats?.length === other.famillesPlats?.length) {
      for (let i = 0; i < this.famillesPlats.length; i++)
        if (other.famillesPlats[i].libelle !== other.famillesPlats[i].libelle) { sameFamillesPlats = false; break; }
    } else {
      sameFamillesPlats = false;
    }

    if (this.plats?.length && other.plats?.length && this.plats?.length === other.plats?.length) {
      for (let i = 0; i < this.plats.length; i++)
        if (other.plats[i].libelle !== other.plats[i].libelle) { samePlats = false; break; }
    } else {
      samePlats = false;
    }

    return this.consumptionDay === other.consumptionDay && this.deliveryDay === other.deliveryDay && this.order === other.order && this.pointLivraison?.id === other.pointLivraison?.id &&
           this.prestation?.libelle === other.prestation?.libelle && this.repas?.id === other.repas?.id && this.tournee?.id === other.tournee?.id && sameFamillesPlats && samePlats;
  }

}
