import {ModeleDeclinaisonNbUcDto} from "./modele-declinaison-nb-uc-dto";
import {InstructionDeclinaisonModel} from "../../../models/gestion-conditionnements/instruction-declinaison";
import {ModelePoidsUcDto} from "./modele-poids-uc-dto";

export class ModeleImplementationMcpDto {

  id: number;

  udpMcpId: number;

  mcpId: number;

  produitId: number;

  uniteAConditionnerId: number;

  remarques: string;

  nbUcByDeclinaisons: ModeleDeclinaisonNbUcDto[] = [];

  poidsUcByDeclinaisons: ModelePoidsUcDto[] = [];

  instructions: InstructionDeclinaisonModel[] = [];

  udpLibelle: string;

  mcpLibelle: string;

  mode: string;

  update: boolean;

}
