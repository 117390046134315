import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';

import {catchError, delay, filter, map, switchMap} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Credentials} from './credentials';
import {
  AUTH,
  HAS_ADMINISTRATION,
  HAS_FACTURATION,
  HAS_GESTION_ADMINISTRATION,
  HAS_GESTION_IDISTRI,
  HAS_GESTION_PLC,
  HAS_GESTION_UDP,
  HAS_GESTION_UDP_ARTICLES_PREFERES,
  HAS_GESTION_UDP_DENREES_INTERDITES,
  HAS_GESTION_UDP_EQUIPES,
  HAS_GESTION_UDP_FAB,
  HAS_GESTION_UDP_LIV,
  HAS_GESTION_UDP_STOCKS,
  HAS_GESTIONBESOIN,
  HAS_GESTIONCLIENTS,
  HAS_GESTIONCOMMANDES,
  HAS_GESTIONCONTRATS,
  HAS_GESTIONFOURNISSEUR,
  HAS_GESTIONGEMRCN,
  HAS_GESTIONMARCHE,
  HAS_GESTIONMENU,
  HAS_GESTIONPRODUCTION,
  HAS_GESTIONPRODUITS_IAPPRO,
  HAS_GESTIONPRODUITS_IPROD,
  HAS_MONBUREAU,
  HAS_PMS,
  HAS_PORTAIL_ADMIN,
  HAS_PORTAIL_USER,
  HAS_READ_GRAPHQL,
  HAS_ROLE_COMMANDES_FOURNISSEURS_FACTURES,
  HAS_ROLE_COMMANDES_FOURNISSEURS_TABLEAU_DE_BORD,
  HAS_STOCKS,
  MSG_KEY,
  MSG_SEVERITY,
  USER_PREFERENCE,
  USER_TYPE_PREFERENCE
} from '../../constants';

import {Utilisateur} from '../../utilisateur';
import {Router} from '@angular/router';
import {UserHasRight} from './user-has-right';
import {UtilsService} from 'app/core/utils/utils.service';
import {EnvironnementDTO} from '../../dtos/environnement-dto';
import {PreferencesUtilisateurService} from '../preferences-utilisateur.service';
import {RoutemapService} from '../routemap.service';
import {UtilisateurDTO} from '../../dtos/utilisateur-dto';
import {JwtHelperService} from '@auth0/angular-jwt';
import {find as _find} from 'lodash'
import {SiteDTO} from '../../dtos/site-dto';
import {FrontStorageService} from '../storage/front-storage.service';
import {UtilisateurConnexionDTO} from '../../dtos/utilisateur-connexion-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {ToastService} from "../technique/toast.service";


export const URL_GET_USER_INFOS = `dolrest/utilisateur/user-infos`;
export const URL_GET_UTILISATEUR_ENIVIRONNEMENTS = `dolrest/utilisateur/environnements`;
export const URL_POST_SAVE_CONNEXION = `dolrest/utilisateurAuth/connexion/save`;
export const URL_POST_UPDATE_CONNEXION = `dolrest/utilisateurAuth/connexion/update-by-token`;

@Injectable()
export class Auth2Service implements OnDestroy {

  private subExpiredSession: Subscription;


  utilisateur: Utilisateur = this.initUserFromLocalStorage();

  /**
   * variable utilisée pour stocker l'environnement sélectionné dans la liste de selection des environnements en haut à droite de l'écran
   * */
  selectedEnvironnementDTO: EnvironnementDTO = this.initEnvironnementFromLocalStorage();


  private subjectOpenMotDePasseOublieDialog = new Subject();
  openMotDePasseOublieDialog$ = this.subjectOpenMotDePasseOublieDialog.asObservable();

  private subjectUser = new BehaviorSubject<Utilisateur>(this.utilisateur);
  user$: Observable<Utilisateur> = this.subjectUser.asObservable();

  private subjectInitUser = new Subject<Utilisateur>();
  initUser$ = this.subjectInitUser.asObservable();

  private subjectEnvironnement = new BehaviorSubject(this.selectedEnvironnementDTO);
  environnement$ = this.subjectEnvironnement.asObservable();

  arrayEnvironnementDTOs: EnvironnementDTO[] = [];


  isLoggedIn$: Observable<boolean> = this.user$.pipe(
    map(user => !!(user && user.sitePrincipal)));

  hasGestionProduitsIprod$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTIONPRODUITS_IPROD)));

  hasGestionProduitsIapro$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTIONPRODUITS_IAPPRO)));

  hasGestionFournisseurs$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTIONFOURNISSEUR)));

  hasUniteDeProduction$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTION_UDP)));

  hasUniteDeProductionLiv$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTION_UDP_LIV)));

  hasUniteDeProductionPrefere$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_ADMINISTRATION)));

  hasUniteDeProductionFab$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTION_UDP_FAB)));

  hasUniteDeProductionStocks$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTION_UDP_STOCKS)));

  hasUniteDeProductionArticlesPreferes$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTION_UDP_ARTICLES_PREFERES)));

  hasUniteDeProductionDenreesInterdites$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTION_UDP_DENREES_INTERDITES)));

  hasUniteDeProductionEquipes$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTION_UDP_EQUIPES)));

  hasMarche$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTIONMARCHE)));

  hasGestionMenus$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTIONMENU)));

  hasGestionClients$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTIONCLIENTS)));

  hasGestionPlc$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTION_PLC)));

  hasMonBureau$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_MONBUREAU)));

  hasAdministration$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_ADMINISTRATION)));

  hasGestionContrats$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTIONCONTRATS)));

  hasGestionGemrcn$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTIONGEMRCN)));

  hasGestionCommandes$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTIONCOMMANDES)));

  hasCommandesTableauBord$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_ROLE_COMMANDES_FOURNISSEURS_TABLEAU_DE_BORD)));

  hasCommandesFactures$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_ROLE_COMMANDES_FOURNISSEURS_FACTURES)));

  hasGestionProduction$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTIONPRODUCTION)));

  hasGestionBesoins$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTIONBESOIN)));

  hasStocks$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_STOCKS)));

  hasFacturation$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_FACTURATION)));

  hasPortailAdmin$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_PORTAIL_ADMIN)));

  hasPortailUser$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_PORTAIL_USER)));

  hasGestionIDistri$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTION_IDISTRI))
  );

  hasPms$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_PMS))
  );

  hasReadGraphQl$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_READ_GRAPHQL))
  );

  hasGestionAdministration$: Observable<boolean> = this.user$.pipe(
    map(user => this.hasFeature(user, HAS_GESTION_ADMINISTRATION))
  )

  constructor(private http: HttpClient,
              private prefsUserSvc: PreferencesUtilisateurService,
              private utils: UtilsService,
              public routeMapSvc: RoutemapService,
              private router: Router,
              private frontStorageSvc: FrontStorageService,
              private toastSvc: ToastService
  ) {

    this.initEnvironnementFromLocalStorage();
    this.initUserFromLocalStorage();

    this.subExpiredSession = this.utils.expiredSession$.subscribe(expired => {
      if (expired) {
        this.logout();
      }
    });


    // lorsque l'utilisateur change, on recharge son environnement ses preferences par défaut
    this.initUser$.pipe(
      filter(user => user && user.id != -1 && user.username ? true : false),
      switchMap(user => this.getUserInfos(user)),
      switchMap(response => {
        this.utilisateur.sites = response.additionalProperties['sites'] as SiteDTO[];
        this.utilisateur.siteListLocaux = response.additionalProperties['siteListLocaux'] as SiteDTO[];
        this.utilisateur.siteListReferents = response.additionalProperties['siteListReferents'] as SiteDTO[];
        this.utilisateur.sitePrincipal = response.additionalProperties['sitePrincipal'] as SiteDTO[];
        this.utilisateur.iddefaultenvironnement = response.additionalProperties['iddefaultenvironnement'];

        // on enregistre l'utilisateur dans le localStorage
        this.frontStorageSvc.secureLS.set(AUTH.utilisateur, this.utilisateur);

        // on charge la liste des environnements
        const listeEnvironnements$ = this.http.get<EnvironnementDTO[]>(URL_GET_UTILISATEUR_ENIVIRONNEMENTS + `/${this.utilisateur.id}`);
        return listeEnvironnements$;


      }),
      switchMap(response => {
        this.arrayEnvironnementDTOs = response;
        this.selectedEnvironnementDTO = this.arrayEnvironnementDTOs[0];

        const defaultEnvironnement = _find(this.arrayEnvironnementDTOs, {'id': this.utilisateur.iddefaultenvironnement});
        if (!this.utils.isNullOrEmpty(defaultEnvironnement)) {
          this.selectedEnvironnementDTO = defaultEnvironnement;
        }

        this.frontStorageSvc.secureLS.set(AUTH.environnements, this.arrayEnvironnementDTOs);
        this.frontStorageSvc.secureLS.set(AUTH.environnement, this.selectedEnvironnementDTO);


        // on notifie aux composants abonnés de recharger les valeurs associées à l'environnement
        this.announceEnvironnement(this.selectedEnvironnementDTO);

        let utilisateurConnexionDTO: UtilisateurConnexionDTO;
        utilisateurConnexionDTO = new UtilisateurConnexionDTO();
        utilisateurConnexionDTO.utilisateurId = this.utilisateur.id;
        utilisateurConnexionDTO.utilisateurNom = this.utilisateur.nom;
        utilisateurConnexionDTO.utilisateurPrenom = this.utilisateur.prenom;
        utilisateurConnexionDTO.environnement = this.selectedEnvironnementDTO;
        const token_tab = localStorage.getItem(AUTH.token).split('.');
        utilisateurConnexionDTO.token = token_tab[token_tab.length - 1];
        utilisateurConnexionDTO.ip = '';
        return this.saveConnexion(utilisateurConnexionDTO)
      }),
      switchMap(response => {
        return this.prefsUserSvc.getMyPreferences(this.utilisateur.username)
      }),
      catchError(err => this.utils.handleError(err))
    )
      .subscribe(prefs => {
        // annoncer aux abonnés que l'utilisateur a changé
        this.announceUser(this.utilisateur);
        const preferences: any = prefs.one;
        // on assigne les prefs utilisateur du back dans les prefs du front et on notifie tous les abonnés
        this.prefsUserSvc.announceAndAssignUserPrefs(preferences?.preferencesUtilisateur);
        this.prefsUserSvc.announceAndAssignGlobalesPrefs(preferences?.preferencesGlobaux);
        // on stocke les prefs dans le localStorage
        this.frontStorageSvc.secureLS.set(AUTH.preferences, preferences?.preferencesUtilisateur);
        this.frontStorageSvc.secureLS.set(AUTH.preferencesGlobales, preferences?.preferencesGlobaux);
        // on va sur notre page de démarrage
        const startPageKey = this.prefsUserSvc.getPreferenceUtilisateurValue(USER_PREFERENCE.GLOBAL_PREFERENCE_STARTPAGE);
        const startPage = this.prefsUserSvc.getPreferenceUtilisateur(startPageKey);

        this.router.navigate([startPage.valeur]);
      });
  }


  hasFeature(user, userHasRight: UserHasRight): boolean {
    if (user && user.sites && user.sites.length > 0) {
      const roleArr = user.roles.filter(item => item === userHasRight.role);
      if (roleArr && roleArr.length > 0) {
        const licenceArr = user.licence;
        if (licenceArr) {
          return user.licence[userHasRight.licence];
        }
      }
    }
    return false;
  }

  login(credentials: Credentials): Observable<any> {

    const body: any = { 'username': credentials.username, 'password': credentials.password };

    return this.http.post('dolrest/signin', body, {}).pipe(
      catchError(error => this.utils.handleError(error, true)));
  }

  redirectToLoginPage() {

    // en appelant logout, on est redirigé sur la page de login
    // ne pas pointer directement sur /login sinon c'est le routeur qui prend le dessus et conflit avec runGuardsAndResolvers
    this.router.navigate(['/logout']);
  }

  redirectToHomePage() {
    this.router.navigate(['/monbureau']);
  }

  closeSideNav(): void {

    if (!this.utils.isNullOrEmpty(this.utils.sidenav)) {
      this.utils.sidenav = false;
    }
  }


  announceUser(user: Utilisateur) {

    this.subjectUser.next(user);
  }

  announceInitUser(user: Utilisateur) {
    this.subjectInitUser.next(user);
  }

  /**
   * Déconnexion de l'utilisateur en cours
   */
  logout() {

    // si le token d'authentification existe, on peut sauvegarder la preference lastpage à la deconnexion
    if (localStorage.getItem(AUTH.token)) {

      let redirectUrl = '';
      try {
        redirectUrl = this.frontStorageSvc.secureLS.get(AUTH.redirectUrl);
      } catch {
        redirectUrl = localStorage.getItem(AUTH.redirectUrl);
        this.frontStorageSvc.secureLS.set(AUTH.redirectUrl, redirectUrl);
      }


      // on assigne la derniere page consultée à la pref lastpage
      this.prefsUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GLOBAL_PREFERENCE_LASTPAGE, USER_TYPE_PREFERENCE.STRING, redirectUrl);

      // on sauvegarde  les preferences côté back
      this.prefsUserSvc.savePreferencesUtilisateur(null).subscribe();

      // on detruit le token d'authentification
      localStorage.removeItem(AUTH.token);
      localStorage.removeItem(AUTH.refreshToken);
      localStorage.removeItem(AUTH.utilisateur);
      localStorage.removeItem(AUTH.environnement);
      localStorage.removeItem(AUTH.environnements);

    }

    // on annonce aux composants que l'utilisateur est inactif
    if (!this.utils.isNullOrEmpty(this.subjectUser)) {
      const emptyUser = this.createEmptyUser();
      this.announceUser(emptyUser);
    }

    // on redirige sur la page d'accueil et on ferme les sidenavs en cours
    this.redirectToLoginPage();
    this.closeSideNav();
  }


  /**
   * On créé l'utilisateur à partir du token stocké dans le localstorage
   * S'il n'existe pas dans le localStorage,on renvoie unen utilisateur vide aved id à -1
   */
  initUserFromToken(localStorageToken: string): Utilisateur {

    if (this.utils.isNullOrEmpty(localStorageToken)) {
      return this.createEmptyUser();
    } else {
      const jwtHelper = new JwtHelperService();
      const token: any = jwtHelper.decodeToken(localStorageToken);
      const user = new Utilisateur();
      user.id = token.id;
      user.username = token.username;
      user.licence = token.licence;
      user.roles = token.roles;
      user.prenom = token.prenom;
      user.nom = token.nom;
      user.profil = token.profil;

      return user;
    }
  }

  /**
   * On récupére l'environnement de l'utilisateur à partir du localStorage. On charge la liste des environnements auxqules l'utilisateur a droit
   *
   */
  initEnvironnementFromLocalStorage() {

    this.arrayEnvironnementDTOs = this.frontStorageSvc.secureLS.get(AUTH.environnements);
    this.selectedEnvironnementDTO = this.frontStorageSvc.secureLS.get(AUTH.environnement);

    this.selectedEnvironnementDTO = this.utils.preSelectSingleList(this.arrayEnvironnementDTOs, this.selectedEnvironnementDTO);

    return this.selectedEnvironnementDTO;
  }

  /**
   * On créé l'utilisateur à partir du localstorage
   * S'il n'existe pas dans le localStorage,on renvoie un utilisateur vide aved id à -1
   */
  initUserFromLocalStorage(): Utilisateur {

    const user = this.frontStorageSvc.secureLS.get(AUTH.utilisateur);
    return this.utils.isNullOrEmpty(user) ? this.createEmptyUser() : user;
  }

  /**
   * Créer l'utilisateur à partir d'un token d'authorization
   *
   * @param data
   * @returns {UtilisateurDTO}
   */
  initUser(data ?: any): Utilisateur {

    // si on a un token du back, on le stocke dans le localStorage
    if (data) {
      localStorage.setItem(AUTH.token, data);
    }

    this.utilisateur = this.initUserFromToken(localStorage.getItem(AUTH.token));

    this.announceInitUser(this.utilisateur);

    if (this.utilisateur.id === -1) {
      this.logout();
    }

    return this.utilisateur;
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subExpiredSession);
  }

  private getUserInfos(utilisateur: Utilisateur) {


    return this.http.get(URL_GET_USER_INFOS, {params: new HttpParams().set('login', utilisateur.username)}).pipe(
      catchError(err => this.utils.handleError(err)))

  }

  changementEnvironnement(environnementDTO: EnvironnementDTO) {


    if (!this.utils.isNullOrEmpty(environnementDTO)) {

      // on met l'environnement selectionne dans le localStorage
      this.frontStorageSvc.secureLS.set(AUTH.environnement, environnementDTO);

      // on change l'environnement
      this.selectedEnvironnementDTO = environnementDTO;
      this.utilisateur.sitePrincipal = this.selectedEnvironnementDTO.environnementSitesVisiblesDTOList[0].site;
      this.utilisateur.sites = [];
      this.utilisateur.siteListLocaux = [];
      this.utilisateur.siteListReferents = [];
      this.selectedEnvironnementDTO.environnementSitesVisiblesDTOList.forEach(esv => {
        if (esv.local) {
          this.utilisateur.siteListLocaux.push(esv.site);
        } else {
          this.utilisateur.siteListReferents.push(esv.site);
        }
      });
      this.selectedEnvironnementDTO.environnementSitesVisiblesDTOList.forEach(environnementSitesVisiblesDTO => {
        this.utilisateur.sites.push(environnementSitesVisiblesDTO.site);
      });

      // on notifie l'utilisateur que l'environnement va changer
      const seconds = 3;

      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `L'environnement sélectionné est désormais ${this.selectedEnvironnementDTO.libelle}. Une reconnexion va s'opérer automatiquement dans ${seconds} secondes...`);

      // on stocke l'environnement dans le localStorage
      this.frontStorageSvc.secureLS.set(AUTH.environnement, this.selectedEnvironnementDTO);

      this.prefsUserSvc.assignPreferenceUtilisateurValeur(USER_PREFERENCE.GLOBAL_PREFERENCE_ID_DEFAULT_ENVIRONNEMENT, USER_TYPE_PREFERENCE.NUMBER, this.selectedEnvironnementDTO.id);
      this.prefsUserSvc.savePreferencesUtilisateur(null).pipe(
        delay(seconds * 1000),
        catchError(err => this.utils.handleError(err))
      ).subscribe(data => {
          // on force la reconnection, pour charger correctement l'environnement de l'utilisateur
          this.logout();
        }, err => {
          this.utils.handleError(err, true)
        },
      );
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Le changement d'environnement n'a pas fonctionné. Veuillez vous reconnecter`);
    }
  }

  /**
   * Annonce le chargement d'un environnement ou son chargement.
   */
  public announceEnvironnement(environnement: EnvironnementDTO) {
    this.subjectEnvironnement.next(environnement);
  }


  createEmptyUser(): Utilisateur {
    // on rend l'utilisateur inactif
    const emptyUser = new Utilisateur();
    emptyUser.id = -1;

    return emptyUser;
  }

  /**
   * Indique si l'utilisateur connecté (champ {@link utilisateur}) possède des sites locaux.
   */
  hasSitesLocaux(): boolean {
    return this.utilisateur.siteListLocaux.length > 0;
  }

  /**
   * Indique si le {@link SiteDTO} dont l'id est passé en paramètre est référent (i.e. s'il appartient à la liste {@link Auth2Service.utilisateur.siteListReferents}.
   * @param siteId
   */
  isSiteReferent(siteId: number) {
    return !this.utils.isNullOrEmpty(this.utilisateur.siteListReferents.find(site => site.id === siteId));
  }

  /**
   * Indique si le {@link SiteDTO} dont l'id est passé en paramètre est local (i.e. s'il appartient à la liste {@link Auth2Service.utilisateur.siteListLocaux}.
   * @param siteId
   */
  isSiteLocal(siteId: number): boolean {
    return !this.utils.isNullOrEmpty(this.utilisateur.siteListLocaux.find(site => siteId === site.id));
  }


  /**
   * Indique si l'utilisateur connecté (champ {@link utilisateur}) possède des sites référents.
   */
  hasSitesReferents(): boolean {
    return this.utilisateur.siteListReferents.length > 0;
  }

  /**
   * Indique si l'utilisateur connecté (champ {@link utilisateur}) possède des sites (locaux ou référents).
   */
  hasSites(): boolean {
    return this.utilisateur.sites.length > 0;

  }


  private saveConnexion(utilisateurConnexionDTO: UtilisateurConnexionDTO) {
    return this.http.post(URL_POST_SAVE_CONNEXION, utilisateurConnexionDTO).pipe(catchError(error => this.utils.handleError(error)));
  }

  public updateConnexion(token: string) {
    const token_tab = token.split('.');
    return this.http.post(URL_POST_UPDATE_CONNEXION, token_tab[token_tab.length - 1]).pipe(
      catchError(error => this.utils.handleError(error)));
  }

  /**
   * Sélectionner le 1er element appartenant à un site local.<br>
   *     Si pas trouvé, on prend le 1er élément de la liste
   * @param elements
   */
  preselectSingleByFirstLocalSite<T extends ObjectDTO>(elements: T[]) {
    let ctrl = true;
    let element: T = null;

    if (!this.utils.isCollectionNullOrEmpty(elements)) {
      elements.forEach(item => {
          if (this.isSiteLocal(item.site.id) && ctrl) {
            element = item;
            ctrl = false;
          }
        }
      );

      if (this.utils.isNullOrEmpty(element)) {
        element = elements[0];
      }
    }

    return element;
  }

  announceOpenMotDePasseOublieDialog() {
    this.subjectOpenMotDePasseOublieDialog.next();
  }
}
