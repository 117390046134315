import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {ContratmenuDTO} from '../../core/dtos/contratmenu-dto';
import {HttpClient} from '@angular/common/http';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {PointDeLivraisonService} from '../../core/services/entities/point-de-livraison.service';
import {PointDeLivraisonDTO} from '../../core/dtos/point-de-livraison-d-t-o';
import {RepasDTO} from '../../core/dtos/repas-dto';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../core/dtos/contratmenuconvive__contrainte-alim-dto';
import {ContratMenuConviveRepasPlcDateDTO} from '../../core/dtos/cmcr-plc-date-dto';
import {MPlcRepasRow} from '../../core/models/plc-repas-row';
import {ConviveDTO} from "../../core/dtos/convive-dto";
import {RepasService} from "../../core/services/entities/repas.service";
import {ConvivesService} from "../../core/services/entities/convives.service";

@Injectable({
  providedIn: 'root'
})
export class MenuPlcResolverService implements Resolve<MenuPlcSupplier> {

  constructor(private http: HttpClient,
              private auth2Svc: Auth2Service,
              private gds: GenericDatagridService,
              private plcSvc: PointDeLivraisonService,
              private repasSvc: RepasService,
              private convivesSvc: ConvivesService

  ) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuPlcSupplier> | Promise<MenuPlcSupplier> | MenuPlcSupplier => {

    const mps = new MenuPlcSupplier();
    const plcList$ = this.plcSvc.getPlcListBySitesPrestations();
    const repasList$ = this.repasSvc.getAll();
    const prestationList$ = this.convivesSvc.findAll();
    const all$ = combineLatest([plcList$, repasList$, prestationList$]);
    return all$.toPromise().then(response => {
      mps.plcList = response[0].resultList.filter(p => p.actif);
      mps.repasList = response[1].resultList;
      mps.prestationList = response[2].resultList.filter(p => p.actif).sort((a, b) => a.libelle.localeCompare(b.libelle));
      return mps;
    });
  };
}

export class MenuPlcSupplier {

  contratsMenus: ContratmenuDTO[];
  plcList: PointDeLivraisonDTO[];
  repasList: RepasDTO[];
  prestationList: ConviveDTO[];

}



export class PlcWeek {
  repasList: PlcRepasWeek[];
}

export class PlcRepasWeek {
  rows: ContratMenuConvive__ContrainteAlimDTO[];
  cols: Date[];
  cells: ContratMenuConviveRepasPlcDateDTO[];
  repas: RepasDTO;
}

export class PlcWeekDetails {

  cmcCa: ContratMenuConvive__ContrainteAlimDTO;
  cols: Date[];
  repas: PlcRepas[];

}

export class PlcRepas {
  rows: MPlcRepasRow[];
}
