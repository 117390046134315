import {Component, Input} from "@angular/core";
import {UtilsService} from "../../../core/utils/utils.service";
import {ConfigurationPortailDTO} from "../../../core/dtos/portail/configuration-portail-dto";
import DatesDetailsFromMenuDTO from "../../../core/dtos/planification/dates-details-from-menu-dto";
import {Subscription} from "rxjs";
import RepasPrestationsEffectifsCommandesClientDTO
  from "../../../core/dtos/planification/repas-prestations-effectifs-commandes-client-dto";
import ViewCommandesEffectifsDTO from "../../../core/dtos/planification/views/view-commandes-effectifs-dto";
import ViewEffectifsByPlcRepasPrestationDTO
  from "../../../core/dtos/planification/views/view-effectifs-by-plc-repas-prestation-dto";
import {CommandesClientService} from "../../../core/services/entities/commandes-client.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";

@Component({
  selector: 'yo-generic-prestations-semaine',
  templateUrl: './generic-prestations-semaine.component.html',
  styleUrls: ['./generic-prestations-semaine.component.scss'],

})
export class GenericPrestationsSemaineComponent {

  @Input() configPortailPlc : ConfigurationPortailDTO;
  @Input("cols") cols: Date[];

  subViewCommandesEffectif: Subscription;
  subLoadingView: Subscription;
  subDatesDetailsFromMenu: Subscription;

  datesDetailsFromMenu: DatesDetailsFromMenuDTO;
  repas: RepasPrestationsEffectifsCommandesClientDTO[] = [];
  isLoadingView: boolean = false;

  constructor(public utils : UtilsService,
              private commandesClientSvc: CommandesClientService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.viewCommandesEffectifSubscription();
    this.loadingViewSubscription();
    this.datesDetailsFromMenuSubscription();
  }

  viewCommandesEffectifSubscription = () => {
    this.subViewCommandesEffectif = this.commandesClientSvc.viewCommandesEffectif$.subscribe((response: ViewCommandesEffectifsDTO) => {
      let messageToast: string = `Mise à jour des données réussie avec succès !`;
      let messageSeverity: string = MSG_SEVERITY.SUCCESS;

      this.repas = [];
      if (response) this.repas = (response as ViewEffectifsByPlcRepasPrestationDTO).repas;
      else {
        messageSeverity = MSG_SEVERITY.WARNING;
        messageToast = `Aucun résultat à afficher pour votre sélection`;
      }

      this.commandesClientSvc.announceLoadingView(false);
      this.toastSvc.displayToast(MSG_KEY.ROOT, messageSeverity, messageToast);
    })
  }

  loadingViewSubscription = () => {
    this.subLoadingView = this.commandesClientSvc.loadingView$
      .subscribe((isLoading) => this.isLoadingView = isLoading);
  }

  datesDetailsFromMenuSubscription = () => {
    this.subDatesDetailsFromMenu = this.commandesClientSvc.datesDetailsFromMenu$
      .subscribe((datesDetailsFromMenu: DatesDetailsFromMenuDTO) => this.datesDetailsFromMenu = datesDetailsFromMenu);
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subViewCommandesEffectif);
    this.utils.unsubscribe(this.subLoadingView);
    this.utils.unsubscribe(this.subDatesDetailsFromMenu);
  }

  canDisplayGrille = () => this.repas?.length && this.cols?.length && this.datesDetailsFromMenu.joursDisponibles?.length;
}
