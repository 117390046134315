import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../../core/utils/utils.service';
import {FormGroup} from '@angular/forms';
import {ContratMenuConvive__EquipeDTO} from '../../../../core/dtos/contratmenuconvive-equipe-dto';
import {UniteDeProduction__EquipeDTO} from '../../../../core/dtos/unite-de-production__equipe';
import {ContratsMenusConvivesEquipesService} from '../../../../core/services/entities/contrats-menus-convives-equipes.service';
import {ContratMenuConviveDTO} from '../../../../core/dtos/contratmenuconvive-dto';

import {MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {cloneDeep as _cloneDeep} from 'lodash'
import {Subscription} from 'rxjs';
import {CmcEquipeDialogSupplier} from "../../../../core/suppliers/gestionmenus/cmc-equipe-dialog-supplier";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {RepasDTO} from "../../../../core/dtos/repas-dto";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import {RoutemapService} from "../../../../core/services/routemap.service";

@Component({
  selector: 'yo-cmc-equipe-dialog-edit',
  templateUrl: './cmc-equipe-dialog-edit.component.html',
  styleUrls: ['./cmc-equipe-dialog-edit.component.scss']
})
export class CmcEquipeDialogEditComponent implements OnInit, AfterViewInit, OnDestroy {

  displayDialog: boolean = false;

  displayEquipes: boolean = false;

  displayRepas: boolean = false;

  currentUdpEquipes: any[] = [];

  form: FormGroup;

  cmcEquipe: ContratMenuConvive__EquipeDTO;
  tacheFabricationList: any[];
  udpEquipeList: UniteDeProduction__EquipeDTO[];

  dataSourceTeams: any;

  repas: RepasDTO[];

  udpList: {
    id,
    label,
    ateliers: {
      id,
      label,
      equipes: UniteDeProduction__EquipeDTO[]
    }[]
  }[];
  contratMenuConvive: ContratMenuConviveDTO;

  selectedFabrication: number;
  selectedUdpEquipe: number;

  repasSelected: number;

  subFormChange: Subscription;

  data:CmcEquipeDialogSupplier;
  subDisplayCmcEquipeDialog: Subscription;

  constructor(public gds: GenericDatagridService,
              public utils: UtilsService,
              private gfs: GenericFormService,
              private cmcEquipeSvc: ContratsMenusConvivesEquipesService,
              private toastSvc: ToastService,
              private routeMapSvc: RoutemapService) {}

  ngOnInit() {
    this.displayCmcEquipeDialogSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFormChange);
    this.utils.unsubscribe(this.subDisplayCmcEquipeDialog);
  }

  ngAfterViewInit(): void {
    /*this.subFormChange = this.form.valueChanges.subscribe(values => {
      this.selectedUdpEquipe = values.udpEquipe;
      this.cmcEquipe.idUdpEquipe = this.selectedUdpEquipe;
    });*/
  }

  displayCmcEquipeDialogSubscription = () => {
    this.subDisplayCmcEquipeDialog = this.cmcEquipeSvc.displayCmcEquipeDialog$.subscribe( data => {
      this.data = data;
      this.displayDialog = true;
      this.displayEquipes = false;
      this.displayRepas = false;
      this.initData();
    });
  };

  initData = () => {
    // avec le cloneDeep, on decorele la grille de la fenetre
    if (!this.utils.isNullOrEmpty(this.data)) {
      this.cmcEquipe = _cloneDeep(this.data.cmcEquipe);
      this.udpEquipeList = _cloneDeep(this.data.udpEquipeList);
      this.initFilters();

      this.contratMenuConvive = _cloneDeep(this.data.contratMenuConvive);
    }
  };

  initFilters = () => {
    this.cmcEquipeSvc.fetchFiltersForOffreAlimentairePrestationEquipe(this.data.contratMenuConvive.id)
      .subscribe((response) => {
        let res = response.one;
        this.tacheFabricationList = res.taches;
        this.initForm();
      });
  }

  initForm = () => {
    this.selectedFabrication = this.cmcEquipeSvc.initTacheFabrication(this.cmcEquipe, this.tacheFabricationList);
    /*this.selectedUdpEquipe = this.cmcEquipeSvc.initUdpEquipe(this.cmcEquipe, this.udpEquipeList);
    this.repasSelected = this.repas.find(r => r.id === this.cmcEquipe?.idRepas)?.id;
    this.udpList = this.cmcEquipeSvc.initUdpList(this.udpEquipeList);*/
  };

  saveContratMenuConviveEquipe = () => {
    let formdata: any = {
      idRepas: this.repasSelected,
      idUdpEquipe: this.selectedUdpEquipe,
      idOffreAlimentairePrestation: this.contratMenuConvive.id,
      idTache: this.selectedFabrication,
      id: this.cmcEquipe && this.cmcEquipe.id ? this.cmcEquipe.id : undefined
    };
    this.cmcEquipeSvc.saveCmcEquipe(formdata).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.closeDialog();
        this.routeMapSvc.goToSecondaryRoute(['gestioncontrats-contratmenuconvive', this.contratMenuConvive?.contratMenuId, this.contratMenuConvive.id, 'equipes'])
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Affectation de l'équipe ${this.cmcEquipe.equipeLibelle.toUpperCase()} au contrat ${this.cmcEquipe.contratMenuConviveLibelle.toUpperCase()} enregistré avec succès`);
      }
    });
  };

  /**
   * Le type de fabrication n'est plus modifiable une fois que le {@link ContratMenuConvive__EquipeDTO} a été créé.
   */
  isTypeDeFabricationDisabled = (): boolean => this.cmcEquipe ? this.cmcEquipe.id > 0 : false;

  closeDialog = (): void => {
    this.displayDialog = false;
    this.selectedFabrication = undefined;
    this.selectedUdpEquipe = undefined;
    this.repasSelected = undefined;
  };

  onChangeTache = ($event): void => {
    if ($event.value) {
      const data: any[] = this.tacheFabricationList
        .filter(tache => tache.id === $event.value)
        .reduce((res, item) => { res.push(...item.udpEquipes); return res; }, []);
      this.currentUdpEquipes = data;
      this.displayEquipes = true;
      this.dataSourceTeams = new DataSource({
        store: new ArrayStore({
          data,
          key: 'id',
        }),
        group: 'atelier.libelle',
      });
    }
  }

  onChangeEquipe = ($event): void => {
    if ($event.value) {
      this.cmcEquipe.idUdpEquipe = $event.value;
      this.repas = this.currentUdpEquipes
        .filter(udpe => udpe.id === $event.value)
        .reduce((res, item) => { res.push(...item.repas); return res; }, []);
      this.displayRepas = true;
    }
  }

}

