import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {PointDeLivraisonDTO} from '../../dtos/point-de-livraison-d-t-o';
import {UtilsService} from '../../utils/utils.service';
import {HttpClient} from '@angular/common/http';
import {ToastService} from "../technique/toast.service";
import {HttpService} from "../technique/http.service";
import ViewCommandesEffectifsDTO from "../../dtos/planification/views/view-commandes-effectifs-dto";
import {StartPointCommandesClientsEnum} from "../../enums/start-point-commandes-clients-enum";

@Injectable()
export class PrintEffectifService {

  private subjectDisplayDialogPrintEffectif = new Subject<PrintEffectifSupplier>();
  public displayDialogPrintEffectif$ = this.subjectDisplayDialogPrintEffectif.asObservable();

  private subjectDisplayDialogPrintEffectivesToTotal = new Subject<any>();
  public displayDialogPrintEffectivesToTotal$ = this.subjectDisplayDialogPrintEffectivesToTotal.asObservable();

  private subjectHaveDataToPrint = new Subject();
  public haveDataToPrint$ = this.subjectHaveDataToPrint.asObservable();

  constructor(private utilsService: UtilsService, private http: HttpClient, private httpSvc: HttpService, private toastSvc: ToastService) {}

  public announceDisplayDialogPrintEffectif(pes: PrintEffectifSupplier) {
    this.subjectDisplayDialogPrintEffectif.next(pes);
  }

  public announceHaveDataToPrint(haveDataToPrint: boolean) {
    this.subjectHaveDataToPrint.next(haveDataToPrint);
  }

  announceDisplayDialogPrintEffectifTotaux = (params: any): void => {
    this.subjectDisplayDialogPrintEffectivesToTotal.next(params);
  }

  printEffectivesToTotal = (filters: any): Observable<any> => {
    const args = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
    return this.httpSvc.get(`dolrest/gestion-clients/effectifs-totaux?${args}`, null, 'blob');
  }

}


export class PrintEffectifSupplier {

  selectedPlc: PointDeLivraisonDTO;
  selectedDate: Date;

  viewCommandesEffectifs: ViewCommandesEffectifsDTO;
  display: boolean;
  details: boolean;

  weekMonday: Date;
  weekSunday: Date;
  startPoint: StartPointCommandesClientsEnum;
  endPoint: StartPointCommandesClientsEnum;
  idSelected: number;

  constructor(selectedPlc: PointDeLivraisonDTO, selectedDate: Date, viewCommandesEffectifs: ViewCommandesEffectifsDTO, display: boolean, details: boolean,
              weekMonday: Date, weekSunday: Date, startPoint: StartPointCommandesClientsEnum, endPoint: StartPointCommandesClientsEnum, idSelected: number) {
    this.selectedPlc = selectedPlc;
    this.selectedDate = selectedDate;
    this.viewCommandesEffectifs = viewCommandesEffectifs;
    this.display = display;
    this.details = details;
    this.weekMonday = weekMonday;
    this.weekSunday = weekSunday;
    this.startPoint = startPoint;
    this.endPoint = endPoint;
    this.idSelected = idSelected;
  }
}
