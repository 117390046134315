import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CatalogueAchatDTO} from '../../core/dtos/catalogue-achat-dto';
import {FournisseurDTO} from '../../core/dtos/fournisseur-d-t-o';
import {GestionFournisseurCatalogueAchatService} from '../../core/services/gestion-fournisseurs/gestion-fournisseur-catalogue-achat.service';
import {Subscription} from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import {Sort} from '../../core/suppliers/generics/generic-request-supplier';
import {DevextremeService} from '../../core/services/technique/devextreme.service';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {DialogMsgSupplier, Paragraphe} from '../../core/suppliers/dialog-msg-supplier';
import {DxDataGridComponent} from 'devextreme-angular';
import {UtilsService} from '../../core/utils/utils.service';
import {ResponseWrapper} from '../../core/suppliers/wrappers/response-wrapper';
import {MSG_KEY} from '../../core/constants';
import {CataloguesAchatsService} from '../../core/services/entities/catalogues-achats.service';
import {IsDeletableObject} from "../../core/models/is-deletable-object";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-catalogue-achat-filiale',
  templateUrl: './catalogue-achat-filiale.component.html',
  styleUrls: ['./catalogue-achat-filiale.component.scss']
})
export class CatalogueAchatFilialeComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, {static: false}) grid: DxDataGridComponent;

  isVisibleCaInherited: boolean = true;
  title: string = "Catalogue des articles de la filiale";
  catalogueAchatList: CatalogueAchatDTO[] = [];
  filiale: FournisseurDTO;
  displayDialog: boolean = false;
  customStore: CustomStore;

  subDisplayCatalogueAchatFournisseur: Subscription;


  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private cd: ChangeDetectorRef,
              private cataloguesAchatsSvc: CataloguesAchatsService,
              private dxSvc: DevextremeService,
              private gds: GenericDatagridService,
              private gestionCaCustomStoreSvc: GestionFournisseurCatalogueAchatService) {
  }

  ngOnInit(): void {
    this.initData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDisplayCatalogueAchatFournisseur);
  }

  initData = () => {
    this.subDisplayCatalogueAchatFournisseur = this.gestionCaCustomStoreSvc.displayCatalogueAchatFournisseur$.subscribe(data => {
      this.filiale = data;
      this.customStore = this.initCustomStore();
      this.displayDialog = true;
    });
  };

  initCustomStore = () => {

    const cs = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {

        // page
        const pageSize: number = loadOptions.take || this.grid.instance.pageSize();
        const page: number = this.grid.instance.pageIndex() + 1;

        // filters
        const filters = this.dxSvc.dxToGrsFilters(loadOptions.filter);
        const filterLibArticle = filters.find(filter => filter.column === 'produitArticle.libelle');
        const filterRefFournisseur = filters.find(filter => filter.column === 'fournisseurOwnerLibelle');
        const filterRefArticle = filters.find(filter => filter.column === 'referenceArticle');
        const filterLibUniteCmd = filters.find(filter => filter.column === 'uniteDeCommande.libelle');

        // sorts
        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);
        const sortLibArticle = sorts.find(filter => filter.path === 'produitArticle.libelle');
        const sortFournisseur = sorts.find(filter => filter.path === 'fournisseurOwnerLibelle');
        const sortRefArticle = sorts.find(filter => filter.path === 'referenceArticle');
        const sortLibUniteCmd = sorts.find(filter => filter.path === 'uniteDeCommande.libelle');

        return this.cataloguesAchatsSvc.dynamicSearchCatalogueAchat([this.filiale.id], !this.isVisibleCaInherited,
          filterRefFournisseur?.value, filterLibArticle?.value, filterRefArticle?.value, filterLibUniteCmd?.value, sortLibArticle?.dir,
          sortRefArticle?.dir, sortLibUniteCmd?.dir, sortFournisseur?.dir, page, pageSize)
          .toPromise().then(response => {

            this.catalogueAchatList = response.resultList;

            return {
              data: this.catalogueAchatList,
              totalCount: response.totalElements
            }
          });
      },
      update: (key, values) => {
        return null;
      }
    });

    return cs;
  };

  onChangeIsVisibleCaInherted = async () => {
    if (this.customStore)
      await this.customStore.load();
    await this.grid.instance.refresh();
  };

  openDeleteObject = async (vmCatalogueAchat: CatalogueAchatDTO) => {
    const result = confirm(`Etes vous sûr de vouloir supprimer l'article ${vmCatalogueAchat.produitArticle.libelle} du fournisseur ${vmCatalogueAchat.fournisseur.libelle} ?`,
      'Suppression de l\'article');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.cataloguesAchatsSvc.deleteCatalogueAchat(vmCatalogueAchat.id).subscribe(async (response: ResponseWrapper<IsDeletableObject>) => {
        this.utils.deleteMessage(response, MSG_KEY.SIDEBAR);
        await this.grid.instance.refresh();
      });
    }
  };

  canModify = (): boolean => this.auth2Svc.isSiteLocal(this.filiale.site.id);

  closeDialog = () => {
    this.displayDialog = false;
  };

  canCreate = () => true;

  isDisabledDeleteBtn = (cell: CatalogueAchatDTO) => {
    const isLocal = this.auth2Svc.isSiteLocal(cell.site.id)
    const isheritedCa = cell.caParentId !== 0;
    if (isheritedCa) {
      return true;
    } else {
      return !isLocal;
    }
  };

  help = (): DialogMsgSupplier => {

    let dms = new DialogMsgSupplier();
    dms.title = `Catalogue Achat d'une filiale`;

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Catalogue Achat d'une filiale`;
    p1.lines = [
      ``
    ];

    dms.content = {
      intro: `Fonctionnement du catalogue achat pour une filiale`,
      paragraphes: [p1]
    };
    return dms;
  };
}
