import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {RepasDTO} from '../../dtos/repas-dto';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";
import {ObjectDTO} from "../../dtos/object-dto";

@Injectable()
export class RepasService {

  private subjectOpenDialogEdition: Subject<RepasDTO> = new Subject<RepasDTO>();

  openDialogEdition$: Observable<RepasDTO> = this.subjectOpenDialogEdition.asObservable();

  private subjectMealSaved: Subject<RepasDTO> = new Subject<RepasDTO>();

  mealSaved$: Observable<any> = this.subjectMealSaved.asObservable();

  constructor(private utils: UtilsService,
              private gds: GenericDatagridService,
              private auth2Svc: Auth2Service,
              private http: HttpClient,
              private httpSvc: HttpService) {
  }

  export = (idsToExport?: number[]): Observable<any> => {
    let params: string = '?1=1';
    if (idsToExport?.length) params += `&ids=${idsToExport.join(',')}`
    return this.http.get(`dolrest/referentiel/base/repas/export?${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  }

  delete = (idsToDelete: number[]): Observable<any> => {
    return this.httpSvc.delete(`dolrest/referentiel/base/repas/delete?ids=${idsToDelete}`, null);
  }

  importFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post("dolrest/referentiel/base/repas/import", formData, null);
  }

  announceOpeningEditionDialog = (meal: RepasDTO): void => {
    this.subjectOpenDialogEdition.next(meal);
  }

  announceMealSaved = (): void => {
    this.subjectMealSaved.next(null);
  }

  save = (meal: RepasDTO): Observable<ResponseWrapper<RepasDTO>> => {
    return this.httpSvc.post("dolrest/referentiel/base/repas/save", meal);
  }

  canModify = (element: ObjectDTO): boolean => {
    if (!element || !element?.site || !element?.site.id)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }

  /**
   * Récupère tous les repas
   */
  getAll = () : Observable<ResponseWrapper<RepasDTO>> => {
    return this.httpSvc.get(`dolrest/referentiel/base/repas/all`);
  }
}
