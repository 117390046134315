import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PrintEffectifService, PrintEffectifSupplier} from '../../../core/services/gestion-plc/print-effectif.service';
import {switchMap} from 'rxjs/operators';
import {Observable, of, Subscription} from 'rxjs';
import {SelectItem} from 'primeng/api';
import {RepasDTO} from '../../../core/dtos/repas-dto';
import {PointDeLivraisonDTO} from '../../../core/dtos/point-de-livraison-d-t-o';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from "../../../core/utils/utils.service";
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import ViewEffectifsByPlcRepasPrestationDTO
  from "../../../core/dtos/planification/views/view-effectifs-by-plc-repas-prestation-dto";
import {CommandesClientService} from "../../../core/services/entities/commandes-client.service";
import {StartPointCommandesClientsEnum} from "../../../core/enums/start-point-commandes-clients-enum";
import {saveAs} from "file-saver";
import ViewCommandesEffectifsDTO from "../../../core/dtos/planification/views/view-commandes-effectifs-dto";
import ViewEffectifsByPlcPrestationRepasDTO
  from "../../../core/dtos/planification/views/view-effectifs-by-plc-prestation-repas-dto";
import ViewEffectifsByPrestationPlcRepasDTO
  from "../../../core/dtos/planification/views/view-effectifs-by-prestation-plc-repas-dto";
import ViewEffectifsByPrestationRepasPlcDTO
  from "../../../core/dtos/planification/views/view-effectifs-by-prestation-repas-plc-dto";
import ViewEffectifsByRepasPlcPrestationDTO
  from "../../../core/dtos/planification/views/view-effectifs-by-repas-plc-prestation-dto";
import ViewEffectifsByRepasPrestationPlcDTO
  from "../../../core/dtos/planification/views/view-effectifs-by-repas-prestation-plc-dto";
import {TypeEffectifEnum} from "../../../core/enums/type-effectif-enum";
import {DxTagBoxComponent} from "devextreme-angular";
import {RegimeAlimentaireDTO} from "../../../core/dtos/regime-alimentaire-dto";

@Component({
  selector: 'yo-print-effectif',
  templateUrl: './print-effectif.component.html',
  styleUrls: ['./print-effectif.component.scss']
})
export class PrintEffectifComponent implements OnInit, OnDestroy {

  selectedDate: Date;
  selectedPlc: PointDeLivraisonDTO;
  repasList: RepasDTO[];
  viewCommandesEffectifs: ViewEffectifsByPlcRepasPrestationDTO;
  selectedRepasList: RepasDTO[];

  displayDialog: boolean;
  details: boolean;
  modeOptionsList: any[];
  selectedMode: any[] = [];
  modeRegimesList: RegimeAlimentaireDTO[];
  selectedRegimes: RegimeAlimentaireDTO[] = [];

  // subscription
  subDisplayDialogPrintEffectif: Subscription;
  subRegimes: Subscription;

  weekMonday: Date;
  weekSunday: Date;
  startPoint: StartPointCommandesClientsEnum;
  endPoint: StartPointCommandesClientsEnum;
  idSelected: number;

  @ViewChild("tagMode", {static: false}) tagMode: DxTagBoxComponent;
  @ViewChild("tagRepas", {static: false}) tagRepas: DxTagBoxComponent;
  @ViewChild("tagRegime", {static: false}) tagRegime: DxTagBoxComponent;

  constructor(private printEffectifService: PrintEffectifService,
              private gds: GenericDatagridService,
              private utils: UtilsService,
              private graphQlSvc: GraphQLService,
              private commandesClientService: CommandesClientService) {
  }

  ngOnInit(): void {
    this.initModeOptionsList();
    this.displayDialogSubscription();
    this.regimesSubscription();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subDisplayDialogPrintEffectif);
    this.utils.unsubscribe(this.subRegimes);
  }

  getPlcRepasList = (viewCommandesEffectifs: ViewCommandesEffectifsDTO,
                     startPoint: StartPointCommandesClientsEnum, endPoint: StartPointCommandesClientsEnum): RepasDTO[] => {
    let repasList = [];
    if (!this.utils.isNullOrEmpty(viewCommandesEffectifs)) {
      switch (startPoint) {
        case StartPointCommandesClientsEnum.PLC:
          if (endPoint === StartPointCommandesClientsEnum.REPAS) {
            repasList = (viewCommandesEffectifs as ViewEffectifsByPlcRepasPrestationDTO).repas.map(item => item.repas);
          } else {
            (viewCommandesEffectifs as ViewEffectifsByPlcPrestationRepasDTO)
              .prestations.forEach(item => item.repas.forEach(repas => {
              if (!repasList.find(r => r.id === repas.repas.id)) repasList.push(repas.repas)
            }))
          }
          break;
        case StartPointCommandesClientsEnum.REPAS:
          if (endPoint === StartPointCommandesClientsEnum.PLC) {
            let repas: RepasDTO = new RepasDTO();
            repas.id = (viewCommandesEffectifs as ViewEffectifsByRepasPlcPrestationDTO).idRepas;
            repas.libelle = (viewCommandesEffectifs as ViewEffectifsByRepasPlcPrestationDTO).libelleRepas;
            repasList = [repas];
            break;
          } else {
            let repas: RepasDTO = new RepasDTO();
            repas.id = (viewCommandesEffectifs as ViewEffectifsByRepasPrestationPlcDTO).idRepas;
            repas.libelle = (viewCommandesEffectifs as ViewEffectifsByRepasPrestationPlcDTO).libelleRepas;
            repasList = [repas];
            break;
          }
        case StartPointCommandesClientsEnum.PRESTATION:
          if (endPoint === StartPointCommandesClientsEnum.PLC) {
            (viewCommandesEffectifs as ViewEffectifsByPrestationPlcRepasDTO)
              .pointsLivraison.forEach(item => item.repas.forEach(repas => {
              if (!repasList.find(r => r.id === repas.repas.id)) repasList.push(repas.repas)
            }))
          } else {
            repasList = (viewCommandesEffectifs as ViewEffectifsByPrestationRepasPlcDTO).repas.map(repas => repas.repas);
          }
          break;
      }
    }
    return repasList;
  };

  public closeDialog = () => {
    this.selectedMode = [];
    this.selectedRepasList = [];
    this.selectedRegimes = [];
    this.displayDialog = false;
    this.tagMode.instance.reset();
    this.tagRepas.instance.reset();
    this.tagRegime.instance.reset();
  };

  public printEffectif = () => {
    if (!this.utils.isNullOrEmpty(this.viewCommandesEffectifs)) {
      if (this.details) {
        return this.commandesClientService
          .exportViewCommandesEffectifDetailsMenu(this.weekMonday, this.weekSunday, this.idSelected, this.startPoint, this.endPoint,
            this.selectedRepasList.map(value => value.id), this.selectedMode.map(value => value.label), this.selectedRegimes.map(value => value.id))
          .subscribe(response => {
            const filename = `commandes-clients-effectifs-${new Date().getTime()}.xlsx`;
            const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
            saveAs(blob, filename);
          });
      } else {
        return this.commandesClientService
          .exportViewCommandesEffectif(this.weekMonday, this.weekSunday, this.idSelected, this.startPoint, this.endPoint,
            this.selectedRepasList.map(value => value.id), this.selectedMode.map(value => value.label), this.selectedRegimes.map(value => value.id))
          .subscribe(response => {
            const filename = `commandes-clients-effectifs-${new Date().getTime()}.xlsx`;
            const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
            saveAs(blob, filename);
          });
      }
    }
  };

  private initModeOptionsList = () => {
    this.modeOptionsList = [
      {label: "Prévision", value: TypeEffectifEnum.PREVISIONNEL},
      {label: "Fabrication", value: TypeEffectifEnum.FABRICATION},
      {label: "Facturation", value: TypeEffectifEnum.FACTURATION}
    ];
    this.selectedMode = [...this.modeOptionsList];
  };

  private regimesSubscription = () => {
    this.subRegimes = this.getRegimesAlimentaires().subscribe(response => {
      this.modeRegimesList = response.allRegimesAlimentaires;
      this.selectedRegimes = this.modeRegimesList;
    });
  };

  private getRegimesAlimentaires = (): Observable<any> => {
    return this.graphQlSvc.sendQuery(`
    {
      allRegimesAlimentaires {
        id,
        libelle
      }
    }
    `)
  }

  private displayDialogSubscription = () => {
    this.subDisplayDialogPrintEffectif = this.printEffectifService.displayDialogPrintEffectif$
      .pipe(
        switchMap((response: PrintEffectifSupplier) => {
          this.displayDialog = response.display;
          this.details = response.details;
          this.viewCommandesEffectifs = response.viewCommandesEffectifs as ViewEffectifsByPlcRepasPrestationDTO;

          this.weekMonday = response.weekMonday;
          this.weekSunday = response.weekSunday;
          this.startPoint = response.startPoint;
          this.endPoint = response.endPoint;
          this.idSelected = response.idSelected;

          this.repasList = this.getPlcRepasList(this.viewCommandesEffectifs, this.startPoint, this.endPoint);
          this.initModeOptionsList();
          this.selectedRegimes = [...this.modeRegimesList];
          this.selectedDate = response.selectedDate;
          this.selectedPlc = response.selectedPlc;
          this.selectedRepasList = [...this.repasList];

          return of(null);
        })
      ).subscribe();
  };

  isPrintDisabled = () => {
    return this.selectedMode.length === 0
      || this.utils.isCollectionNullOrEmpty(this.selectedRepasList)
      || this.utils.isCollectionNullOrEmpty(this.selectedRegimes);
  };

  onChangeMode = ($event): void => {
    console.log($event)
    this.selectedMode = $event.value;
  }

  onChangeRepas = ($event): void => {
    this.selectedRepasList = $event.value;
  }

  onChangeRegimes = ($event): void => {
    this.selectedRegimes = $event.value;
  }
}
