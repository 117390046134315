import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import {DeclinaisonsService} from "../../../core/services/declinaisons/declinaisons.service";
import {ConditionnementsVarianteService} from "../../../core/services/conditionnements/conditionnement-variante.service";
import {
  ModelePlatParametrage,
  ModelesPlatsService
} from "../../../core/services/conditionnements/modeles-plats.service";
import {DeclinaisonDTO} from "../../../core/dtos/declinaison-dto";
import {combineLatest, Subscription} from "rxjs";
import ModeleConditionnementPlatDTO from "../../../core/dtos/conditionnement/modele-conditionnement-plat-dto";
import ConditionnementVarianteDTO from "../../../core/dtos/conditionnement/conditionnement-variante-dto";
import ModeleConditionnementPlatDeclinaisonDTO
  from "../../../core/dtos/conditionnement/modele-conditionnement-plat-declinaison-dto";
import {ModelesConditionnementsPlatsDeclinaisonsService} from "../../../core/services/conditionnements/modeles-conditionnements-plats-declinaison.service";
import ModeleConditionnementsPlatConditionnementsVariantesDTO
  from "../../../core/dtos/conditionnement/modele-conditionnement-plat-conditionnement-variante-dto";
import {McpConditionnementsVariantesService} from "../../../core/services/conditionnements/mcp-conditionnements-variantes.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import ResteDTO from "../../../core/dtos/conditionnement/reste-dto";
import {TacheDTO} from "../../../core/dtos/tache-dto";
import {TYPE_TACHE} from "../../../core/services/entities/type-tache.service";
import ConditionnementVarianteDeclinaisonDTO
  from "../../../core/dtos/conditionnement/conditionnement-variante-declinaison-dto";
import {RestesService} from "../../../core/services/conditionnements/restes.service";
import {TachesService} from "../../../core/services/entities/taches.service";
import {ConditionnementsVarianteDeclinaisonService} from "../../../core/services/conditionnements/conditionnement-variante-declinaison.service";
import {DxDataGridComponent} from "devextreme-angular";
import {cloneDeep as _cloneDeep} from 'lodash';
import {WizardService} from "../../../core/services/wizard-service";
import {GraphQLService} from "../../../core/services/technique/graphql.service";

@Component({
  selector: 'yo-wizard-configuration-step-5',
  templateUrl: './wizard-configuration-step-5.component.html',
  styleUrls: ['./wizard-configuration-step-5.component.scss']
})
export class WizardConfigurationStepFiveComponent implements OnInit, OnDestroy {

  @Output() onEmitPreviousStep = new EventEmitter<any>();

  @Output() onEmitNextStep = new EventEmitter<any>();

  @ViewChild("grid") grid: DxDataGridComponent;

  @ViewChild("gridUc") gridUc: DxDataGridComponent;

  private currentStep: number;

  declinaisons: DeclinaisonDTO[] = [];

  declinaisonsSelected: DeclinaisonDTO[] = [];

  subCombine: Subscription;

  modelePlat: ModeleConditionnementPlatDTO;

  conditionnementsVariantes: ConditionnementVarianteDTO[] = [];

  conditionnementsVariantesSelected: ConditionnementVarianteDTO[] = [];

  modelesConditionnementsPlatDeclinaisons: ModeleConditionnementPlatDeclinaisonDTO[] = [];

  modelesConditionnementsPlatCv: ModeleConditionnementsPlatConditionnementsVariantesDTO[] = [];

  conditionnementVariantesDeclinaisons: ConditionnementVarianteDeclinaisonDTO[] = [];

  declinaisonForGridList: DeclinaisonDTO[] = [];

  declinaisonsGrid: DeclinaisonDTO[] = [];

  declinaisonsNbUCList: any[] = [];

  /**
   * Gestion des queues
   */
  queuesConfiguration: any = {};

  /**
   * Totalité des restes
   */
  resteList: ResteDTO[];

  /**
   *  Un trompe oeil pour DevExtreme pour avoir 1 footer d'une seule ligne.
   */
  footerList: any[] = [{id: 1}];

  /**
   * Mapping entre le n° de colonne DevExtreme et l'identifiant d'une déclinaison
   */
  mappingColumnsDeclinaisons: Map<number, number> = new Map<number, number>();

  /**
   * Totalité des tâches
   */
  tachesList: TacheDTO[];

  gridParametrage: any[];

  hasIDistri = false;

  constructor(private readonly auth2Svc: Auth2Service,
              private readonly utilsSvc: UtilsService,
              private readonly wizardSvc: WizardConditionnementService,
              private readonly dcSvc: DeclinaisonsService,
              private readonly cvSvc: ConditionnementsVarianteService,
              private readonly mcpSvc: ModelesPlatsService,
              private readonly mcpdSvc: ModelesConditionnementsPlatsDeclinaisonsService,
              private readonly mcpcvSvc: McpConditionnementsVariantesService,
              private readonly resteSvc: RestesService,
              private readonly tacheSvc: TachesService,
              private readonly cvdSvc: ConditionnementsVarianteDeclinaisonService,
              private readonly i8nSvc: InternationalizationService,
              private readonly toastSvc: ToastService,
              private readonly wizardGlobalSvc: WizardService,
              private readonly graphQlSvc: GraphQLService) {
    this.currentStep = this.wizardSvc.STEP_CONFIG_BINDINGS_GRID_MODELE_PLAT;
  }

  ngOnInit() {
    this.initData();
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.subCombine);
  }

  initData = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    const dataStepTwo: any = this.wizardSvc.getDataStepTwo();
    const idModelePlat: number = dataStepTwo.id;
    const combine$ = combineLatest([
      this.cvSvc.getAll(),
      this.mcpSvc.findById(idModelePlat),
      this.graphQlSvc.sendQuery(`
        {
            allDeclinaisons(filters: {
              siteIds: [${idsSites}],
              fabrique: true
            }) {
              id, libelle, site { id, libelle }
            }
        }
      `),
      this.mcpdSvc.getAllByIdMcp(idModelePlat),
      this.mcpcvSvc.getAllByIdMcp(idModelePlat),
      this.cvdSvc.getAllByIdMcp(idModelePlat),
      this.resteSvc.getAll(),
      this.tacheSvc.getAll()
    ]);

    this.subCombine = combine$.subscribe(response => {
      this.conditionnementsVariantes = response[0].resultList;
      this.modelePlat = response[1].one;
      this.declinaisons = response[2].allDeclinaisons.sort((d1, d2) => d1.libelle.localeCompare(d2.libelle));
      this.modelesConditionnementsPlatDeclinaisons = response[3].resultList;
      this.modelesConditionnementsPlatCv = response[4].resultList;
      this.conditionnementVariantesDeclinaisons = response[5].resultList;
      this.resteList = response[6].resultList;
      this.tachesList = response[7].resultList.filter(item => item.typeTacheCode === TYPE_TACHE.CONDITIONNEMENT);

      this.declinaisonsNbUCList = this.modelesConditionnementsPlatDeclinaisons.map(m => ({declinaison: m.declinaison, nbUc: m.nbUc}));

      if (this.modelesConditionnementsPlatDeclinaisons) {
        this.declinaisonForGridList = this.modelesConditionnementsPlatDeclinaisons
          .map(mcpd => mcpd.declinaison);
      }

      this.initConditionnementsConfigured();
      this.initDeclinaisonsConfigured();
      this.initQueuesConfiguration();
      this.initGridParametrage();
    });

  }

  updateGridFromBindings = (): void => {
    this.mcpSvc.saveRowParametrageModern(this.modelePlat, this.conditionnementsVariantesSelected, this.declinaisonsSelected)
      .subscribe(() => {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Grille configurée avec succès`);
        this.initData();
      });
  }

  changeQueue($event, columnIndex) {
    if (this.queuesConfiguration[columnIndex]) {
      this.queuesConfiguration[columnIndex].reste = this.resteList.find(r => r.id === $event.value);
    }
  }

  private initConditionnementsConfigured(): void {
    if (this.modelesConditionnementsPlatCv && this.conditionnementsVariantes) {
      this.conditionnementsVariantesSelected = this.conditionnementsVariantes.filter(cv => this.modelesConditionnementsPlatCv.filter(mcpcv => mcpcv.conditionnementVariante.id === cv.id).length);
    }
  }

  private initDeclinaisonsConfigured(): void {
    if (this.modelesConditionnementsPlatDeclinaisons) {
      this.declinaisonsSelected = this.declinaisons.filter(decli => this.modelesConditionnementsPlatDeclinaisons.map(mcpd => mcpd.declinaison).filter(d => decli.id === d.id).length);
    }
  }

  private initQueuesConfiguration() {
    if (this.modelesConditionnementsPlatDeclinaisons) {
      this.modelesConditionnementsPlatDeclinaisons.forEach((mcpd, idx) => {
        // le mapping entre l'index et la déclinaison ID est expliquée plus bas (ligne 111) :
        this.queuesConfiguration[idx + 2] = {reste: mcpd.reste, idmcpd: mcpd.id}
      });
    }
  }

  private initGridParametrage() {
    if (this.conditionnementsVariantesSelected) {
      this.gridParametrage = this.conditionnementsVariantesSelected.map(cv => {
        const current = this.modelesConditionnementsPlatCv.find((m: ModeleConditionnementsPlatConditionnementsVariantesDTO) => {
          return m.conditionnementVariante && m.conditionnementVariante.id === cv.id
        });

        const declinaisons = {};

        this.declinaisonsGrid = this.declinaisonForGridList.filter(declinaison => this.conditionnementVariantesDeclinaisons.find((cvd: ConditionnementVarianteDeclinaisonDTO) =>
          cvd.modeleConditionnementsPlat__variantes__conditionnements &&
          cvd.modeleConditionnementsPlat__variantes__conditionnements.conditionnementVariante &&
          cvd.modeleConditionnementsPlat__variantes__conditionnements.conditionnementVariante.id === cv.id &&
          cvd.modeleConditionnementPlat__declinaison.declinaison &&
          cvd.modeleConditionnementPlat__declinaison.declinaison.id === declinaison.id));

        this.declinaisonsGrid.forEach((declinaison, idx) => {
          const currentCvd = this.conditionnementVariantesDeclinaisons.find((cvd: ConditionnementVarianteDeclinaisonDTO) =>
            cvd.modeleConditionnementsPlat__variantes__conditionnements &&
            cvd.modeleConditionnementsPlat__variantes__conditionnements.conditionnementVariante &&
            cvd.modeleConditionnementsPlat__variantes__conditionnements.conditionnementVariante.id === cv.id &&
            cvd.modeleConditionnementPlat__declinaison.declinaison &&
            cvd.modeleConditionnementPlat__declinaison.declinaison.id === declinaison.id
          );

          // + 2 car les premières colonnes sont conditionnement et variante :
          if (currentCvd) {
            const key = idx + 2;
            this.mappingColumnsDeclinaisons.set(key, declinaison.id);
            // declinaison => objet dont la clé est l'association de l'identifiant d'une condition variante et d'une déclinaison.
            // En effet, chaque valeur prescrite dans une cellule est faite pour une déclinaison d'une condition variante.
            declinaisons[key] = {
              [cv.id]: { // identifiant pour représenter la ligne de l'ihm
                mcp__did: currentCvd.modeleConditionnementPlat__declinaison.id,
                mcp__cvid: currentCvd.modeleConditionnementsPlat__variantes__conditionnements.id,
                cvdid: currentCvd.id, // Identifiant à utiliser pour le backend
                contient: currentCvd ? currentCvd.contient : 0.0,
                effectifNourri: currentCvd ? currentCvd.effectifNourri : 0.0,
                poidsNet: currentCvd ? currentCvd.poidsNet : 0.0,
                uniteAConditionner: currentCvd && currentCvd.modeleConditionnementPlat__declinaison && currentCvd.modeleConditionnementPlat__declinaison.modeleConditionnementPlat && currentCvd.modeleConditionnementPlat__declinaison.modeleConditionnementPlat.uniteAConditionner.abreviation ? currentCvd.modeleConditionnementPlat__declinaison.modeleConditionnementPlat.uniteAConditionner.abreviation : 'POR',
              }
            }
          }
        });

        const result = {
          id: cv.id,
          conditionnementLabel: cv.conditionnement ? cv.conditionnement.libelle : '',
          varianteLabel: cv.variante ? cv.variante.libelle : 'Aucune',
          remarque: current && current.remarque ? current.remarque : '',
          idtache: current && current.tache ? current.tache.id : null,
          idmcpcv: current.id,
          declinaisons
        };

        return (result);
      });
      this.grid.instance.refresh();

      if (this.modelePlat?.mode === 'PIECE_NON_PROPORTIONNELLE') {
        // this.declinaisonsNbUCList = this.declinaisonForGridList.map(d => ({declinaison: d, nbUc: 1}));
        this.gridUc.instance.refresh();
      }
    }
  }

  changeNbUc = ($event, cell): void => {
    const value = $event.value;
    const columnIdx = cell.columnIndex;
    this.declinaisonsNbUCList[columnIdx].nbUc = value;
  };

  previous = (): void => {
    this.onEmitPreviousStep.emit({currentStep: this.currentStep});
  }

  next = (): void => {
    const grid = _cloneDeep(this.gridParametrage);
    let data: ModelePlatParametrage = this.mcpSvc.formatDataParametrage(grid, this.mappingColumnsDeclinaisons, this.modelePlat.id, this.modelePlat.mode, this.queuesConfiguration);
    this.onEmitNextStep.emit({
      currentStep: this.currentStep,
      modelePlatParametrage: data,
      declinaisonsSelected: this.declinaisonsSelected.map(d => d.id),
      nombreUnitesAServir: this.declinaisonsNbUCList
    });
  }

  currentStepPourcentage = (): number => this.wizardGlobalSvc.calculateCurrentStepPourcentage(this.wizardSvc.totalSteps, this.currentStep);

}
