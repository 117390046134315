<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-rowa align-items-center">
      <span class="mg-r-5 ">
          <p-selectButton [options]="startPoints" (onChange)="updateStartPoint()"
                          [(ngModel)]="startPoint"></p-selectButton>
        </span>
    </div>
  </dxi-item>

  <dxi-item *ngIf="startPoint === StartPointCommandesClientsEnum.PLC" location="center">
    <div class="dx-field-label">Point de livraison</div>
    <div class="dx-field-value">
      <dx-select-box
        [dataSource]="mplc.plcList"
        placeholder="Sélectionner un point de livraison..."
        displayExpr="libelle"
        (onValueChanged)="selectStartPoint($event)"
        [searchExpr]="searchExprOption"
        [minSearchLength]="0"
        [searchEnabled]="true"
        [value]="selectedPlc"
        [showClearButton]="true"
        [(ngModel)]="selectedPlc"
        (onFocusIn)="onItemClick($event)"
      ></dx-select-box>
    </div>
  </dxi-item>

  <dxi-item *ngIf="startPoint === StartPointCommandesClientsEnum.REPAS" location="center">
    <div class="dx-field-label">Repas</div>
    <div class="dx-field-value">
      <dx-select-box
        [dataSource]="mplc.repasList"
        placeholder="Sélectionner un repas..."
        displayExpr="libelle"
        (onValueChanged)="selectStartPoint($event)"
        [searchExpr]="searchExprOption"
        [minSearchLength]="0"
        [searchEnabled]="true"
        [value]="selectedRepas"
        [showClearButton]="true"
        [(ngModel)]="selectedRepas"
      ></dx-select-box>
    </div>
  </dxi-item>

  <dxi-item *ngIf="startPoint === StartPointCommandesClientsEnum.PRESTATION" location="center">
    <div class="dx-field-label">Prestation/Régime</div>
    <div class="dx-field-value">
      <dx-select-box
        [dataSource]="mplc.prestationList"
        placeholder="Sélectionner une prestation/un régime..."
        displayExpr="libelle"
        (onValueChanged)="selectStartPoint($event)"
        [searchExpr]="searchExprOption"
        [minSearchLength]="0"
        [searchEnabled]="true"
        [showClearButton]="true"
        [value]="selectedPrestation"
        [(ngModel)]="selectedPrestation"
      ></dx-select-box>
    </div>
  </dxi-item>

  <dxi-item location="after">
    <div class="d-flex flex-rowa align-items-center">
      <!--    IMPRESSION-->
      <span class="mg-r-1">
      <p-menu appendTo="body" #printMenu [popup]="true" [model]="itemsPrintMenu"></p-menu>
      <yo-button
        iconClass="fas fa-print"
        class="mg-r-5 va-top"
        (onClick)="printMenu.toggle($event)"
        tooltip="Impression">
      </yo-button>
    </span>
      <span>
      <yo-button
        iconClass="fas fa-copy"
        class="mg-r-5 va-top"
        (onClick)="openDialogDuplicateEffectivesPrevisionnals()"
        tooltip="Dupliquer les effectifs">
      </yo-button>
    </span>

    </div>
  </dxi-item>
</dx-toolbar>

<dx-toolbar class="p-4">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <!--        CALENDRIER-->
      <span class="mg-r-5">
        <dx-date-box
          [(value)]="selectedDate"
          [applyValueMode]="'instantly'"
          (onValueChanged)="updateDatesBySelection($event)"
          [calendarOptions]="{ onOptionChanged: this.onOptionChanged }"
          (onFocusOut)="updtateDatesByClickOutside()"
        >
          <span *dxTemplate="let cell of 'cell'">
            <yo-devextreme-date-dispo [cellData]="cell"
                                      [dateSaisieEffectif]="utils.isDateInList(convertToDate(cell.date), datesSaisieEffectifs)"
                                      [dateCreationMenu]="utils.isDateInList(convertToDate(cell.date), datesCreationMenus)"></yo-devextreme-date-dispo>
          </span>
        </dx-date-box>
        </span>
    </div>
  </dxi-item>

  <dxi-item location="center">
    <div class="d-flex flex-rowa align-items-center">
      <!--        TYPE D'EFFECTIF-->
      <span class="mg-r-5 ">
          <p-selectButton [options]="modes" (onChange)="changeTypeEffectif()"
                          [(ngModel)]="typeEffectifSelected"></p-selectButton>
        </span>
    </div>
  </dxi-item>
</dx-toolbar>

<div class="d-flex mg-t-14 flex-wrap container-table" style="overflow-y: scroll">

  <div class="left">

    <dx-tabs
      *ngIf="endPointPanel.length > 0 && idSelected"
      [selectedItem]="endPoint"
      [dataSource]="endPointPanel"
      (onItemClick)="updateEndPoint($event)"
    ></dx-tabs>
    <div id="sub-left">
      <yo-plc-semaine *ngIf="startPoint === StartPointCommandesClientsEnum.PLC"
                      [endPoint]="endPoint" [cols]="cols" [typeEffectif]="typeEffectifSelected"></yo-plc-semaine>
      <yo-prestations-semaine *ngIf="startPoint === StartPointCommandesClientsEnum.PRESTATION"
                              [endPoint]="endPoint" [cols]="cols"
                              [typeEffectif]="typeEffectifSelected"></yo-prestations-semaine>
      <yo-repas-semaine *ngIf="startPoint === StartPointCommandesClientsEnum.REPAS"
                        [endPoint]="endPoint" [cols]="cols" [typeEffectif]="typeEffectifSelected"></yo-repas-semaine>
    </div>
    <div class="card-footer mg-t-14">
          <span class="font-weight-normal font-12">
            <div class="text-left">
              <i class="fas fa-square save-color mg-r-5 border"></i>Effectifs en cours de saisie
            </div>
            <div class="text-left">
              <i class="fas fa-square site-secondaire-color mg-r-5 border"></i>Menus en cours de saisie
            </div>
            <div class="text-left">
              <i class="fas fa-square checked-color mg-r-5 border"></i>Menus validés = Saisie des effectifs possible
            </div>
            <div class="text-left">
              <i class="fas fa-square white mg-r-5 border"></i>Aucun menu
            </div>
          </span>
    </div>
  </div>

  <div class="right">
    <yo-repas></yo-repas>
  </div>
</div>

<!--IMPRESSION DES EFFECTIFS-->
<yo-print-effectif></yo-print-effectif>
<yo-print-cout-revient-plat></yo-print-cout-revient-plat>
<yo-print-effectif-totaux></yo-print-effectif-totaux>

<dx-popup
  [showTitle]="true"
  width="450px"
  height="220px"
  title="Dupliquer les effectifs"
  [dragEnabled]="false"
  [showCloseButton]="true"
  [(visible)]="popupDuplicateEffectivesVisible"
>
  <form id="form-duplicate-effectives">
    <div class="row">
      <div class="col-md-12">
        <div>
          <input id="checkbox-fabrication" type="checkbox" [(ngModel)]="duplicateToManufacturing"
                 [ngModelOptions]="{standalone: true}"/>
          <label for="checkbox-fabrication" style="margin-left: 5px;">Fabrication</label>
        </div>
        <div>
          <input id="checkbox-facturation" type="checkbox" [(ngModel)]="duplicateToBilling"
                 [ngModelOptions]="{standalone: true}"/>
          <label for="checkbox-facturation" style="margin-left: 5px;">Facturation</label>
        </div>
      </div>
    </div>

    <div class="text-right" id="form-duplicate-effectives-cta">
      <yo-button
        label="Dupliquer"
        iconClass="fas fa-copy"
        class="mg-r-5 va-top"
        (onClick)="duplicateEffectivesPrevisionnals()"
        tooltip="Copier">
      </yo-button>
      <yo-button
        label="Fermer"
        iconClass="fas fa-times"
        buttonClass="cta-inner-delete"
        (onClick)="closeDialogDuplicateEffectivesPrevisionnals()">
      </yo-button>
    </div>
  </form>
</dx-popup>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
>
</dx-load-panel>
