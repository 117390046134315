import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Subject} from 'rxjs';
import {HttpService} from "../technique/http.service";
import {StartPointCommandesClientsEnum} from "../../enums/start-point-commandes-clients-enum";
import ViewCommandesEffectifsDTO from "../../dtos/planification/views/view-commandes-effectifs-dto";
import RowEffectifsCommandesClientDTO from "../../dtos/planification/row-effectifs-commandes-client-dto";
import DetailEffectifCommandeClientDTO from "../../dtos/planification/detail-effectif-commande-client-dto";
import RepasCommandeClientDTO from "../../dtos/planification/repas-commande-client-dto";
import PrestationCommandeClientDTO from "../../dtos/planification/prestation-commande-client-dto";
import {TypeEffectifEnum} from "../../enums/type-effectif-enum";
import {URL_POST_MENUS_COMPOS_PLC_DATES} from "./point-de-livraison.service";
import {DatePipe} from "@angular/common";
import DatesDetailsFromMenuDTO from "../../dtos/planification/dates-details-from-menu-dto";

@Injectable({
  providedIn: 'root'
})
export class CommandesClientService {

  private subjectViewCommandesEffectif = new Subject<ViewCommandesEffectifsDTO>();
  viewCommandesEffectif$ = this.subjectViewCommandesEffectif.asObservable();

  /**
   * permet d'indiquer aux composants repas-plc quelle est la cellule sélectionnée.
   * en effet, on peut afficher plusieurs repas plc en meme temps (dejeuner, gouter, diner...)
   * mais on ne veut qu'une seule cellule sélectionnée pour l'ensemble de ces repas-plc
   * il faut un moyen de deselectionner la cellule precedemment selectionnée dans un repas plc différent de celui actuel
   */
  private subjectSelectedCell = new Subject<RowEffectifsCommandesClientDTO>();
  selectedCell$ = this.subjectSelectedCell.asObservable();

  private subjectDetailsEffectifCommandeClient = new Subject<DetailEffectifCommandeClientDTO[]>();
  detailsEffectifCommandeClient$ = this.subjectDetailsEffectifCommandeClient.asObservable();

  private subjectSelectedRepas = new Subject<RepasCommandeClientDTO>();
  selectedRepas$ = this.subjectSelectedRepas.asObservable();

  private subjectSelectedPrestation = new Subject<PrestationCommandeClientDTO>();
  selectedPrestation$ = this.subjectSelectedPrestation.asObservable();

  private subjectRefreshViewCommandesEffectif = new Subject();
  refreshViewCommandesEffectif$ = this.subjectRefreshViewCommandesEffectif.asObservable();

  private subjectSaveEffectifsCommandesClientsFromRow = new Subject<RowEffectifsCommandesClientDTO[]>();
  saveEffectifsCommandesClientsFromRow$ = this.subjectSaveEffectifsCommandesClientsFromRow.asObservable();

  private subjectSaveEffectifsCommandesClientsFromDetail = new Subject<[boolean, boolean, DetailEffectifCommandeClientDTO[]]>();
  saveEffectifsCommandesClientsFromDetail$ = this.subjectSaveEffectifsCommandesClientsFromDetail.asObservable();

  private subjectSelectedTypeEffectif = new Subject<TypeEffectifEnum>();
  selectedTypeEffectif$ = this.subjectSelectedTypeEffectif.asObservable();

  private subjectChangeEffectifsCommandesClients = new Subject();
  changeEffectifsCommandesClients$ = this.subjectChangeEffectifsCommandesClients.asObservable();

  private subjectMenuMonthChange = new Subject<[any, boolean]>();
  menuMonthChange$ = this.subjectMenuMonthChange.asObservable();

  private subjectLoadingView = new Subject<boolean>();
  loadingView$ = this.subjectLoadingView.asObservable();

  private subjectDatesDetailsFromMenu = new Subject<DatesDetailsFromMenuDTO>();
  datesDetailsFromMenu$ = this.subjectDatesDetailsFromMenu.asObservable();

  private subjectEndPointUpdated = new Subject<StartPointCommandesClientsEnum>();
  endPointUpdated$ = this.subjectEndPointUpdated.asObservable();

  private subjectRowUpdated = new Subject<RowEffectifsCommandesClientDTO>();
  rowUpdated$ = this.subjectRowUpdated.asObservable();

  private subjectDetailUpdated = new Subject<DetailEffectifCommandeClientDTO>();
  detailUpdated$ = this.subjectDetailUpdated.asObservable();

  constructor(private utils: UtilsService,
              private httpSvc: HttpService,
              public datepipe: DatePipe) {
  }


  loadViewCommandesEffectif = (weekMonday: Date, weekSunday: Date, idSelected: number,
                               startPoint: StartPointCommandesClientsEnum, endPoint: StartPointCommandesClientsEnum) => {

    const dateDebut = this.datepipe.transform(weekMonday, 'yyyyMMdd');
    const dateFin = this.datepipe.transform(weekSunday, 'yyyyMMdd');

    return this.httpSvc.get(`dolrest/gestion-clients/view/effectifs/semaine`, new HttpParams()
      .set('dateDebut', dateDebut)
      .set('dateFin', dateFin)
      .set('idSelected', idSelected)
      .set('startPoint', startPoint)
      .set('endPoint', endPoint)
    )
  };

  saveEffectifsCommandesClients = (idSelected: number, startPoint: StartPointCommandesClientsEnum, endPoint: StartPointCommandesClientsEnum,
                                   viewCommandesEffectifs: ViewCommandesEffectifsDTO, rows: RowEffectifsCommandesClientDTO[],
                                   details: DetailEffectifCommandeClientDTO[], platRestored: boolean, platUpdated: boolean) => {
    let params: HttpParams;

    params = new HttpParams()
      .set('idSelected', idSelected)
      .set('startPoint', startPoint)
      .set('endPoint', endPoint)
      .set('platRestored', platRestored ? platRestored : false)
      .set('platUpdated', platUpdated ? platUpdated : false);

    return this.httpSvc.post(`dolrest/gestion-clients/view/effectifs/save`, {
      viewCommandesEffectifs,
      rows,
      details
    }, params)
  }

  duplicateEffectifsCommandesClients = (weekMonday: Date, weekSunday: Date, idSelected: number,
                                        startPoint: StartPointCommandesClientsEnum, endPoint: StartPointCommandesClientsEnum,
                                        viewCommandesEffectifs: ViewCommandesEffectifsDTO, typesEffectifs: TypeEffectifEnum[]) => {
    const dateDebut = this.datepipe.transform(weekMonday, 'yyyyMMdd');
    const dateFin = this.datepipe.transform(weekSunday, 'yyyyMMdd');

    return this.httpSvc.post(`dolrest/gestion-clients/view/effectifs/duplicate`, viewCommandesEffectifs, new HttpParams()
      .set('dateDebut', dateDebut)
      .set('dateFin', dateFin)
      .set('idSelected', idSelected)
      .set('startPoint', startPoint)
      .set('endPoint', endPoint)
      .set('typesEffectifs', typesEffectifs.join(","))
    )
  }

  exportViewCommandesEffectif = (weekMonday: Date, weekSunday: Date, idSelected: number,
                                 startPoint: StartPointCommandesClientsEnum, endPoint: StartPointCommandesClientsEnum,
                                 idRepasList: number[], modeList: string[], idRegimeList: number[]) => {

    const dateDebut = this.datepipe.transform(weekMonday, 'yyyyMMdd');
    const dateFin = this.datepipe.transform(weekSunday, 'yyyyMMdd');

    return this.httpSvc.get(`dolrest/gestion-clients/view/effectifs/semaine/export`, new HttpParams()
        .set('dateDebut', dateDebut)
        .set('dateFin', dateFin)
        .set('idSelected', idSelected)
        .set('startPoint', startPoint)
        .set('endPoint', endPoint)
        .set('idRepasList', idRepasList.join(","))
        .set('idModeList', modeList.join(","))
        .set('idRegimeList', idRegimeList.join(","))
      , 'blob',
      new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    )
  };

  exportViewCommandesEffectifDetailsMenu = (weekMonday: Date, weekSunday: Date, idSelected: number,
                                            startPoint: StartPointCommandesClientsEnum, endPoint: StartPointCommandesClientsEnum,
                                            idRepasList: number[], modeList: string[], idRegimeList: number[]) => {

    const dateDebut = this.datepipe.transform(weekMonday, 'yyyyMMdd');
    const dateFin = this.datepipe.transform(weekSunday, 'yyyyMMdd');

    return this.httpSvc.get(`dolrest/gestion-clients/view/effectifs/semaine/export/details`, new HttpParams()
        .set('dateDebut', dateDebut)
        .set('dateFin', dateFin)
        .set('idSelected', idSelected)
        .set('startPoint', startPoint)
        .set('endPoint', endPoint)
        .set('idRepasList', idRepasList.join(","))
        .set('idModeList', modeList.join(","))
        .set('idRegimeList', idRegimeList.join(","))
      , 'blob',
      new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    )
  };

  getMonthDates = (event, startDate: Date, idSelected: number, startPoint: StartPointCommandesClientsEnum) => {
    let dateDebut = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
    let dateFin = new Date(startDate.getFullYear(), startDate.getMonth() + 2, 0);

    // si ca vient du onMonthChange
    if (event && event.month && event.year) {
      dateDebut = new Date(event.year, event.month - 1, 1);
      dateFin = new Date(event.year, event.month + 1, 0);
    }

    return this.httpSvc.get(URL_POST_MENUS_COMPOS_PLC_DATES, new HttpParams()
      .set('dateDebut', this.datepipe.transform(dateDebut, 'yyyyMMdd'))
      .set('dateFin', this.datepipe.transform(dateFin, 'yyyyMMdd'))
      .set('idSelected', idSelected ? idSelected : -1)
      .set('startPoint', startPoint)
    );
  };

  announceViewCommandesEffectif = (viewCommandesEffectif: ViewCommandesEffectifsDTO) => {
    this.subjectViewCommandesEffectif.next(viewCommandesEffectif);
  };

  announceRefreshViewCommandesEffectif = () => {
    this.subjectRefreshViewCommandesEffectif.next();
  }

  announceSelectedCell = (selectedCell: RowEffectifsCommandesClientDTO) => {
    this.subjectSelectedCell.next(selectedCell);
  };

  announceSelectedRepas = (selectedRepas: RepasCommandeClientDTO) => {
    this.subjectSelectedRepas.next(selectedRepas);
  };

  announceSelectedPrestation = (selectedPrestation: PrestationCommandeClientDTO) => {
    this.subjectSelectedPrestation.next(selectedPrestation);
  };

  announceDetailsEffectifCommandeClient = (detailsEffectifCommandeClient: DetailEffectifCommandeClientDTO[]) => {
    this.subjectDetailsEffectifCommandeClient.next(detailsEffectifCommandeClient);
  };

  announceSaveEffectifsCommandesClientsFromRow = (rows: RowEffectifsCommandesClientDTO[]) => {
    this.subjectSaveEffectifsCommandesClientsFromRow.next(rows);
  }

  announceSaveEffectifsCommandesClientsFromDetail = (platRestored: boolean, platUpdated: boolean, details: DetailEffectifCommandeClientDTO[]) => {
    this.subjectSaveEffectifsCommandesClientsFromDetail.next([platRestored, platUpdated, details]);
  }

  announceSelectedTypeEffectif = (selectedMode: TypeEffectifEnum) => {
    this.subjectSelectedTypeEffectif.next(selectedMode);
  };

  announceChangeEffectifsCommandesClients = () => {
    this.subjectChangeEffectifsCommandesClients.next();
  }

  announceMenuMonthChange = (event: any, clickOutside: boolean) => {
    this.subjectMenuMonthChange.next([event, clickOutside]);
  };

  announceLoadingView = (isLoading: boolean) => {
    this.subjectLoadingView.next(isLoading);
  };

  announceDatesDetailsFromMenu = (datesDetailsFromMenu: DatesDetailsFromMenuDTO) => {
    this.subjectDatesDetailsFromMenu.next(datesDetailsFromMenu);
  };

  announceEndPointUpdated = (endPoint: StartPointCommandesClientsEnum) => {
    this.subjectEndPointUpdated.next(endPoint);
  }

  announceRowUpdated = (row: RowEffectifsCommandesClientDTO) => {
    this.subjectRowUpdated.next(row);
  }

  announceDetailUpdated = (detail: DetailEffectifCommandeClientDTO) => {
    this.subjectDetailUpdated.next(detail);
  }
}


