import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GestionPlcRoutingModule} from './gestion-plc-routing.module';
import {SharedModule} from '../shared/shared.module';
import {GplcRootComponent} from './gplc-root/gplc-root.component';
import {MenuPlcComponent} from './menu-plc/menu-plc.component';
import {PrestationsSemaineComponent} from './menu-plc/prestations-semaine/prestations-semaine.component';
import {RepasComponent} from './menu-plc/repas/repas.component';
import {GestionmenusModule} from '../gestion-menus/gestionmenus.module';
import {PrintEffectifComponent} from './menu-plc/print-effectif/print-effectif.component';
import {PrintEffectifService} from '../core/services/gestion-plc/print-effectif.service';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PrintCoutRevientPlatComponent} from './menu-plc/print-cout-revient-plat/print-cout-revient-plat.component';
import {PrintCoutRevientPlatService} from '../core/services/gestion-plc/print-cout-revient-plat.service';
import {TableModule} from 'primeng/table';
import {TabMenuModule} from 'primeng/tabmenu';
import {DialogModule} from 'primeng/dialog';
import {CalendarModule} from 'primeng/calendar';
import {MenuModule} from 'primeng/menu';
import {DropdownModule} from 'primeng/dropdown';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SelectButtonModule} from 'primeng/selectbutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {RepasPlcComponent} from './menu-plc/prestations-semaine/repas-plc/repas-plc.component';
import {AccordionModule} from "primeng/accordion";
import {PortailPlcComponent} from "./portail/portail-plc.component";
import {GenericPrestationsSemaineComponent} from "./menu-plc/generic-prestations-semaine/generic-prestations-semaine.component";
import {GenericRepasPlcComponent} from "./menu-plc/generic-prestations-semaine/generic-repas-plc/generic-repas-plc.component";
import {GenericRepasComponent} from "./menu-plc/generic-prestations-semaine/generic-repas/generic-repas.component";
import {
  DxAccordionModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDateRangeBoxModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxLookupModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTabPanelModule,
  DxTabsModule,
  DxTagBoxModule,
  DxToolbarModule,
  DxTooltipModule,
  DxTreeViewModule
} from "devextreme-angular";
import {PrintEffectifTotauxComponent} from "./menu-plc/print-effectif-totaux/print-effectif-totaux.component";
import {PlcSemaineComponent} from "./menu-plc/plc-semaine/plc-semaine.component";
import {PrestationsRepasComponent} from "./menu-plc/plc-semaine/prestations-repas/prestations-repas.component";
import {RepasPrestationsComponent} from "./menu-plc/plc-semaine/repas-prestations/repas-prestations.component";
import {PlcRepasComponent} from "./menu-plc/prestations-semaine/plc-repas/plc-repas.component";
import {PrestationsPlcComponent} from "./menu-plc/repas-semaine/prestations-plc/prestations-plc.component";
import {PlcPrestationsComponent} from "./menu-plc/repas-semaine/plc-prestations/plc-prestations.component";
import {RepasSemaineComponent} from "./menu-plc/repas-semaine/repas-semaine.component";
import {CardModule} from "primeng/card";

@NgModule({
    imports: [
        CommonModule,
        GestionPlcRoutingModule,
        GestionmenusModule,
        SharedModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        CardModule,
        TableModule,
        TabMenuModule,
        DialogModule,
        CalendarModule,
        MenuModule,
        DropdownModule,
        RadioButtonModule,
        SelectButtonModule,
        MultiSelectModule,
        AccordionModule,
        DxPopupModule,
        DxDateBoxModule,
        DxLookupModule,
        DxTagBoxModule,
        DxToolbarModule,
        DxTreeViewModule,
        DxSelectBoxModule,
        DxAccordionModule,
        DxDataGridModule,
        DxTabPanelModule,
        DxTabsModule,
        DxLoadPanelModule,
        DxLoadIndicatorModule,
        DxNumberBoxModule,
        DxTooltipModule,
        DxDateRangeBoxModule
    ],
  declarations: [
    GplcRootComponent,
    MenuPlcComponent,
    PrestationsSemaineComponent,
    RepasComponent,
    PrintEffectifComponent,
    PrintEffectifTotauxComponent,
    PrintCoutRevientPlatComponent,
    RepasPlcComponent,
    PortailPlcComponent,
    GenericPrestationsSemaineComponent,
    GenericRepasPlcComponent,
    GenericRepasComponent,
    PlcSemaineComponent,
    PrestationsRepasComponent,
    RepasPrestationsComponent,
    PlcRepasComponent,
    PrestationsPlcComponent,
    PlcPrestationsComponent,
    RepasSemaineComponent
  ],
  providers: [
    PrintEffectifService,
    PrintCoutRevientPlatService
  ]
})
export class GestionPlcModule {
}
