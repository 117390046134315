import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DialogMsgSupplier} from '../../../core/suppliers/dialog-msg-supplier';
import {UtilsService} from '../../../core/utils/utils.service';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpService} from "../../../core/services/technique/http.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'yo-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, OnDestroy {

  displayDialog = false;
  dms: DialogMsgSupplier;

  fileData: String;

  private subData: Subscription;

  @Input() lang: string = 'fr';
  @Input() file: string = 'blank';
  /**
   * @deprecated
   */
  @Input() dialogMsgSupplier: DialogMsgSupplier;
  @Input() width: number = 450;

  @Input() set iconClick(value: string) {
    this._iconClick = value;
  }

  _iconClick = 'fa fa-question-circle yoni-color';

  constructor(public utilsSvc: UtilsService, public domSanitizer: DomSanitizer, private httpSvc: HttpService) {
  }

  ngOnInit() {
    this.initData();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subData);
  }

  initData = () => {
    if (this.lang && this.file && this.file != "blank")
      this.subData = this.httpSvc.get('assets/markdown/help/' + this.lang + '/' + this.file + '.md',
        null,
        'blob'
      ).subscribe(async response => {
        this.fileData = await response.text();
      });
  }

  initDms = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Aide`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    return dms;
  };

  getHelp: () => void = () => {
    this.dms = this.utilsSvc.isNullOrEmpty(this.dialogMsgSupplier) ? this.initDms() : this.dialogMsgSupplier;
    this.dms.logo = `fas fa-question-circle fa-2x  yoni-color`;
    this.displayDialog = true;
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  isDataFound = (): boolean => {
    return this.fileData && this.fileData.length > 0;
  };
}
