// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[b878a2e59156e346797eb4175a0df92b17a4f55b]6.1-10996(b878a2e591)-C14/11/2024-17:20:21-B15/11/2024-09:05:42' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[b878a2e59156e346797eb4175a0df92b17a4f55b]6.1-10996(b878a2e591)-C14/11/2024-17:20:21-B15/11/2024-09:05:42",
  branch: "b878a2e59156e346797eb4175a0df92b17a4f55b",
  latestTag: "6.1",
  revCount: "10996",
  shortHash: "b878a2e591",
  longHash: "b878a2e59156e346797eb4175a0df92b17a4f55b",
  dateCommit: "14/11/2024-17:20:21",
  dateBuild: "15/11/2024-09:05:42",
  buildType: "Ansible",
  } ;
