import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {AllergeneSupplier} from './allergene-resolver.service';
import {ProduitDeclinaisonDTO} from '../../../core/dtos/produit-declinaison-dto';
import {ProduitAllergeneDTO} from '../../../core/dtos/produit-allergene-dto';
import {AllergenesService} from '../../../core/services/entities/allergenes-service.service';
import {find as _find} from 'lodash';
import {ProduitAllergeneService} from '../../../core/services/entities/produit-allergene.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {HELP_FOLDERS} from '../../../core/constants';
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'app-allergene-produit',
  templateUrl: './allergene-produit.component.html',
  styleUrls: ['./allergene-produit.component.scss']
})
export class AllergeneProduitComponent implements OnInit, OnDestroy {

  subRouteData: Subscription;
  allergenesSupplier: AllergeneSupplier;
  produit: ProduitDTO;
  totalRecords: number = 0;

  allergeneEntityName: string;
  refreshImg: number = 1;

  mapSelection: Map<string, ProduitAllergeneDTO> = new Map();
  mapActiveRow: Map<number, boolean> = new Map();
  mapActiveCol: Map<number, boolean> = new Map();

  cols: any[] = [];

  pathFile: string = HELP_FOLDERS.PRODUITS + '/produits-allergenes';

  constructor(public utils: UtilsService,
              private produitAllergeneSvc: ProduitAllergeneService,
              public allergeneSvc: AllergenesService,
              private route: ActivatedRoute,
              public gds: GenericDatagridService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.allergeneEntityName = this.allergeneSvc.getEntityName();
    this.subscriptionRouteData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRouteData);
  }

  subscriptionRouteData = () => {
    this.subRouteData = this.route.data.subscribe((data: { allergeneSupplier: AllergeneSupplier }) => {

      this.allergenesSupplier = data.allergeneSupplier;
      this.produit = data.allergeneSupplier.produit;
      this.totalRecords = this.allergenesSupplier.allergenes.length;
      this.refreshImg = new Date().getTime();
      this.cols = this.initCols(this.allergenesSupplier);
      this.mapSelection = this.initMapSelection(this.allergenesSupplier);
      this.mapActiveRow = this.initMapActiveRow(this.allergenesSupplier, this.mapSelection);
    });
  };

  help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Paramétrage d'un produit/denrée`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Allergènes d'un <b>produit/denrée</b>`;
    p1.lines = [
      `Il s’agit en fait d’identifier ici les allergènes pour chacun des <b>articles</b> de la <b>déclinaison</b>.`,
      `S’il n’existe aucun <b>article</b> il conviendra de renseigner les allergènes pour la <b>déclinaison</b>.`,
    ];

    dms.content = {
      intro: ``,
      paragraphes: [p1]
    };
    return dms;
  };


  /**
   * Initialiser la map de selection
   */
  initMapSelection = (allergenesSupplier: AllergeneSupplier): Map<string, ProduitAllergeneDTO> => {
    const selectionMap: Map<string, ProduitAllergeneDTO> = new Map();
    if (!this.utils.isNullOrEmpty(allergenesSupplier)) {
      if (!this.utils.isCollectionNullOrEmpty(allergenesSupplier.allergenes) && !this.utils.isCollectionNullOrEmpty(allergenesSupplier.produitsDeclinaisons)) {

        let produitsAllergenes = this.utils.isCollectionNullOrEmpty(allergenesSupplier.produitsAllergenes) ? [] : allergenesSupplier.produitsAllergenes;


        // map des produits declinaisons , utilisé pour savoir si on a une liste d'allergenes incomplete
        const mapProduitDecli: Map<number, { produitDecli: ProduitDeclinaisonDTO, exists: boolean }> = new Map();

        for (let al of allergenesSupplier.allergenes) {

          for (let pd of allergenesSupplier.produitsDeclinaisons) {

            // tous les produits declis sont considérés en info incomplete (exists=false) tant qu'on n'en pas trouvé 1 en BDD.
            if (!mapProduitDecli.get(pd.id)) {
              mapProduitDecli.set(pd.id, {produitDecli: pd, exists: false});
            }

            const paExists = _find(produitsAllergenes, {'produitDeclinaisonId': pd.id, 'allergeneId': al.id});
            if (paExists) {
              // on n'est plus en info incomplete
              mapProduitDecli.set(pd.id, {produitDecli: pd, exists: true});

              selectionMap.set(this.getKeySelectionMap(pd.id, al.id), paExists);
            } else {
              const pa = this.produitAllergeneSvc.createEmptyProduitAllergene(pd, al);
              selectionMap.set(this.getKeySelectionMap(pd.id, al.id), pa);
            }
          }
        }
      }
    }
    return selectionMap;
  };

  private initMapActiveRow = (allergeneSupplier: AllergeneSupplier, mapSelection: Map<string, ProduitAllergeneDTO>): Map<number, boolean> => {
    const mapActiveRow: Map<number, boolean> = new Map();

    if (!this.utils.isNullOrEmpty(allergeneSupplier)) {
      if (!this.utils.isCollectionNullOrEmpty(allergeneSupplier.allergenes)) {

        for (let al of allergeneSupplier.allergenes) {
          let active = true;
          mapSelection.forEach((value, key) => {
            if (value.allergeneId === al.id) {
              if (!value.actif) {
                active = false;
              }
            }
          });
          mapActiveRow.set(al.id, active);
        }
      }
    }
    return mapActiveRow;
  };

  /**
   * Clé de sélection du produitAllergene
   * @param idProduitDeclinaison
   * @param idAllergene
   */
  getKeySelectionMap = (idProduitDeclinaison: number, idAllergene: number): string => idProduitDeclinaison + '-' + idAllergene;

  /**
   * Initialiser les colonnes
   * @param appellationsSupplier
   */
  initCols = (allergenesSupplier: AllergeneSupplier): any[] => {

    const cols = [];

    cols.push({field: 'allergene.libelle', header: 'ALLERGÈNE'});
    cols.push({field: 'allergene.logo', header: 'LOGO'});

    if (!this.utils.isNullOrEmpty(allergenesSupplier)) {
      const produitsDeclinaisons = this.allergenesSupplier.produitsDeclinaisons;
      if (!this.utils.isCollectionNullOrEmpty(produitsDeclinaisons)) {
        produitsDeclinaisons.forEach(pd => cols.push({
          field: pd.id,
          header: pd.fabrique ? pd.declinaison.libelle : pd.libelle
        }));
      }
    }
    return cols;
  };

  getActiveProduitAllergene = (idAllergene: number, idProduitDeclinaison: number) => {
    const key = this.getKeySelectionMap(idProduitDeclinaison, idAllergene);
    return this.mapSelection.get(key).actif;

  };

  setActiveProduitAllergene = (idAllergene: number, idProduitDeclinaison: number, $event: boolean) => {
    const key = this.getKeySelectionMap(idProduitDeclinaison, idAllergene);
    this.mapSelection.get(key).actif = $event;

    const pa = this.mapSelection.get(key);
    const rowKey = pa.allergeneId;

    let activeRow = true;
    let activeAll = true;
    this.mapSelection.forEach((value, keySel) => {
      // check ligne
      if (value.allergeneId === rowKey) {
        if (!value.actif) {
          activeRow = false;
        }
      }
      // check all
      if (!value.actif) {
        activeAll = false;
      }
    });
    this.mapActiveRow.set(rowKey, activeRow);
  };
}
