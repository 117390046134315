<ng-container *ngIf="canDisplayGrid()">
  <h5 class="first mg-b-38">
    <span class="badge badge-primary mg-r-5">{{selectedRepas?.libelle | uppercase}}</span>
    <span class="badge badge-success mg-r-5">{{selectedPrestation?.libelle | uppercase}}</span>
    <span class="badge badge-warning mg-r-5">{{selectedCell?.regime?.libelle | uppercase}}</span>
    <span class="badge badge-info mg-r-5">{{selectedCell?.date  | date:'EEEE d LLLL'  | uppercase}}</span>
  </h5>

  <div class="container-table">
    <table class="table table-bordered">
      <thead>
      <tr>
        <th [style.width.px]="150">Composante Repas</th>
        <th>Plat</th>
        <th data-toggle="tooltip" title="Taux de prise en %">Tdp %</th>
        <th>
          <!--TYPE EFFECTIF-->
          <ng-container *ngTemplateOutlet="tplThEffectif"></ng-container>
        </th>
      </tr>
      </thead>
      <tbody *ngFor="let item of detailsEffectifCommandeClient">
      <tr class="input-saisie-effectifs-tdp "
          [class.modification-manuelle]="item?.modificationManuelle"
          [class.disable]="item.codeStatutMenu !== STATUTS_MENUS.SAISIE_EFFECTIFS && item.codeStatutMenu !== null">

        <td [style.width.px]="150">
          {{item.composanteRepas.libelle}}
        </td>


        <td class="cursor" (click)="chooseAction(menuActions, $event, item)">
          <p-menu appendTo="body" #menuActions [model]="actions" [popup]="true"></p-menu>
          <ng-container *ngIf="!utils.isNullOrEmpty(item.plat)">

            <div [title]="getHistoLabel(item)"
                 style="width: 100%">

              <ng-container *ngIf="!utils.isNullOrEmpty(item.plat) && item.actif; else elseBlock">
                <ng-container *ngIf="item.plat?.actif">
                  <span>
                    {{item?.plat?.libelle}}
                  </span>

                </ng-container>
                <ng-container *ngIf="!item.plat?.actif">
                  {{item?.plat?.libelle}} <i title="Plat inactif"
                                             class="fas fa-exclamation-triangle danger-color mg-r-5"></i>
                </ng-container>
              </ng-container>

              <ng-template #elseBlock>

              </ng-template>

            </div>
          </ng-container>

        </td>


        <td class="text-right">
          <ng-container *ngTemplateOutlet="tplTauxDePrise;context:{row:item}"></ng-container>
        </td>
        <td class="text-right">
          <ng-container *ngTemplateOutlet="tplEffectif;context:{row:item}"></ng-container>
        </td>

      </tr>
      </tbody>
      <tr>
        <td colspan="4">
          <div class="font-weight-normal font-12 text-left">
            <i class="fas fa-square edit-color mg-r-5 border"></i>Plat ajouté/modifié/supprimé manuellement.
          </div>
        </td>
      </tr>
    </table>

  </div>

</ng-container>

<ng-template #tplThEffectif>
  <ng-container [ngSwitch]="typeEffectif">
    <ng-container *ngSwitchCase="TypeEffectifEnum.PREVISIONNEL">
      <span data-toggle="tooltip" title="Effectif prévisionnel">Eff. prév.</span>
    </ng-container>

    <ng-container *ngSwitchCase="TypeEffectifEnum.FABRICATION">
      <span data-toggle="tooltip" title="Effectif pour la fabrication">Eff. fab.</span>
    </ng-container>

    <ng-container *ngSwitchCase="TypeEffectifEnum.FACTURATION">
      <span data-toggle="tooltip" title="Effectif pour la facturation">Eff. factu.</span>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #tplEffectif let-row="row">

  <ng-container *ngIf="!utils.isNullOrEmpty(row.plat)">

    <input class="cell text-right  bold"
           type="text"
           min="0"
           step="1"
           (keydown)="keyDown($event)"
           [value]="row.effectifPrevu"
           (click)="$event.stopPropagation()"
           (keyup)="changeEffectif($event, row)"
           [class.arrondi]="row.arrondiEffectifPrevu"/>

  </ng-container>
</ng-template>

<ng-template #tplTauxDePrise let-row="row">

  <ng-container *ngIf="!utils.isNullOrEmpty(row.plat)">

    <input class="cell text-right  bold"
           type="text"
           min="0"
           step="1"
           (keydown)="keyDown($event)"
           [value]="row.tauxPrise"
           (click)="$event.stopPropagation()"
           (keyup)="changeTauxDePrise($event, row)"/>

  </ng-container>
</ng-template>

<yo-dialog-recherche-plats></yo-dialog-recherche-plats>
