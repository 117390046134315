import {ContratMenuConviveDecoupageDTO} from './contratmenuconvivedecoupage-dto';
import {ProduitDeclinaisonDTO} from "./produit-declinaison-dto";
import {ContratMenuConviveRepasPlcDateDTO} from "./cmcr-plc-date-dto";
import {AuditedDTO} from "./audited-dto";

export class MenuComposition__PlcDTO extends AuditedDTO {


  produitDeclinaison: ProduitDeclinaisonDTO;
  cmcrPlcDate: ContratMenuConviveRepasPlcDateDTO;
  cmcd: ContratMenuConviveDecoupageDTO;

  ordre: number;
  effectifPrevisionnel: number;
  effectifFabrication: number;
  effectifFacture: number;

  tpEffectifPrevisionnel: number;
  tpEffectifFabrication: number;
  tpEffectifFacture: number;

  arrondiEffectifPrevisionnel: boolean;
  arrondiEffectifFabrication: boolean;
  arrondiEffectifFacture: boolean;

  modificationManuelle:boolean;

  actif:boolean;


}
