import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import RepasPrestationsEffectifsCommandesClientDTO
  from "../../../core/dtos/planification/repas-prestations-effectifs-commandes-client-dto";
import {CommandesClientService} from "../../../core/services/entities/commandes-client.service";
import {StartPointCommandesClientsEnum} from "../../../core/enums/start-point-commandes-clients-enum";
import PrestationsRepasEffectifsCommandesClientDTO
  from "../../../core/dtos/planification/prestations-repas-effectifs-commandes-client-dto";
import ViewCommandesEffectifsDTO from "../../../core/dtos/planification/views/view-commandes-effectifs-dto";
import ViewEffectifsByPlcRepasPrestationDTO
  from "../../../core/dtos/planification/views/view-effectifs-by-plc-repas-prestation-dto";
import ViewEffectifsByPlcPrestationRepasDTO
  from "../../../core/dtos/planification/views/view-effectifs-by-plc-prestation-repas-dto";
import DatesDetailsFromMenuDTO from "../../../core/dtos/planification/dates-details-from-menu-dto";
import {ToastService} from "../../../core/services/technique/toast.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {TypeEffectifEnum} from "../../../core/enums/type-effectif-enum";

@Component({
  selector: 'yo-plc-semaine',
  templateUrl: './plc-semaine.component.html',
  styleUrls: ['./plc-semaine.component.scss'],

})
export class PlcSemaineComponent implements OnInit, OnDestroy {

  @Input("cols") cols: Date[];
  @Input("endPoint") endPoint: StartPointCommandesClientsEnum;
  @Input("typeEffectif") typeEffectif: TypeEffectifEnum;

  subViewCommandesEffectif: Subscription;
  subLoadingView: Subscription;
  subDatesDetailsFromMenu: Subscription;
  subEndPoint: Subscription;

  datesDetailsFromMenu: DatesDetailsFromMenuDTO;
  repas: RepasPrestationsEffectifsCommandesClientDTO[] = [];
  prestations: PrestationsRepasEffectifsCommandesClientDTO[] = [];
  isLoadingView: boolean = false;

  constructor(public utils: UtilsService,
              private commandesClientSvc: CommandesClientService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.viewCommandesEffectifSubscription();
    this.loadingViewSubscription();
    this.datesDetailsFromMenuSubscription();
    this.endPointSubscription();
  }

  viewCommandesEffectifSubscription = () => {
    this.subViewCommandesEffectif = this.commandesClientSvc.viewCommandesEffectif$.subscribe((response: ViewCommandesEffectifsDTO) => {
      let messageToast: string = `Mise à jour des données réussie avec succès !`;
      let messageSeverity: string = MSG_SEVERITY.SUCCESS;
      this.prestations = [];
      this.repas = [];

      if (response) {
        if (this.isPrestationFirst()) {
          this.prestations = (response as ViewEffectifsByPlcPrestationRepasDTO).prestations;
        } else {
          this.repas = (response as ViewEffectifsByPlcRepasPrestationDTO).repas;
        }
      } else {
        messageSeverity = MSG_SEVERITY.WARNING;
        messageToast = `Aucun résultat à afficher pour votre sélection`;
      }
      this.commandesClientSvc.announceLoadingView(false);
      this.toastSvc.displayToast(MSG_KEY.ROOT, messageSeverity, messageToast);
    })
  }

  loadingViewSubscription = () => {
    this.subLoadingView = this.commandesClientSvc.loadingView$
      .subscribe((isLoading) => this.isLoadingView = isLoading);
  }

  datesDetailsFromMenuSubscription = () => {
    this.subDatesDetailsFromMenu = this.commandesClientSvc.datesDetailsFromMenu$
      .subscribe((datesDetailsFromMenu: DatesDetailsFromMenuDTO) => this.datesDetailsFromMenu = datesDetailsFromMenu);
  }

  endPointSubscription = () => {
    this.subEndPoint = this.commandesClientSvc.endPointUpdated$
      .subscribe((endPoint: StartPointCommandesClientsEnum) => this.endPoint = endPoint);
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subViewCommandesEffectif);
    this.utils.unsubscribe(this.subLoadingView);
    this.utils.unsubscribe(this.subDatesDetailsFromMenu);
    this.utils.unsubscribe(this.subEndPoint);
  }

  canDisplayGrille = () => (this.repas?.length || this.prestations?.length) && this.cols?.length && this.datesDetailsFromMenu.joursDisponibles?.length;

  isPrestationFirst = () => this.endPoint === StartPointCommandesClientsEnum.PRESTATION;
}
