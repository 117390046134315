import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {CommandesClientService} from "../../../core/services/entities/commandes-client.service";
import ViewCommandesEffectifsDTO from "../../../core/dtos/planification/views/view-commandes-effectifs-dto";
import {StartPointCommandesClientsEnum} from "../../../core/enums/start-point-commandes-clients-enum";
import DatesDetailsFromMenuDTO from "../../../core/dtos/planification/dates-details-from-menu-dto";
import PrestationsPlcEffectifsCommandesClientDTO
  from "../../../core/dtos/planification/prestations-plc-effectifs-commandes-client-dto";
import PlcPrestationsEffectifsCommandesClientDTO
  from "../../../core/dtos/planification/plc-prestations-effectifs-commandes-client-dto";
import ViewEffectifsByRepasPlcPrestationDTO
  from "../../../core/dtos/planification/views/view-effectifs-by-repas-plc-prestation-dto";
import ViewEffectifsByRepasPrestationPlcDTO
  from "../../../core/dtos/planification/views/view-effectifs-by-repas-prestation-plc-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {ToastService} from "../../../core/services/technique/toast.service";
import {TypeEffectifEnum} from "../../../core/enums/type-effectif-enum";

@Component({
  selector: 'yo-repas-semaine',
  templateUrl: './repas-semaine.component.html',
  styleUrls: ['./repas-semaine.component.scss'],

})
export class RepasSemaineComponent implements OnInit, OnDestroy {

  @Input("cols") cols: Date[];
  @Input("endPoint") endPoint: StartPointCommandesClientsEnum;
  @Input("typeEffectif") typeEffectif: TypeEffectifEnum;

  subViewCommandesEffectif: Subscription;
  subLoadingView: Subscription;
  subDatesDetailsFromMenu: Subscription;
  subEndPoint: Subscription;
  datesDetailsFromMenu: DatesDetailsFromMenuDTO;
  prestations: PrestationsPlcEffectifsCommandesClientDTO[] = [];
  pointsDeLivraison: PlcPrestationsEffectifsCommandesClientDTO[] = [];
  isLoadingView: boolean = false;

  constructor(public utils: UtilsService,
              private commandesClientSvc: CommandesClientService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.viewCommandesEffectifSubscription();
    this.loadingViewSubscription();
    this.datesDetailsFromMenuSubscription();
    this.endPointSubscription();
  }

  viewCommandesEffectifSubscription = () => {
    this.subViewCommandesEffectif = this.commandesClientSvc.viewCommandesEffectif$.subscribe((response: ViewCommandesEffectifsDTO) => {
      let messageToast: string = `Mise à jour des données réussie avec succès !`;
      let messageSeverity: string = MSG_SEVERITY.SUCCESS;
      this.pointsDeLivraison = [];
      this.prestations = [];

      if (response) {
        if (this.isPointDeLivraisonFirst()) {
          this.pointsDeLivraison = (response as ViewEffectifsByRepasPlcPrestationDTO).pointsLivraison;
        } else {
          this.prestations = (response as ViewEffectifsByRepasPrestationPlcDTO).prestations;
        }
      } else {
        messageSeverity = MSG_SEVERITY.WARNING;
        messageToast = `Aucun résultat à afficher pour votre sélection`;
      }

      this.commandesClientSvc.announceLoadingView(false);
      this.toastSvc.displayToast(MSG_KEY.ROOT, messageSeverity, messageToast);
    })
  }

  loadingViewSubscription = () => {
    this.subLoadingView = this.commandesClientSvc.loadingView$
      .subscribe((isLoading) => this.isLoadingView = isLoading);
  }

  datesDetailsFromMenuSubscription = () => {
    this.subDatesDetailsFromMenu = this.commandesClientSvc.datesDetailsFromMenu$
      .subscribe((datesDetailsFromMenu: DatesDetailsFromMenuDTO) => this.datesDetailsFromMenu = datesDetailsFromMenu);
  }

  endPointSubscription = () => {
    this.subEndPoint = this.commandesClientSvc.endPointUpdated$
      .subscribe((endPoint: StartPointCommandesClientsEnum) => this.endPoint = endPoint);
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subViewCommandesEffectif);
    this.utils.unsubscribe(this.subLoadingView);
    this.utils.unsubscribe(this.subDatesDetailsFromMenu);
    this.utils.unsubscribe(this.subEndPoint);
  }

  canDisplayGrille = () => (this.prestations?.length || this.pointsDeLivraison?.length) && this.cols?.length && this.datesDetailsFromMenu.joursDisponibles?.length;

  isPointDeLivraisonFirst = () => this.endPoint === StartPointCommandesClientsEnum.PLC;
}
