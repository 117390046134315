import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {UtilsService} from "../../../../core/utils/utils.service";
import {STATUTS_MENUS} from "../../../../core/constants";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {MenucompositionPlcService} from "../../../../core/services/entities/menucomposition-plc.service";
import {MenuItem} from "primeng/api";
import {Subscription} from "rxjs/index";
import {ConfigurationPortailDTO} from "../../../../core/dtos/portail/configuration-portail-dto";
import {TypeEffectifEnum} from "../../../../core/enums/type-effectif-enum";
import DetailEffectifCommandeClientDTO from "../../../../core/dtos/planification/detail-effectif-commande-client-dto";
import RowEffectifsCommandesClientDTO from "../../../../core/dtos/planification/row-effectifs-commandes-client-dto";
import RepasCommandeClientDTO from "../../../../core/dtos/planification/repas-commande-client-dto";
import PrestationCommandeClientDTO from "../../../../core/dtos/planification/prestation-commande-client-dto";
import {CommandesClientService} from "../../../../core/services/entities/commandes-client.service";

@Component({
  selector: 'yo-generic-repas',
  templateUrl: './generic-repas.component.html',
  styleUrls: ['./generic-repas.component.scss']
})
export class GenericRepasComponent implements OnInit, OnDestroy {

  @Input("configPortailPlc") configPortailPlc: ConfigurationPortailDTO;

  subDetailsEffectifCommandeClient: Subscription;
  subTypeEffectif: Subscription;
  subCellSelected: Subscription;
  subRepasSelected: Subscription;
  subPrestationSelected: Subscription;

  detailsEffectifCommandeClient: DetailEffectifCommandeClientDTO[] = [];
  selectedCell: RowEffectifsCommandesClientDTO;
  selectedRow: DetailEffectifCommandeClientDTO;
  selectedRepas: RepasCommandeClientDTO;
  selectedPrestation: PrestationCommandeClientDTO;

  STATUTS_MENUS = STATUTS_MENUS;
  TypeEffectifEnum = TypeEffectifEnum;
  typeEffectif: TypeEffectifEnum;
  actions: MenuItem[] = [];

  timer: any;

  cellsUpdated: DetailEffectifCommandeClientDTO[];
  isUpdated: boolean = false;

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private mcPlcSvc: MenucompositionPlcService,
              private commandesClientSvc: CommandesClientService) {
  }

  ngOnInit() {
    this.typeEffectifSubscription();
    this.detailsEffectifCommandeClientSubscription();
    this.cellSelectedSubscription();
    this.repasSelectedSubscription();
    this.prestationSelectedSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDetailsEffectifCommandeClient);
    this.utils.unsubscribe(this.subTypeEffectif);
    this.utils.unsubscribe(this.subCellSelected);
    this.utils.unsubscribe(this.subRepasSelected);
    this.utils.unsubscribe(this.subPrestationSelected);
  }

  keyDown = event => event.target.value = event.target.value.replace(/[^0-9]/g, "");

  /**
   * Subscription qui permet de récupérer le type d'effectif sélectionné
   */
  typeEffectifSubscription = () => {
    this.subTypeEffectif = this.commandesClientSvc.selectedTypeEffectif$.subscribe(response => this.typeEffectif = response);
  };

  /**
   * Subscription qui permet de récupérer la cellule sélectionnée
   */
  cellSelectedSubscription = () => {
    this.subCellSelected = this.commandesClientSvc.selectedCell$
      .subscribe(response => {
        this.selectedCell = response;
        if(this.isUpdated && this.cellsUpdated.length)
          this.commandesClientSvc.announceSaveEffectifsCommandesClientsFromDetail(null, null, this.cellsUpdated);
        this.isUpdated = false;
      });
  };

  /**
   * Subscription qui permet de récupérer le repas sélectionné
   */
  repasSelectedSubscription = () => {
    this.subRepasSelected = this.commandesClientSvc.selectedRepas$
      .subscribe(response => this.selectedRepas = response);
  };

  /**
   * Subscription qui permet de récupérer la prestation sélectionnée
   */
  prestationSelectedSubscription = () => {
    this.subPrestationSelected = this.commandesClientSvc.selectedPrestation$
      .subscribe(response => this.selectedPrestation = response);
  };

  /**
   * Subscription qui permet de récupérer les détails liés à la cellule sélectionnée
   */
  detailsEffectifCommandeClientSubscription = () => {
    this.subDetailsEffectifCommandeClient = this.commandesClientSvc.detailsEffectifCommandeClient$
      .subscribe(response => {
        this.detailsEffectifCommandeClient = response;
        if (!this.utils.isCollectionNullOrEmpty(this.detailsEffectifCommandeClient))
          this.detailsEffectifCommandeClient.sort((a, b) => a.composanteRepas.ordre - b.composanteRepas.ordre);
      });
  };

  /**
   * Méthode utilisée pour modifier l'effectif d'un détail
   */
  changeEffectif = ($event: any, cell: DetailEffectifCommandeClientDTO) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      let detail: DetailEffectifCommandeClientDTO = Object.assign({}, cell);
      detail.effectifPrevu = $event.target.value;
      detail.arrondiEffectifPrevu = false;

      let idxItemFound: number = this.cellsUpdated.findIndex(c => c.mcPlc?.id === detail.mcPlc?.id);
      if (idxItemFound > -1)
        this.cellsUpdated[idxItemFound].effectifPrevu = detail.effectifPrevu;
      else
        this.cellsUpdated.push(Object.assign({}, detail));

      cell.effectifPrevu = $event.target.value;
      this.updateTauxDePrise(cell);

      this.isUpdated = true;
    }, 25);
  };

  updateEffectif = (cell: DetailEffectifCommandeClientDTO) => {
    switch (this.typeEffectif) {
      case TypeEffectifEnum.PREVISIONNEL:
        if(this.selectedCell.effectifPrevisionnel) cell.effectifPrevu = Math.round((cell.tauxPrise * this.selectedCell.effectifPrevisionnel) / 100);
        cell.arrondiEffectifPrevu = false;
        break;
      case TypeEffectifEnum.FABRICATION:
        if(this.selectedCell.effectifFabrication) cell.effectifPrevu = Math.round((cell.tauxPrise * this.selectedCell.effectifFabrication) / 100);
        cell.arrondiEffectifPrevu = false;
        break;
      case TypeEffectifEnum.FACTURATION:
        if(this.selectedCell.effectifFacturation) cell.effectifPrevu = Math.round((cell.tauxPrise * this.selectedCell.effectifFacturation) / 100);
        cell.arrondiEffectifPrevu = false;
        break;
    }
  }

  /**
   * Méthode utilisée pour modifier le taux de prise d'un détail
   */
  changeTauxDePrise = ($event: any, cell: DetailEffectifCommandeClientDTO) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      let detail: DetailEffectifCommandeClientDTO = Object.assign({}, cell);
      detail.tauxPrise = $event.target.value;
      detail.arrondiEffectifPrevu = false;

      let idxItemFound: number = this.cellsUpdated.findIndex(c => c.mcPlc?.id === detail.mcPlc?.id);
      if (idxItemFound > -1)
        this.cellsUpdated[idxItemFound].tauxPrise = detail.tauxPrise;
      else
        this.cellsUpdated.push(Object.assign({}, detail));

      cell.tauxPrise = $event.target.value;
      this.updateEffectif(cell);

      this.isUpdated = true;
    }, 25);
  };

  updateTauxDePrise = (cell: DetailEffectifCommandeClientDTO) => {
    switch (this.typeEffectif) {
      case TypeEffectifEnum.PREVISIONNEL:
        if(this.selectedCell.effectifPrevisionnel) cell.tauxPrise = Math.round((cell.effectifPrevu / this.selectedCell.effectifPrevisionnel) * 100);
        cell.arrondiEffectifPrevu = false;
        break;
      case TypeEffectifEnum.FABRICATION:
        if(this.selectedCell.effectifFabrication) cell.tauxPrise = Math.round((cell.effectifPrevu / this.selectedCell.effectifFabrication) * 100);
        cell.arrondiEffectifPrevu = false;
        break;
      case TypeEffectifEnum.FACTURATION:
        if(this.selectedCell.effectifFacturation) cell.tauxPrise = Math.round((cell.effectifPrevu / this.selectedCell.effectifFacturation) * 100);
        cell.arrondiEffectifPrevu = false;
        break;
    }
  }

  /**
   * Méthode qui permet de formatter l'affichage d'un label lorsque l'utilisateur passe sa souris sur le libellé du plat d'un détail
   */
  getHistoLabel = (item: DetailEffectifCommandeClientDTO) => {
    if (!this.utils.isNullOrEmpty(item)) {
      const dateCreation = this.utils.convertNumberDateToDate(item.dateCreation);
      const dateDerniereModification = this.utils.convertNumberDateToDate(item.dateDerniereModification);
      return `Créé le ${this.utils.getFrenchDateHHMM(dateCreation.getTime())} par ${item.creerPar}.<br><br>Dernière modification le ${this.utils.getFrenchDateHHMM(dateDerniereModification.getTime())} par ${item.modifierPar}.`;
    }
  };

  isPlatActif = (rowData: DetailEffectifCommandeClientDTO) => {
    if (this.utils.isNullOrEmpty(rowData.mcPlc)) return false;
    else return rowData.mcPlc.actif;
  };

  isInputDisabled = (row: DetailEffectifCommandeClientDTO) => !this.auth2Svc.isSiteLocal(row.mcPlc.cmcrPlcDate.cmSiteId);

  canDisplayGrid = () => !this.utils.isCollectionNullOrEmpty(this.detailsEffectifCommandeClient);

  isEffectifInputDisabled = (row: DetailEffectifCommandeClientDTO): boolean => {
    if (this.configPortailPlc?.dateLimiteSaisie < new Date(row.mcPlc.cmcrPlcDate.dateRepas)) {
      if (row.mcPlc.cmcrPlcDate.statutMenuCode != STATUTS_MENUS.SAISIE_EFFECTIFS) return true;
      else return !this.auth2Svc.isSiteLocal(row.mcPlc.cmcrPlcDate.cmSiteId);
    } else return true;
  };
}
