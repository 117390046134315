<table class="table table-bordered overflow-scroll">
  <thead>
  <tr>
    <th scope="col">Prestations</th>
    <th scope="col">Régimes</th>
    <th *ngFor="let col of cols" scope="col">{{col | date:'EEE d LLL'}}</th>
  </tr>
  </thead>
  <tbody *ngFor="let item of prestations">
  <tr class="input-saisie-effectifs ">
    <td>{{item.prestation.libelle}}</td>
    <td>{{showRegime(item)}}</td>
    <ng-container *ngFor="let col of cols">
      <ng-container *ngTemplateOutlet="tplCell;context:{cell:getEffectif(col, item.rows)}"></ng-container>
    </ng-container>
  </tr>
  </tbody>
</table>

<ng-template #tplCell let-cell="cell">

  <ng-container *ngIf="!utils.isNullOrEmpty(cell)">
    <td [style.width.px]="colWidth"
        [class.cell-saisie-effectifs]="showStatutMode(cell) === STATUTS_MENUS.SAISIE_EFFECTIFS && selectedCell!=cell"
        [class.cell-creation-menus]="showStatutMode(cell) === STATUTS_MENUS.CREATION_MENUS && selectedCell!=cell"
        [class.cell-selected]="selectedCell === cell"
        class=" cursor text-right" (click)="showRepas(cell)">

      <ng-container [ngSwitch]="typeEffectif">

        <ng-container *ngSwitchCase="TypeEffectifEnum.PREVISIONNEL">
          <input [class.disable]="isEffectifInputDisabled(cell)"
                 type="text"
                 min="0"
                 step="1"
                 (keydown)="keyDown($event)"
                 [id]="cell?.cmcrPlcDate?.id"
                 (focusin)="showRepas(cell)"
                 [ngModel]="cell.effectifPrevisionnel"
                 (ngModelChange)="changeEffectif($event, cell)"
                 (click)="$event.stopPropagation()"
                 class="cell text-right bold">
        </ng-container>

        <ng-container *ngSwitchCase="TypeEffectifEnum.FABRICATION">
          <input [class.disable]="isEffectifInputDisabled(cell)"
                 type="text"
                 min="0"
                 step="1"
                 (keydown)="keyDown($event)"
                 [id]="cell?.cmcrPlcDate?.id"
                 (focusin)="showRepas(cell)"
                 [ngModel]="cell.effectifFabrication"
                 (ngModelChange)="changeEffectif($event, cell)"
                 (click)="$event.stopPropagation()"
                 class=" cell text-right bold">
        </ng-container>

        <ng-container *ngSwitchCase="TypeEffectifEnum.FACTURATION">
          <input type="text"
                 min="0"
                 step="1"
                 (keydown)="keyDown($event)"
                 [id]="cell?.cmcrPlcDate?.id"
                 (focusin)="showRepas(cell)"
                 [class.disable]="isEffectifInputDisabled(cell)"
                 [ngModel]="cell.effectifFacturation"
                 (click)="$event.stopPropagation()"
                 (ngModelChange)="changeEffectif($event, cell)"
                 class=" cell text-right bold">
        </ng-container>

      </ng-container>
    </td>
  </ng-container>

  <ng-container *ngIf="utils.isNullOrEmpty(cell)">
    <td [class.cell-selected]="false">
      <span>&nbsp;</span>
    </td>
  </ng-container>

</ng-template>
