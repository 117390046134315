<ng-container *ngIf="!isLoadingView; else tplNoData">
  <ng-container *ngIf="canDisplayGrille(); else tplNoRepas">

    <div id="accordion">
      <dx-accordion
        *ngIf="isPointDeLivraisonFirst()"
        [dataSource]="pointsDeLivraison"
        [collapsible]="true"
        [multiple]="true"
        [animationDuration]="300"
        [selectedItems]="pointsDeLivraison"
      >
        <div *dxTemplate="let item of 'title'">
          <h6>{{ item.plc.label }}</h6>
        </div>
        <div class="overflow-auto" *dxTemplate="let item of 'item'">
          <yo-plc-prestations [plc]="item.plc" [cols]="cols" [prestations]="item.prestations"
                              [datesDetailsFromMenu]="datesDetailsFromMenu" [typeEffectif]="typeEffectif"></yo-plc-prestations>
        </div>
      </dx-accordion>

      <dx-accordion
        *ngIf="!isPointDeLivraisonFirst()"
        [dataSource]="prestations"
        [collapsible]="true"
        [multiple]="true"
        [animationDuration]="300"
        [selectedItems]="prestations"
      >
        <div *dxTemplate="let item of 'title'">
          <h6>{{ item.prestation.libelle }}</h6>
        </div>
        <div class="overflow-auto" *dxTemplate="let item of 'item'">
          <yo-prestations-plc [plc]="item.pointsLivraison" [cols]="cols" [prestation]="item.prestation"
                              [datesDetailsFromMenu]="datesDetailsFromMenu" [typeEffectif]="typeEffectif"></yo-prestations-plc>
        </div>
      </dx-accordion>
    </div>

  </ng-container>
</ng-container>

<ng-template #tplNoRepas>
  <div class="mt-4 mb-4">
    <i class="mg-r-5 fas fa-exclamation-triangle danger-color"></i>Aucun menu existant pour cette période.
  </div>
</ng-template>

<ng-template #tplNoData>
  <div class="indicators">
    <dx-load-indicator
      id="large-indicator"
      height="60"
      width="60"
    ></dx-load-indicator>
  </div>
</ng-template>
