<dx-popup
  [width]="900"
  [height]="'auto'"
  [showTitle]="true"
  [(visible)]="displayDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeDialog()"
>
  <div *dxTemplate="let data of 'title'">
    <div style="padding: 10px 0">
      <i class="fas fa-print mg-r-5"></i> {{ dialogTitle }}
    </div>
  </div>
  <form novalidate>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Sélection de la période <span
        class="danger-color">*</span></div>
      <div class="col-md-4">
        <dx-date-box [value]="dateFrom" type="date" placeholder="Date de début..."
                     (onValueChanged)="onChangeStartDate($event)"></dx-date-box>
      </div>
      <div class="col-md-4">
        <dx-date-box [value]="dateTo" type="date" placeholder="Date de fin..."
                     (onValueChanged)="onChangeEndDate($event)"></dx-date-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Types d'effectifs <span class="danger-color">*</span>
      </div>
      <div class="col-md-8">
        <dx-tag-box
          #tagEffs
          [multiline]="true"
          [items]="typesEffectives"
          placeholder="Filtrer par type d'effectifs à exporter..."
          displayExpr="label"
          valueExpr="value"
          [searchEnabled]="true"
          [value]="typesEffectifsToExport"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeTypeEffective($event)">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Repas</div>
      <div class="col-md-8">
        <dx-tag-box
          #tagRepas
          [multiline]="true"
          [items]="repas"
          [value]="repasSelected"
          placeholder="Filtrer par repas..."
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeRepas($event)">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Régimes</div>
      <div class="col-md-8">
        <dx-tag-box
          #tagRegimes
          [multiline]="true"
          [items]="regimes"
          [value]="regimesSelected"
          placeholder="Filtrer par régimes..."
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeRegimes($event)">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Clients</div>
      <div class="col-md-8">
        <dx-tag-box
          #tagClients
          [multiline]="true"
          [items]="clients"
          [value]="clientsSelected"
          placeholder="Filtrer par client..."
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeClient($event)">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Points de livraison</div>
      <div class="col-md-8">
        <dx-tag-box
          #tagPlcs
          [multiline]="true"
          [dataSource]="pointsLivraisonsClient"
          [value]="pointsLivraisonsClientSelected"
          placeholder="Filtrer par point de livraison..."
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangePlc($event)">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel">Sites</div>
      <div class="col-md-8">
        <dx-tag-box
          #tagSites
          [multiline]="true"
          [items]="sitesLocaux"
          [value]="sitesSelected"
          placeholder="Filtrer par site..."
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeSite($event)">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10 float-right">
      <div class="col-md-12">
        <yo-button
          iconClass="fas fa-print"
          (onClick)="print()"
          tooltip="Imprimer les effectifs totaux"
          label="Imprimer"
        ></yo-button>
        <span class="mg-l-5">
          <yo-button
            iconClass="fas fa-times"
            buttonClass="cta-inner-delete"
            (onClick)="closeDialog()"
            tooltip="Fermer la boîte de dialogue"
            label="Fermer"
          ></yo-button>
        </span>
      </div>
    </div>
  </form>
</dx-popup>
