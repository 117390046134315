import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DecoupageRepasDTO} from '../../dtos/decoupagerepas-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";


@Injectable()
export class DecoupagesRepasService {

  private subjectOpenDialogEdition: Subject<DecoupageRepasDTO> = new Subject<DecoupageRepasDTO>();

  openDialogEdition$: Observable<DecoupageRepasDTO> = this.subjectOpenDialogEdition.asObservable();

  subjectDecoupageRepasSaved: Subject<DecoupageRepasDTO> = new Subject<DecoupageRepasDTO>();

  decoupageRepasSaved$: Observable<DecoupageRepasDTO> = this.subjectDecoupageRepasSaved.asObservable();


  constructor(private utils: UtilsService,
              private auth2Svc: Auth2Service,
              private http: HttpClient,
              private httpSvc: HttpService) {
  }

  canModify(element: ObjectDTO): boolean {
    if (!element || !element?.site || !element?.site.id)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }

  export = (idsToExport?: number[]): Observable<any> => {
    let params: string = '?1=1';
    if (idsToExport?.length) params += `&ids=${idsToExport.join(',')}`
    return this.http.get(`dolrest/referentiel/base/decoupageRepas/export${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  }

  delete = (idsToDelete: number[]): Observable<any> => {
    return this.httpSvc.delete(`dolrest/referentiel/base/decoupageRepas/delete?ids=${idsToDelete}`, null);
  }

  importFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post("dolrest/referentiel/base/decoupageRepas/import", formData, null);
  }

  announceOpeningEditionDialog = (decoupageRepas: DecoupageRepasDTO): void => {
    this.subjectOpenDialogEdition.next(decoupageRepas);
  }

  announceDecoupageRepasSaved = (): void => {
    this.subjectDecoupageRepasSaved.next(null);
  }

  save = (item: DecoupageRepasDTO): Observable<ResponseWrapper<DecoupageRepasDTO>> => {
    return this.httpSvc.post("dolrest/referentiel/base/decoupageRepas/save", item);
  }

}
