import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {RoutemapService} from '../../core/services/routemap.service';
import {DialogMsgSupplier, Paragraphe} from '../../core/suppliers/dialog-msg-supplier';
import ConditionnementDTO from '../../core/dtos/conditionnement/conditionnement-dto';
import {
  ConditionnementSavedSupplier,
  ConditionnementsService
} from '../../core/services/conditionnements/conditionnements.service';
import {GenericManageDialogService} from '../../core/services/generics/generic-manage-dialog.service';
import {Subscription} from 'rxjs';
import {GenericDatagridService} from "../../core/services/generics/generic-datagrid.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../core/services/technique/toast.service";
import {IsDeletableObject} from "../../core/models/is-deletable-object";
import {CoreErrorModel} from "../../core/models/technique/errors/core-error-model";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";

@Component({
  selector: 'yo-grille-conditionnements',
  templateUrl: './grille-conditionnements.component.html',
  styleUrls: ['./grille-conditionnements.component.scss']
})
export class GrilleConditionnementsComponent implements OnInit, OnDestroy {

  conditionnementsList: ConditionnementDTO[] = [];

  hasIDistri = false;

  subConditionnementSaved: Subscription;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/ref-conditionnements';

  displayDeletePopupErrors: boolean = false;

  errors: any[] = [];

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private genericManageDialogService: GenericManageDialogService,
              private cdtSvc: ConditionnementsService,
              private toastSvc: ToastService,
              private i8nSvc: InternationalizationService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit(): void {
    this.initConditionnements();
    this.initHasGestionIDistri();
    this.conditionnementSavedSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subConditionnementSaved);
  }

  initConditionnements = (): void => {
    this.cdtSvc.getAll()
      .subscribe(response => {
        this.conditionnementsList = response.resultList;
      });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  canEdit = (): boolean => this.auth2Svc.hasSitesLocaux() && this.hasIDistri;

  deleteValues = async (): Promise<void> => {
    this.errors = [];
    const res = await confirm('Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?', 'Confirmation');
    if (res) {
      const idsToDelete: number[] = this.selectedRows
        .map((idSelected: number) => this.conditionnementsList.find(c => c.id === idSelected))
        .map((c: ConditionnementDTO) => c.id);
      this.cdtSvc.deleteByIds(idsToDelete)
        .subscribe(res => {
          if (res && res.one) {
            let response: IsDeletableObject = res.one;
            if (response.deletable)
              this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La suppression de(s) élément(s) a été réalisée avec succès`);

            let errors: CoreErrorModel[] = response.messagesErrorList;
            let idsToKeep = errors.map(error => Number(error.args));

            this.conditionnementsList = this.conditionnementsList.filter(v => !(idsToDelete.includes(v.id) && !idsToKeep.includes(v.id)));

            if (errors && errors.length > 0) {
              errors.forEach(error => this.errors.push({
                infosLine: this.i8nSvc.getLabelFromCode(error.code, this.conditionnementsList.filter(item => item.id == Number(error.args)).map(item => item.code))
              }));
              this.displayDeletePopupErrors = true;
            }
          }
        });
    }
  };

  conditionnementSavedSubscription = (): void => {
    this.subConditionnementSaved = this.cdtSvc.conditionnementSaved$
      .subscribe((response: ConditionnementSavedSupplier) => {
        if (!response.isUpdate)
          this.conditionnementsList.push(response.conditionnement);
        else {
          const index = this.conditionnementsList.findIndex(cdt => cdt.id === response.conditionnement.id)
          this.conditionnementsList[index] = response.conditionnement
        }
      });
  };

  calculateSiteValue = (cdt: ConditionnementDTO): string => cdt?.site?.libelle;

  openConditionnement = (cdt: ConditionnementDTO): void => {
    this.cdtSvc.announceOpenDialogConditionnement(cdt);
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les conditionnements`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };


  closeErrors = () => {
    this.displayDeletePopupErrors = false;
  }
}
