import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChildren} from "@angular/core";
import {PointDeLivraisonService} from "../../../core/services/entities/point-de-livraison.service";
import {combineLatest, Subscription} from "rxjs";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {PointDeLivraisonDTO} from "../../../core/dtos/point-de-livraison-d-t-o";
import {ConditionnementRegroupementService} from "../../../core/services/conditionnements/regroupement/conditionnement-regroupement.service";
import {RegleRegimeModel} from "../../../core/models/gestion-conditionnements/regroupement/regle-regime-model";
import {DATAGRID_ROW_TYPES} from "../../../core/services/technique/devextreme.service";
import {MSG_KEY, MSG_SEVERITY, UI_COLORS} from "../../../core/constants";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../core/services/technique/toast.service";
import {WizardService} from "../../../core/services/wizard-service";

@Component({
  selector: 'yo-wizard-configuration-step-11',
  templateUrl: './wizard-configuration-step-11.component.html',
  styleUrls: ['./wizard-configuration-step-11.component.scss']
})
export class WizardConfigurationStepElevenComponent implements OnInit, OnDestroy {

  @Output() onEmitPreviousStep = new EventEmitter<any>();

  @Output() onEmitNextStep = new EventEmitter<any>();

  private subPlcs: Subscription;

  private subCombine: Subscription;

  dataStepEight: any;

  pointsLivraisons: PointDeLivraisonDTO[] = [];

  private currentStep: number;
  rulesByPlcs: any;

  disableSaveByPlcs: any = {};

  prestationsRegimesByPlcs: any;
  allMode: string;
  checkBoxesMode: string;

  selectedRows: any = {};

  /**
   * Pour éviter l'effet suivant :
   * 0 1 => 1 0 alors que l'on veut 0 1 => 0 0 ou 0 1 => 1 1
   * Conclusion : Ne pas utiliser actif = !actif
   */
  toogleByRulesByPlc: any = {};

  cptIdRulesByPlc: any = {};

  @ViewChildren("parts") parts: any;

  constructor(private readonly plcSvc: PointDeLivraisonService,
              private readonly wizardSvc: WizardConditionnementService,
              private readonly conditionnementRegroupementSvc: ConditionnementRegroupementService,
              private readonly utilsSvc: UtilsService,
              private readonly cdtRegroupementSvc: ConditionnementRegroupementService,
              private readonly toastSvc: ToastService,
              private readonly wizardGlobalSvc: WizardService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
    this.currentStep = this.wizardSvc.STEP_GROUPING_PLCS;
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subPlcs);
  }

  ngOnInit(): void {
    this.dataStepEight = this.wizardSvc.getDataStepEight();
    this.initData();
  }

  initData = (): void => {
    this.subPlcs = this.plcSvc.getPlcWithMcPlcList(this.dataStepEight?.id)
      .subscribe((res) => {
        this.pointsLivraisons = [...new Map(res.resultList.map((item: PointDeLivraisonDTO) => [item["id"], item])).values()] as PointDeLivraisonDTO[];
        this.pointsLivraisons.forEach(plc => {
          this.cptIdRulesByPlc[plc.id] = 0;
          this.selectedRows[plc.id] = [];
          this.toogleByRulesByPlc[plc.id] = new Map<number, boolean>();
        });
        const combine$ = combineLatest([
          this.conditionnementRegroupementSvc.getAllRulesByIdsPlc([...new Set(this.pointsLivraisons.map(pl => pl.id))]),
          this.conditionnementRegroupementSvc.getAllPrestationsRegimesByIdsPlc([...new Set(this.pointsLivraisons.map(pl => pl.id))])
        ]);
        this.subCombine = combine$.subscribe((res) => {
          this.rulesByPlcs = res[0].one;
          this.prestationsRegimesByPlcs = res[1].one;

          if (this.rulesByPlcs) {
            Object.entries(this.rulesByPlcs).forEach((entry) => {
              let idPlc: number = parseInt(entry[0]);
              let rules: any[] = (entry[1] as any[]);
              if (rules.length && rules[idPlc] && this.toogleByRulesByPlc[idPlc]) {
                const numRules: number[] = rules[idPlc].map(r => r.id);
                this.cptIdRulesByPlc[idPlc] = Math.max(...numRules); // Stockage du dernier n° de règle
                rules.forEach(r => this.toogleByRulesByPlc[idPlc].set(r.id, false));
              }
            });
          }

          this.pointsLivraisons.forEach(plc => {
            if (!this.rulesByPlcs[plc.id]?.length) {
              this.createRule(plc.id);
              this.disableSaveByPlcs[plc.id] = true;
            }
          });
          this.pointsLivraisons.forEach(plc => {
            if (this.rulesByPlcs[plc.id]?.length)
              this.saveRules(plc.id);
          });
        });
      });
  }

  toggleRow = (plcId: number, idRule: number): void => {
    this.disableSaveByPlcs[plcId] = false;
    const rule: RegleRegimeModel = this.rulesByPlcs[plcId].find(r => r.id === idRule);
    if (rule) {
      const currentToggleValue: boolean = this.toogleByRulesByPlc[plcId].get(rule.id);
      rule.prestations.forEach(p => {
        p.regimes.forEach(r => r.actif = !currentToggleValue);
      });
      this.toogleByRulesByPlc[plcId].set(rule.id, !currentToggleValue);
      this.parts.forEach(grid => grid.instance.refresh());
    }
  }

  createRule = (plcId: number): void => {
    this.disableSaveByPlcs[plcId] = false;
    ++this.cptIdRulesByPlc[plcId];
    const numRule = JSON.parse(JSON.stringify(this.cptIdRulesByPlc[plcId])); // copie pour éviter la référence
    const rule: RegleRegimeModel = new RegleRegimeModel();
    rule.id = numRule;
    rule.plcId = plcId;
    rule.prestations = this.prestationsRegimesByPlcs[plcId].map(p => ({ id: p.id, libelle: p.libelle, regimes: p.regimes.map(r => ({ id: r.id, libelle: r.libelle, actif: true }) ) }));
    if (rule.prestations.length === 1 && rule.prestations[0].regimes.length === 1) {
      rule.prestations[0].regimes[0].actif = false;
    }
    this.rulesByPlcs[plcId].push(rule);
    this.toogleByRulesByPlc[plcId].set(rule.id, (rule.prestations.length === 1 && rule.prestations[0].regimes.length === 1) ? false : true);
  }

  saveRules = (plcId: number): void => {
    this.cdtRegroupementSvc.saveRules(this.rulesByPlcs[plcId])
      .subscribe(() => {
        this.disableSaveByPlcs[plcId] = true;
      });
  }

  deleteValues = async (idPlc: number): Promise<void> => {
    const result = confirm(`Êtes-vous sûr de vouloir supprimer le(s) règle(s) sélectionnée(s) ?`, 'Suppression de règle');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.cdtRegroupementSvc.deleteRules(this.selectedRows[idPlc], idPlc)
        .subscribe(r => {
          const result = r.one;
          if (result.deletable) {
            this.rulesByPlcs[idPlc] = !this.rulesByPlcs[idPlc].filter(item => this.selectedRows[idPlc].includes(item.id));
            this.parts.forEach(grid => grid.instance.refresh());
          }
        });
    }
  };

  getToolTipPrestation = ($event: any) => {
    const currentPrestation = $event.data.prestations.find(p => p.libelle === $event.column.name.libelle); // il ne peut en avoir qu'un
    const currentRegimeLabel = $event.column.caption;

    const currentRegime = currentPrestation.regimes.find(r => `Régime ${r.libelle}` === currentRegimeLabel);
    return currentRegime.actif ?
      'Cliquez ici pour désactiver le regroupement' : 'Cliquez ici pour activer le regroupement';
  }

  onCellClick = ($event: any) => {
    if ($event.columnIndex > 1) {
      this.disableSaveByPlcs[$event.data.plcId] = false;
      const currentPrestation = $event.data.prestations.find(p => p.libelle === $event.column.name.libelle); // il ne peut en avoir qu'un
      const currentRegimeLabel = $event.column.caption;
      const currentRegime = currentPrestation.regimes.find(r => `Régime ${r.libelle}` === currentRegimeLabel);
      currentRegime.actif = !currentRegime.actif;
      this.cellActiveBackground($event);
    }
  };

  onRowPrepared = ($event: any) => {
    if ($event.rowType === DATAGRID_ROW_TYPES.DATA) {
      $event.rowElement.style.lineHeight = '40px';
    }
  };

  onCellPrepared = ($event: any) => {
    this.cellActiveBackground($event);
  };

  cellActiveBackground = ($event: any) => {
    if ($event.columnIndex > 1 && $event.rowType === DATAGRID_ROW_TYPES.DATA) {
      const currentPrestation = $event.data.prestations.find(p => p.libelle === $event.column.name.libelle); // il ne peut en avoir qu'un
      const currentRegimeLabel = $event.column.caption;
      const currentRegime = currentPrestation.regimes.find(r => `Régime ${r.libelle}` === currentRegimeLabel);
      if (currentRegime) {
        $event.cellElement.style.cursor = 'pointer';
        $event.cellElement.style.backgroundColor = UI_COLORS.CHECKED;
        if (currentRegime.actif) {
          $event.cellElement.style.backgroundColor = UI_COLORS.CHECKED;
        } else {
          $event.cellElement.style.backgroundColor = UI_COLORS.NOT_CHECKED;
        }
      }
    }
  };

  previous = (): void => {
    this.onEmitPreviousStep.emit({currentStep: this.currentStep});
  }

  next = (): void => {
    let disableNextButton: boolean = false;
    Object.keys(this.disableSaveByPlcs).forEach((idPlc) => {
      if (!this.disableSaveByPlcs[idPlc]) {
        disableNextButton = true;
        return;
      }
    });
    if (disableNextButton) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Veuillez enregistrer la ou les règle(s) ajoutée(s)/modifiée(s) des points de livraison concernés`);
    } else {
      this.onEmitNextStep.emit({
        currentStep: this.currentStep,
        rulesByPlcs: this.rulesByPlcs
      });
    }
  }

  currentStepPourcentage = (): number => this.wizardGlobalSvc.calculateCurrentStepPourcentage(this.wizardSvc.totalSteps, this.currentStep);

}
