import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GestionproduitsRoutingModule} from './gestionproduits-routing.module';
import {GestionproduitsComponent} from './gestionproduits.component';
import {SharedModule} from '../shared/shared.module';
import {GrilleproduitsComponent} from './grilleproduits/grilleproduits.component';
import {ProduitComponent} from './produit/produit.component';

import {DragDropConfig, DragDropService, DragDropSortableService} from 'ng2-dnd';

import {ArticleProduitComponent} from './produit/article-produit/article-produit.component';
import {AllergeneProduitComponent} from './produit/allergene-produit/allergene-produit.component';

import {FichetechniqueProduitComponent} from './produit/fichetechnique-produit/fichetechnique-produit.component';
import {ProduitsrecetteComponent} from './produit/fichetechnique-produit/produitsrecette/produitsrecette.component';
import {GemrcnProduitComponent} from './produit/gemrcn-produit/gemrcn-produit.component';
import {GemrcnResolverService} from './produit/gemrcn-produit/gemrcn-resolver.service';
import {ModeOperatoireProduitComponent} from './produit/mode-operatoire-produit/mode-operatoire-produit.component';
import {FicheidentiteProduit2Component} from './produit/ficheidentite-produit2/ficheidentite-produit2.component';
import {CiqualComponent} from './produit/ciqual/ciqual.component';
import {DeclinaisonProduitDialogEditComponent} from './produit/declinaisons-produit/declinaison-produit-dialog-edit/declinaison-produit-dialog-edit.component';
import {DeclinaisonsProduitComponent} from './produit/declinaisons-produit/declinaisons-produit.component';
import {CiqualDialogEditComponent} from './produit/ciqual/ciqual-dialog-edit/ciqual-dialog-edit.component';
import {AllergeneResolverService} from './produit/allergene-produit/allergene-resolver.service';
import {AppellationsComponent} from './produit/appellations/appellations.component';
import {FtSearchPanelComponent} from './produit/fichetechnique-produit/search-panel/ft-search-panel.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DialogComponent} from './produit/article-produit/dialog/dialog.component';
import {MarchesProduitComponent} from './produit/marches-produit/marches-produit.component';
import {
  DxBarGaugeModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxCircularGaugeModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownBoxModule,
  DxHtmlEditorModule,
  DxMultiViewModule,
  DxNumberBoxModule,
  DxPieChartModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTabPanelModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTreeViewModule
} from 'devextreme-angular';
import {MarcheProduitComponent} from './produit/marches-produit/marche-produit/marche-produit.component';
import {NgxGaugeModule} from 'ngx-gauge';
import {TableModule} from 'primeng/table';

import {DropdownModule} from 'primeng/dropdown';
import {OverlayPanelModule} from 'primeng/overlaypanel';

import {MenuModule} from 'primeng/menu';
import {CardModule} from 'primeng/card';
import {TabMenuModule} from 'primeng/tabmenu';
import {CheckboxModule} from 'primeng/checkbox';
import {SelectButtonModule} from 'primeng/selectbutton';
import {DialogModule} from 'primeng/dialog';
import {TabViewModule} from 'primeng/tabview';
import {RadioButtonModule} from 'primeng/radiobutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {SliderModule} from 'primeng/slider';
import {ListboxModule} from 'primeng/listbox';
import {AccordionModule} from 'primeng/accordion';
import {DialogListPlatComponent} from "./produit/declinaisons-produit/dialog-list-plat/dialog-list-plat.component";
import {DialogReplaceDenreeComponent} from "./produit/declinaisons-produit/dialog-list-plat/dialog-replace-denree/dialog-replace-denree.component";
import {InputTextModule} from "primeng/inputtext";
import {ImplementationModeleConditionnementPlatComponent} from "./produit/conditionnement/modele-conditionnement-plat/implementation-modele-conditionnement-plat.component";
import {DialogEditImplementationModeleConditionnementProduitComponent} from "./produit/conditionnement/modele-conditionnement-plat/dialog-edit-conditionnement-produit/dialog-edit-implementation-modele-conditionnement-produit.component";
import {VisualisationMcpParametrageComponent} from "./produit/conditionnement/modele-conditionnement-plat/visualisation/visualisation-mcp-parametrage.component";
import {DialogChangeUniteTechniqueComponent} from "./produit/declinaisons-produit/declinaison-produit-dialog-edit/dialog-change-unite-technique/dialog-change-unite-technique.component";
import {DialogBatchProduitsComponent} from "./dialog-batch/dialog-batch-produits.component";
import {DialogCheckRatiosComponent} from "./dialog-check-ratios/dialog-check-ratios.component";
import {PeriodPriceComponent} from "./produit/article-produit/dialog/period-price/period-price.component";
import {DialogSavePeriodPriceComponent} from "./produit/article-produit/dialog/dialog-save-period-price/dialog-save-period-price.component";
import {PanelModule} from "primeng/panel";
import {CommunicationComponent} from "./produit/communication/communication.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GestionproduitsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    DropdownModule,
    OverlayPanelModule,
    MenuModule,
    CardModule,
    TabMenuModule,
    CheckboxModule,
    SelectButtonModule,
    DialogModule,
    TabViewModule,
    RadioButtonModule,
    MultiSelectModule,
    SliderModule,
    ListboxModule,
    AccordionModule,
    DxDataGridModule,
    DxPieChartModule,
    DxCircularGaugeModule,
    DxBarGaugeModule,
    DxToolbarModule,
    NgxGaugeModule,
    InputTextModule,
    DxNumberBoxModule,
    DxTextAreaModule,
    DxTagBoxModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxHtmlEditorModule,
    DxPopupModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxTabPanelModule,
    DxScrollViewModule,
    DxMultiViewModule,
    DxTabsModule,
    DxCheckBoxModule,
    DxDateBoxModule,
    PanelModule,
    DxButtonModule,
  ],
  providers: [
    GemrcnResolverService,
    AllergeneResolverService,
    DragDropService,
    DragDropSortableService,
    DragDropConfig
  ],
  entryComponents:[

  ],
  declarations: [
    GestionproduitsComponent,
    GrilleproduitsComponent,
    ProduitComponent,
    ArticleProduitComponent,
    AllergeneProduitComponent,
    FichetechniqueProduitComponent,
    ProduitsrecetteComponent,
    GemrcnProduitComponent,
    ModeOperatoireProduitComponent,
    FicheidentiteProduit2Component,
    DeclinaisonsProduitComponent,
    DeclinaisonProduitDialogEditComponent,
    CiqualComponent,
    CiqualDialogEditComponent,
    AppellationsComponent,
    FtSearchPanelComponent,
    DialogComponent,
    MarchesProduitComponent,
    MarcheProduitComponent,
    DialogListPlatComponent,
    DialogReplaceDenreeComponent,
    ImplementationModeleConditionnementPlatComponent,
    DialogEditImplementationModeleConditionnementProduitComponent,
    VisualisationMcpParametrageComponent,
    DialogChangeUniteTechniqueComponent,
    DialogBatchProduitsComponent,
    DialogCheckRatiosComponent,
    PeriodPriceComponent,
    DialogSavePeriodPriceComponent,
    CommunicationComponent
  ],
  exports: [FichetechniqueProduitComponent, DialogComponent]

})
export class GestionproduitsModule {
}
