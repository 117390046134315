import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {Subscription} from "rxjs";
import {FichetechniqueService} from "../../../core/services/gestionproduits/fichetechnique.service";
import {WizardService} from "../../../core/services/wizard-service";
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {GenericDatagridService} from "../../../core/services/generics/generic-datagrid.service";
import {ImplementationModelesConditionnementsPlatsService} from "../../../core/services/conditionnements/plat/implementation-modeles-conditionnements-plats.service";
import {UniteDeMesureDTO} from "../../../core/dtos/unitedemesure-dto";
import {ModeleImplementationMcpDto} from "../../../core/dtos/conditionnement/plat/modele-implementation-mcp-dto";
import {ModelePoidsUcDto} from "../../../core/dtos/conditionnement/plat/modele-poids-uc-dto";
import {InstructionDeclinaisonModel} from "../../../core/models/gestion-conditionnements/instruction-declinaison";
import {ProduitDTO} from "../../../core/dtos/produit-dto";

@Component({
  selector: 'yo-wizard-configuration-step-7',
  templateUrl: './wizard-configuration-step-7.component.html',
  styleUrls: ['./wizard-configuration-step-7.component.scss']
})
export class WizardConfigurationStepSevenComponent implements OnInit, OnDestroy {

  @Output() onEmitPreviousStep = new EventEmitter<any>();

  @Output() onEmitNextStep = new EventEmitter<any>();

  private currentStep: number;

  private dataPreviousStep: any;

  produits: any[] = [];

  declinaisons: any[] = [];

  private subProducts: Subscription;
  private timer: any;

  idUniteMesureSelected: number;

  uniteMesureAbrevModelePlat: string;

  uniteMesureLibelleModelePlat: string;

  modeSelected: string;
  poidsNetAConditionnerEnKg: any;
  displayDialogHandleInstructions: boolean = false;
  currentProduit: ProduitDTO;

  instructionsByDeclinaison: any;

  private implementationsModelesPlats: ModeleImplementationMcpDto[];

  constructor(private readonly graphQlSvc: GraphQLService,
              private readonly gdsSvc: GenericDatagridService,
              private readonly wizardSvc: WizardConditionnementService,
              private readonly ftSvc: FichetechniqueService,
              private readonly utilsSvc: UtilsService,
              private readonly wizardGlobalSvc: WizardService,
              private implMcpSvc: ImplementationModelesConditionnementsPlatsService) {
    this.dataPreviousStep = this.wizardSvc.getDataStepSix();
    this.currentStep = this.wizardSvc.STEP_CHECK_RECIPES;
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subProducts);
  }

  ngOnInit(): void {
    this.initProducts();
  }

  addRowInstruction = (): void => {
    this.instructionsByDeclinaison[this.currentProduit?.id].push({
      libelle: '',
      valuesByDeclinaison: this.initValuesByDeclinaisonRow(),
      declinaisons: this.declinaisons.map(d => d.id),
      values: this.declinaisons.map(d => 0)
    } as InstructionDeclinaisonModel);
  };

  initValuesByDeclinaisonRow = (): Map<number, number> => {
    const map = new Map<number, number>();
    this.declinaisons.forEach(declinaison => map.set(declinaison.id, 0));
    return map;
  };

  deleteRowInstruction = ($event, rowIndex): void => {
    this.instructionsByDeclinaison[this.currentProduit.id].splice(rowIndex, 1);
  };

  changeLibelleInstruction = ($event, rowIndex): void => {
    this.instructionsByDeclinaison[this.currentProduit.id][rowIndex].libelle = $event.value;
  };

  changeValueInstruction = ($event, rowIndex, columnIdx): void => {
    const value = $event.value;
    this.instructionsByDeclinaison[this.currentProduit.id][rowIndex].values[columnIdx] = value;
    this.instructionsByDeclinaison[this.currentProduit.id][rowIndex].valuesByDeclinaison.set(this.instructionsByDeclinaison[this.currentProduit.id][rowIndex].declinaisons[columnIdx], value);
  };

  saveInstructions = (): void => {
    this.implementationsModelesPlats.forEach(impl => {
      Object.keys(this.instructionsByDeclinaison).forEach(productId => {
        if (this.instructionsByDeclinaison[productId])
          impl.instructions = this.instructionsByDeclinaison[productId];
          impl.update = true;
      });
    });
    this.implMcpSvc.saveMultipleModels(this.implementationsModelesPlats)
      .subscribe(() => this.closeDialogGererInstructions());
  }

  initProducts = (): void => {
    this.modeSelected = this.wizardSvc.getDataStepTwo()?.modeModelePlatSelected;

    this.gdsSvc.getAll('unitedemesure').subscribe((response: ResponseWrapper<any>) => {
      const unitesAConditionner: UniteDeMesureDTO[] = response.resultList ? response.resultList : [];
      const udm: UniteDeMesureDTO = unitesAConditionner.find(udm => udm.id === this.wizardSvc.getDataStepTwo()?.idUniteMesureSelected);
      this.uniteMesureAbrevModelePlat = udm?.abreviation;
      this.uniteMesureLibelleModelePlat = udm?.libelle?.toLowerCase();
    });

    this.subProducts = this.graphQlSvc.sendQuery(`{
      allProduits(filters: { ids: [${this.dataPreviousStep?.idsProducts}] }) {
          id,
          libelle,
          code,
          produitsDeclinaisons {
            id,
            totalPoidsNetRecetteUT
            declinaison {
              id,
              libelle
            }
          }
        }
      }`).subscribe((response: any) => {

      const data: any = this.wizardSvc.getDataStepFive();
      this.produits = response.allProduits;
      this.declinaisons = this.produits.filter(p => p.produitsDeclinaisons.filter(pd => data.declinaisonsSelected.includes(pd.declinaison.id) ))
                                       .map(p => p.produitsDeclinaisons.map(pd => pd.declinaison) )
                                       .reduce((item, res) => { res.push(...item); return res; }, []);
      this.declinaisons = this.declinaisons.filter((declinaison, idx) => this.declinaisons.findIndex((d) => d.id === declinaison.id) === idx);
      this.declinaisons = this.declinaisons.filter(d => data.declinaisonsSelected.find(dd => dd === d.id));

      this.implMcpSvc.findAll(this.produits?.length ? this.produits.map(p => p.id) : [])
        .subscribe(result => {
          this.poidsNetAConditionnerEnKg = {};
          this.instructionsByDeclinaison = {};
          this.implementationsModelesPlats = result.resultList;
          this.implementationsModelesPlats.forEach(implementation => {
            if (!this.poidsNetAConditionnerEnKg[implementation.produitId])
              this.poidsNetAConditionnerEnKg[implementation.produitId] = {};
            if (!this.instructionsByDeclinaison[implementation.produitId])
              this.instructionsByDeclinaison[implementation.produitId] = {};

            implementation.poidsUcByDeclinaisons.forEach((item: ModelePoidsUcDto) => {
              this.poidsNetAConditionnerEnKg[implementation.produitId][item.idDeclinaison] = item.poidsUC;
            });

            if (implementation.instructions) {
              this.instructionsByDeclinaison[implementation.produitId] = implementation.instructions?.map((row: InstructionDeclinaisonModel) => {
                return {
                  libelle: row.libelle,
                  declinaisons: row.declinaisons,
                  values: row.values
                } as InstructionDeclinaisonModel;
              });
            } else {
              this.instructionsByDeclinaison[implementation.produitId] = [];
            }
          });

        });
    });
  }

  findTotalNetUT = (idProduit: number, idDeclinaison: number): number => {
    const produit: any = this.produits
      .find(produit => produit.id === idProduit);
    const produitDecline = produit.produitsDeclinaisons.map(pd => pd)
      .find(pd => pd.declinaison.id === idDeclinaison)
    return produitDecline ? produitDecline.totalPoidsNetRecetteUT : null;
  }

  readPoidsNet = (idProduit: number, idDeclinaison: number): number => this.poidsNetAConditionnerEnKg ? this.poidsNetAConditionnerEnKg[idProduit][idDeclinaison] : 1;

  containsWrongValues = (idProduit: number): boolean => {
    const data: any = this.wizardSvc.getDataStepFive();
    const produit: any = this.produits
      .find(produit => produit.id === idProduit);
    return produit.produitsDeclinaisons.filter(pd => data.declinaisonsSelected.find(dd => dd === pd.declinaison.id) && (!pd.totalPoidsNetRecetteUT || pd.totalPoidsNetRecetteUT === 1)).length > 0;
  }

  containsValuesWithZero = (): boolean => {
    let total = 0;
    const data: any = this.wizardSvc.getDataStepFive();
    this.produits.forEach(produit => total += produit.produitsDeclinaisons.filter(pd => data.declinaisonsSelected.find(dd => dd === pd.declinaison.id) && !pd.totalPoidsNetRecetteUT).length);
    return total > 0;
  }

  updateTotalNetUT = ($event, idProduit: number, idDeclinaison: number): void => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.produits.forEach(produit => {
        if (produit.id === idProduit) {
          produit.produitsDeclinaisons.forEach(pd => {
            if (pd.declinaison.id === idDeclinaison) {
              pd.totalPoidsNetRecetteUT = parseFloat($event.target.value);
              this.ftSvc.updatePoidsNetFT(pd.id, $event.target.value)
                .subscribe(() => {});
            }
          });
        }
      });
    }, 1000);
  }

  updatePoidsNetUniteAConditionner = ($event, idProduit: number, idDeclinaison: number): void => {
    const idModelePlat: number = this.wizardSvc.getDataStepTwo()?.id;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.implMcpSvc.updatePoidsNetUniteConditionner($event.target.value, idProduit, idDeclinaison, idModelePlat).subscribe(() => {});
    }, 200);
  }

  gererInstruction = (currentIdProduit: number, currentIdDeclinaison: number): void => {
    this.currentProduit = this.produits.find(p => p.id === currentIdProduit);
    this.displayDialogHandleInstructions = true;
  }

  closeDialogGererInstructions = (): void => {
    this.currentProduit = null;
    this.displayDialogHandleInstructions = false;
  }

  previous = (): void => {
    this.onEmitPreviousStep.emit({ currentStep: this.currentStep });
  }

  next = (): void => {
    this.onEmitNextStep.emit({ currentStep: this.currentStep, containsValuesWithZero: this.containsValuesWithZero() });
  }

  currentStepPourcentage = (): number => this.wizardGlobalSvc.calculateCurrentStepPourcentage(this.wizardSvc.totalSteps, this.currentStep);

}
