import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {UtilsService} from "../../core/utils/utils.service";
import {ActivatedRoute, Event, NavigationStart, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {PortailPlcSupplier} from "./portail-plc-resolver.service";
import {PointDeLivraisonDTO} from "../../core/dtos/point-de-livraison-d-t-o";
import {PointDeLivraisonService} from "../../core/services/entities/point-de-livraison.service";
import {ConfigurationPortailService} from "../../core/services/portail/configuration-portail.service";
import {ConfigurationPortailDTO} from "../../core/dtos/portail/configuration-portail-dto";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {StartPointCommandesClientsEnum} from "../../core/enums/start-point-commandes-clients-enum";
import DatesDetailsFromMenuDTO from "../../core/dtos/planification/dates-details-from-menu-dto";
import ViewEffectifsByPlcRepasPrestationDTO
  from "../../core/dtos/planification/views/view-effectifs-by-plc-repas-prestation-dto";
import {CommandesClientService} from "../../core/services/entities/commandes-client.service";
import {TypeEffectifEnum} from "../../core/enums/type-effectif-enum";
import Week from "../../core/dtos/week-dto";
import RowEffectifsCommandesClientDTO from "../../core/dtos/planification/row-effectifs-commandes-client-dto";
import DetailEffectifCommandeClientDTO from "../../core/dtos/planification/detail-effectif-commande-client-dto";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'yo-portail-plc',
  templateUrl: './portail-plc.component.html',
  styleUrls: ['./portail-plc.component.scss']
})
export class PortailPlcComponent implements OnInit, OnDestroy {

  subRouteData: Subscription;
  subMonthChange: Subscription;
  subSaveEffectivesCommandesClientsFromRow: Subscription;
  subSaveEffectivesCommandesClientsFromDetail: Subscription;
  subRefreshViewCommandesEffectif: Subscription;
  subRowUpdated: Subscription;
  subDetailUpdated: Subscription;

  selectedDate: Date = new Date();
  week: Week;
  localeFr: any;

  disabledDays: number[];
  dateMin: Date;
  datesCreationMenus: Date[];
  datesSaisieEffectifs: Date[];
  datesDetailsFromMenu: DatesDetailsFromMenuDTO;

  plcList: PointDeLivraisonDTO[] = [];
  selectedPlc: PointDeLivraisonDTO;

  configPortailPlc: ConfigurationPortailDTO;

  typeEffectif: TypeEffectifEnum = TypeEffectifEnum.PREVISIONNEL;

  searchModeOption = 'contains';
  searchExprOption: any = 'libelle';
  viewCommandesEffectifs: ViewEffectifsByPlcRepasPrestationDTO;
  duplicateViewCommandesEffectifs: ViewEffectifsByPlcRepasPrestationDTO;
  cols: Date[] = [];

  rowsUpdated: RowEffectifsCommandesClientDTO[] = [];
  detailsUpdated: DetailEffectifCommandeClientDTO[] = [];

  loadingVisible: boolean = false;

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private plcSvc: PointDeLivraisonService,
              private configPortailSvc: ConfigurationPortailService,
              private commandesClientSvc: CommandesClientService,
              private datePipe: DatePipe,
              private cd: ChangeDetectorRef,
              private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) this.saveEffectivesCommandesClients();
    });

    document.addEventListener("visibilitychange", (event) => {
      if (document.visibilityState != "visible") {
        this.saveEffectivesCommandesClients();
      }
    });

    window.addEventListener("beforeunload", (event) => {
      this.saveEffectivesCommandesClients();
      event.returnValue = '';
    });

    this.inactivityTime();
  }

  ngOnInit() {
    this.routeDataSubscription();
    this.menuMonthChangeSubscription();
    this.saveEffectifsCommandesClientsSubscription();
    this.refreshViewCommandeClientSubscription();
    this.rowUpdatedSubscription();
    this.detailUpdatedSubscription();
    this.updateDatesBySelection(null);
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subRouteData);
    this.utils.unsubscribe(this.subMonthChange);
    this.utils.unsubscribe(this.subSaveEffectivesCommandesClientsFromRow);
    this.utils.unsubscribe(this.subSaveEffectivesCommandesClientsFromDetail);
    this.utils.unsubscribe(this.subRefreshViewCommandesEffectif);
    this.utils.unsubscribe(this.subRowUpdated);
    this.utils.unsubscribe(this.subDetailUpdated);
  }

  routeDataSubscription = (): void => {
    this.subRouteData = this.route.data.subscribe((data: { portailPlcSupplier: PortailPlcSupplier }) => {
      this.plcList = data?.portailPlcSupplier?.plcList;
      if (this.plcList && this.plcList.length === 1) {
        this.selectedPlc = this.plcList[0];
        this.loadConfigPortailByPlc();
      }
    });
  };

  /**
   * Méthode utilisée lorsque l'utilisateur sélectionne un point de livraison
   */
  updatePlc = ($event: any): void => {
    this.saveEffectivesCommandesClients();

    if ($event.value) {
      this.commandesClientSvc.announceSelectedTypeEffectif(this.typeEffectif);
      this.commandesClientSvc.announceDetailsEffectifCommandeClient(null);
      this.commandesClientSvc.announceLoadingView(true);
      this.commandesClientSvc.announceMenuMonthChange(null, false);
    }
  };

  /**
   * Subscription qui permet de rafraîchir la vue du planning des effectifs
   */
  refreshViewCommandeClientSubscription = () => {
    this.subRefreshViewCommandesEffectif = this.commandesClientSvc.refreshViewCommandesEffectif$
      .subscribe(() => {
        this.viewCommandesEffectifs = null;
        if (this.selectedPlc) this.getViewEffectifCommandeClient();
      });
  }

  /**
   * Méthode qui renvoie la vue demandée par l'utilisateur par rapport au point de livraison sélectionné
   */
  getViewEffectifCommandeClient = (): void => {
    this.loadingVisible = true;
    this.loadConfigPortailByPlc();

    this.commandesClientSvc.loadViewCommandesEffectif(this.week.weekMonday, this.week.weekSunday, this.selectedPlc?.id,
      StartPointCommandesClientsEnum.PLC, StartPointCommandesClientsEnum.REPAS)
      .subscribe(response => {
        if (!this.utils.isNullOrEmpty(response) && !this.utils.isNullOrEmpty(response.one) && this.utils.isNullOrEmpty(response.one.empty)) {
          this.viewCommandesEffectifs = response.one;
          this.duplicateViewCommandesEffectifs = JSON.parse(JSON.stringify(response.one));
          this.commandesClientSvc.announceViewCommandesEffectif(this.viewCommandesEffectifs);
          this.commandesClientSvc.announceChangeEffectifsCommandesClients();
        } else {
          this.viewCommandesEffectifs = null;
          this.commandesClientSvc.announceViewCommandesEffectif(null);
        }

        this.loadingVisible = false;
      });
  };

  /**
   * Méthode qui permet de récupérer les configurations pour le portail
   */
  loadConfigPortailByPlc = (): void => {
    this.configPortailSvc.getById(this.selectedPlc.id).subscribe(
      (response: ResponseWrapper<ConfigurationPortailDTO>) => {
        if (response.one) this.configPortailPlc = response.one;
        else {
          this.configPortailPlc = new ConfigurationPortailDTO();
          this.configPortailPlc.maxValeurSaisie = 10000;
          this.configPortailPlc.minValeurSaisie = 0;
          this.configPortailPlc.dateLimiteSaisie = new Date();
        }
      });
  };

  /**
   * Méthode qui initialise les colonnes selon la date sélectionnée par l'utilisateur
   */
  initCols = () => {
    this.cols = [];
    this.datesDetailsFromMenu.joursDisponibles.sort((a, b) => a - b)
    let day: Date = new Date(this.week.weekMonday);
    this.cols.push(day);
    this.datesDetailsFromMenu.joursDisponibles.forEach(jour => {
      let newDay: Date = this.utils.addDaysToDate(day, jour);
      if (newDay.getTime() <= this.week.weekSunday.getTime() && this.datesDetailsFromMenu.joursDisponibles.find(j => newDay.getDay() === j)) this.cols.push(newDay);
    });
    if (this.datesDetailsFromMenu.joursDisponibles.find(j => j === 7)) this.cols.push(new Date(this.week.weekSunday));
    this.cols.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  }

  /**
   * Subscription qui permet de rafraîchir les données du calendrier selon la date sélectionnée
   */
  menuMonthChangeSubscription = () => {
    this.subMonthChange = this.commandesClientSvc.menuMonthChange$.subscribe(item => {
      this.commandesClientSvc.getMonthDates(item[0], this.selectedDate, this.selectedPlc?.id, StartPointCommandesClientsEnum.PLC)
        .subscribe(response => {
          console.log(this.selectedPlc)

          if (response.one) this.initMenu(response.one, item[0], item[1]);
        })
    });
  };

  /**
   * Méthode utilisée lorsque l'utilisateur sélectionne une date
   */
  updateDatesBySelection = ($event: any) => {
    this.saveEffectivesCommandesClients();

    if ($event) this.selectedDate = $event;

    this.commandesClientSvc.announceViewCommandesEffectif(null);
    this.commandesClientSvc.announceDetailsEffectifCommandeClient(null);
    this.commandesClientSvc.announceLoadingView(true);

    this.week = {
      weekInYear: this.utils.getWeek(this.selectedDate),
      weekMonday: this.utils.getFirstDayOfWeek(this.selectedDate),
      weekSunday: this.utils.getLastDayOfWeek(this.selectedDate),
      weekYear: this.selectedDate.getFullYear()
    };

    this.cd.detectChanges();

    this.commandesClientSvc.announceMenuMonthChange(null, false);
  }

  /**
   * Méthode utilisée lorsque l'utilisateur ne sélectionne pas de date
   */
  updtateDatesByClickOutside = () => this.commandesClientSvc.announceMenuMonthChange(null, true);

  /**
   * Méthode utilisée lorsque l'utilisateur change de mois sur le calendrier proposé pour
   * sélectionner une date
   */
  updateDatesByMonth = ($event: any) => this.commandesClientSvc.announceMenuMonthChange($event, false);

  /**
   * Méthode qui initialise les données du menu
   */
  initMenu = (response: DatesDetailsFromMenuDTO, event: any, clickOutside: boolean) => {
    this.datesDetailsFromMenu = response;
    this.disabledDays = this.utils.getDisabledDays(response.joursDisponibles);

    this.datesSaisieEffectifs = response.datesSaisieEffectifs;
    this.datesCreationMenus = response.datesCreationMenus;
    this.dateMin = response.dateMin;

    this.dateMin = this.utils.convertNumberDateToDate(this.dateMin);

    if (this.datesSaisieEffectifs?.length) {
      this.datesSaisieEffectifs = this.datesSaisieEffectifs.map(item => this.utils.convertNumberDateToDate(item));
    }

    if (this.datesCreationMenus?.length)
      this.datesCreationMenus = this.datesCreationMenus.map(item => this.utils.convertNumberDateToDate(item));

    if (!event) {
      this.commandesClientSvc.announceDatesDetailsFromMenu(response);

      if (!clickOutside) {
        this.initCols();
        this.commandesClientSvc.announceRefreshViewCommandesEffectif();
      }
    }
  };

  /**
   * Méthode qui permet de formatter la date sur le calendrier proposé à l'utilisateur
   */
  getWeekLabel = (week: Week): string => this.utils.getWeek(week.weekMonday) + ' ' + this.datePipe.transform(week.weekMonday, 'dd/MM/yyyy')
    + ' au ' + this.datePipe.transform(week.weekSunday, 'dd/MM/yyyy');

  /**
   * Subscription qui reçoit les informations nécessaires pour modifier un effectif ou un taux de prise, par
   * rapport à une ligne ou à un détail
   */
  saveEffectifsCommandesClientsSubscription = () => {
    this.subSaveEffectivesCommandesClientsFromRow =
      this.commandesClientSvc.saveEffectifsCommandesClientsFromRow$.subscribe((cells: RowEffectifsCommandesClientDTO[]) => {
        if (this.selectedPlc) {
          this.commandesClientSvc.saveEffectifsCommandesClients(this.selectedPlc.id, StartPointCommandesClientsEnum.PLC,
            StartPointCommandesClientsEnum.REPAS, this.duplicateViewCommandesEffectifs, cells, null, null, null)
            .subscribe(response => {
              if (!this.utils.isNullOrEmpty(response) && !this.utils.isNullOrEmpty(response.one)) {
                this.commandesClientSvc.announceRefreshViewCommandesEffectif();
                this.commandesClientSvc.announceChangeEffectifsCommandesClients();
              } else {
                this.viewCommandesEffectifs = null;
                this.commandesClientSvc.announceViewCommandesEffectif(null);
              }
            })
        }
      });

    this.subSaveEffectivesCommandesClientsFromDetail =
      this.commandesClientSvc.saveEffectifsCommandesClientsFromDetail$.subscribe((item: [boolean, boolean, DetailEffectifCommandeClientDTO[]]) => {
        if (this.selectedPlc) {
          this.commandesClientSvc.saveEffectifsCommandesClients(this.selectedPlc?.id, StartPointCommandesClientsEnum.PLC,
            StartPointCommandesClientsEnum.REPAS, this.duplicateViewCommandesEffectifs, null, item[2], item[0], item[1])
            .subscribe(response => {
              if (!this.utils.isNullOrEmpty(response) && !this.utils.isNullOrEmpty(response.one)) {
                this.commandesClientSvc.announceRefreshViewCommandesEffectif();
                this.commandesClientSvc.announceChangeEffectifsCommandesClients();
              } else {
                this.viewCommandesEffectifs = null;
                this.commandesClientSvc.announceViewCommandesEffectif(null);
              }
            })
        }
      })
  }

  /**
   * Méthode qui permet de récupérer les cellules 'row' modifiées et de les stocker avant de les sauvegarder
   */
  rowUpdatedSubscription = () => {
    this.subRowUpdated = this.commandesClientSvc.rowUpdated$.subscribe((cell: RowEffectifsCommandesClientDTO) => {
      cell = this.updateDetails(cell);

      let idxItemFound: number = this.rowsUpdated.findIndex(c => c.cmcrPlcDate?.id === cell.cmcrPlcDate?.id);
      if (idxItemFound > -1)
        this.rowsUpdated[idxItemFound] = cell;
      else
        this.rowsUpdated.push(Object.assign({}, cell));
    })
  }

  /**
   * Méthode qui permet de récupérer les cellules 'detail' modifiées et de les stocker avant de les sauvegarder
   */
  detailUpdatedSubscription = () => {
    this.subDetailUpdated = this.commandesClientSvc.detailUpdated$.subscribe((cell: DetailEffectifCommandeClientDTO) => {
      let idxItemFound: number = this.detailsUpdated.findIndex(c => c.mcPlc?.id === cell.mcPlc?.id);
      if (idxItemFound > -1)
        this.detailsUpdated[idxItemFound] = cell;
      else
        this.detailsUpdated.push(Object.assign({}, cell));
    })
  }

  /**
   * Méthode qui modifier les détails d'une cellule suite à la modification de l'effectif de celle-ci
   */
  updateDetails = (cell: RowEffectifsCommandesClientDTO) : RowEffectifsCommandesClientDTO => {
    cell.details.forEach(detail => {
      detail.effectifPrevu = Math.round((detail.tauxPrise * cell.effectifPrevisionnel) / 100);
      detail.arrondiEffectifPrevu = false;
    });

    return cell;
  }

  /**
   * Méthode qui permet de lancer la sauvegarde des effectifs
   */
  saveEffectivesCommandesClients = () => {
    if (this.rowsUpdated.length) {
      this.commandesClientSvc.announceSaveEffectifsCommandesClientsFromRow(this.rowsUpdated);
      this.rowsUpdated = [];
    } else {
      if (this.detailsUpdated.length) {
        this.commandesClientSvc.announceSaveEffectifsCommandesClientsFromDetail(null, null, this.detailsUpdated);
        this.detailsUpdated = [];
      }
    }
  }

  inactivityTime = () => {
    let cur = this;
    let time;
    const timeout: number = 60000;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;

    function save() {
      cur.saveEffectivesCommandesClients();
    }

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(save, timeout);
    }
  }

}
