import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpService} from '../technique/http.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {TacheDTO} from "../../dtos/tache-dto";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";
import {Auth2Service} from "../security/auth2.service";

export const URL_GET_ALL = `dolrest/taches/list`;
export const URL_SAVE = `dolrest/taches/save`;
export const URL_DELETE = `dolrest/taches/delete`;

@Injectable({
  providedIn: 'root'
})
export class TachesService {

  private subjectOpenDialogTache = new Subject<TacheDTO>();
  openDialogTache$ = this.subjectOpenDialogTache.asObservable();

  private subjectTacheSaved = new Subject<TacheSavedSupplier>();
  tacheSaved$ = this.subjectTacheSaved.asObservable();

  constructor(private readonly httpSvc: HttpService,
              private readonly http: HttpClient,
              private readonly auth2Svc: Auth2Service) { }

  /**
   * Récupérer toutes les tâches
   * @returns {Observable<TacheDTO>}
   */
  getAll = (): Observable<ResponseWrapper<TacheDTO>> => this.httpSvc.get(URL_GET_ALL, null);

  /**
   * Sauvegarde une tâche
   * @param tâche
   */
  save = (t: TacheDTO): Observable<ResponseWrapper<TacheDTO>> => this.httpSvc.post(URL_SAVE, t);

  announceOpenDialogTache = (param: TacheDTO) => {
    this.subjectOpenDialogTache.next(param);
  };

  announceTacheSaved = (tache: TacheDTO, isUpdate: boolean = false) => {
    this.subjectTacheSaved.next({ isUpdate, tache } as TacheSavedSupplier);
  };

  /**
   * Supprime une ou plusieurs tâche(s) en fonction de
   * leurs identifiants.
   * @param tachesIdsToDelete
   */
  deleteByIds = (tachesIdsToDelete: number[]): Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", tachesIdsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  };

  export = (idsToExport?: number[]): Observable<any> => {
    let params: string = '?1=1';
    if (idsToExport?.length) params += `&ids=${idsToExport.join(',')}`
    return this.http.get(`dolrest/taches/export${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  }

  importFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post("dolrest/taches/import", formData, null);
  }

  canModify = (element: TacheDTO): boolean => {
    if (!element || !element?.site || !element?.site?.id) return true;
    return (element?.site?.id) ? this.auth2Svc.isSiteLocal(element.site.id) : false;
  }

}

export interface TacheSavedSupplier {
  isUpdate: boolean;
  tache: TacheDTO;
}
