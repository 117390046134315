import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import CustomStore from "devextreme/data/custom_store";
import {DevextremeService, FilterItem} from "../../../core/services/technique/devextreme.service";
import {DxDataGridComponent} from "devextreme-angular";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import {ProduitsService} from "../../../core/services/entities/produits.service";
import {DeclinaisonDTO} from "../../../core/dtos/declinaison-dto";
import {ModelesConditionnementsPlatsDeclinaisonsService} from "../../../core/services/conditionnements/modeles-conditionnements-plats-declinaison.service";
import {FamilleProduitDTO} from "../../../core/dtos/famille-produit-dto";
import {Observable, Subscription} from "rxjs";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {GenericDatagridService} from "../../../core/services/generics/generic-datagrid.service";
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import {TacheDTO} from "../../../core/dtos/tache-dto";
import {RegimeAlimentaireDTO} from "../../../core/dtos/regime-alimentaire-dto";
import {FamillesProduitService} from "../../../core/services/entities/familles-produit.service";
import {DeclinaisonsService} from "../../../core/services/entities/declinaisons.service";
import {TachesService} from "../../../core/services/taches/taches.service";
import {ProduitDTO} from "../../../core/dtos/produit-dto";
import {ImplementationModelesConditionnementsPlatsService} from "../../../core/services/conditionnements/plat/implementation-modeles-conditionnements-plats.service";
import {BindPlatsMcpModel} from "../../../core/models/gestion-conditionnements/bind-plats-mcp-model";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {ToastService} from "../../../core/services/technique/toast.service";
import {WizardService} from "../../../core/services/wizard-service";
import {Udp_ModeleConditionnementPlat__ProduitDTO} from "../../../core/dtos/unite-de-production_modele-conditionnement-plat__produit.dto";
import {UdpMcpProduitsService} from "../../../core/services/conditionnements/plat/udp-mcp-produits.service";

@Component({
  selector: 'yo-wizard-configuration-step-6',
  templateUrl: './wizard-configuration-step-6.component.html',
  styleUrls: ['./wizard-configuration-step-6.component.scss']
})
export class WizardConfigurationStepSixComponent implements OnInit, OnDestroy {

  @Output() onEmitPreviousStep = new EventEmitter<any>();

  @Output() onEmitNextStep = new EventEmitter<any>();

  private currentStep: number;

  dataSource: CustomStore;

  @ViewChild("gridPlatsToAdd") grid: DxDataGridComponent;

  @ViewChild("gridProduitsSelected") gridBinds: DxDataGridComponent;

  siteIdList: number[] = [];

  declinaisonIdList: number[] = [];

  declinaisonsConfigured: DeclinaisonDTO[] = [];

  allMode: string = 'allPages';
  checkBoxesMode: string = 'always';
  checkBoxesModeGridProduitsSelected: string = 'always';

  treeBoxValue: string[];
  treeDataSource: any;

  platLibelle: string;

  code: string;

  familleProduitIdList: number[] = [];

  regimeIdList: number[] = [];

  tachesIdList: number[] = [];

  sitesUtilisateurConnecte: SiteDTO[] = [];

  allFamillesProduits: FamilleProduitDTO[] = [];

  declinaisons: DeclinaisonDTO[] = [];
  allDeclinaisons: DeclinaisonDTO[] = [];
  allTaches: TacheDTO[] = [];
  allRegimes: RegimeAlimentaireDTO[] = [];

  allProducts: ProduitDTO[] = [];
  produitsSelected: ProduitDTO[] = [];
  allUdpMcpProduits: Udp_ModeleConditionnementPlat__ProduitDTO[] = [];

  selectedRows: number[] = [];

  test: any[] = [];

  subProducts: Subscription;
  subProducts2: Subscription;

  subUdpMcpProduitsSvc: Subscription;

  openDialogDisplayProductsAlreadyUsed: boolean = false;

  productsAlreadyUsed: ProduitDTO[] = [];

  selectAll: boolean = false;

  constructor(private readonly auth2Svc: Auth2Service,
              private readonly utilsSvc: UtilsService,
              private readonly wizardSvc: WizardConditionnementService,
              private readonly produitSvc: ProduitsService,
              private readonly dxSvc: DevextremeService,
              private readonly mcpdSvc: ModelesConditionnementsPlatsDeclinaisonsService,
              private readonly gds: GenericDatagridService,
              private readonly graphQlSvc: GraphQLService,
              private readonly familleProduitSvc: FamillesProduitService,
              private readonly declinaisonEntitySvc: DeclinaisonsService,
              private readonly tachesSvc: TachesService,
              private readonly implMcpSvc: ImplementationModelesConditionnementsPlatsService,
              private readonly toastSvc: ToastService,
              private readonly wizardGlobalSvc: WizardService,
              private readonly udpMcpProduitsSvc: UdpMcpProduitsService) {
    this.currentStep = this.wizardSvc.STEP_CHOICE_MEALS;
  }

  ngOnDestroy(): void {
    this.productsAlreadyUsed = [];
    this.utilsSvc.unsubscribe(this.subProducts);
    this.utilsSvc.unsubscribe(this.subProducts2);
    this.utilsSvc.unsubscribe(this.subUdpMcpProduitsSvc);
  }

  ngOnInit(): void {
    this.initFiltersAndDataSource();
  }

  previous = (): void => {
    this.onEmitPreviousStep.emit({currentStep: this.currentStep});
  }

  next = (): void => {
    this.onEmitNextStep.emit({
      currentStep: this.currentStep,
      idsProducts: this.produitsSelected.map(p => p.id)
    });
  }

  addRows = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(idsProducts => {
        if (idsProducts.length) {
          this.subProducts2 = this.graphQlSvc.sendQuery(`{
          allProduits(filters: { ids: [${idsProducts}] }) {
              id,
              libelle,
              site {
                id, libelle
              },
              produitsDeclinaisons {
                id,
                declinaison {
                  id,
                  libelle
                }
              }
            }
          }`).subscribe((response: any) => {
            const productsSelectedByUser = response.allProduits;
            productsSelectedByUser.forEach(p => {
              if (!this.produitsSelected.find(ps => ps.id === p.id))
                this.produitsSelected.push(JSON.parse(JSON.stringify(p)));
            });
            const dataStepTwo: any = this.wizardSvc.getDataStepTwo();
            this.implMcpSvc.saveMultiple({
              mcpId: dataStepTwo.id,
              platsIdList: idsProducts.filter(id => this.produitsSelected.filter(p => p.id).length)
            } as BindPlatsMcpModel)
              .subscribe(response => {
                const productsAlreadyUsed: ProduitDTO[] = response.resultList;
                if (productsAlreadyUsed?.length) {
                  this.openDialogDisplayProductsAlreadyUsed = true;
                  this.productsAlreadyUsed = productsAlreadyUsed;
                  this.produitsSelected = this.produitsSelected.filter(p => !this.productsAlreadyUsed.find(pa => p.id === pa.id));
                } else {
                  this.grid.instance.deselectAll();
                  this.grid.instance.refresh();
                  this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Plat(s) associé(s) avec succès`);
                  this.wizardSvc.saveStepSix({idsProducts: this.produitsSelected.map(p => p.id)});
                }
              });
          });
        }
      });
  }

  initFiltersAndDataSource = () => {
    const dataStepTwo: any = this.wizardSvc.getDataStepTwo();
    const idModelePlat: number = dataStepTwo.id;

    this.loadFilterFamillesProduitsSubscription();
    this.loadFilterDeclinaisonsSubscription();
    this.loadFilterRegimesSubscription();
    this.loadFilterSites();
    this.loadFilterTachesSubscription();

    this.mcpdSvc.getAllByIdMcp(idModelePlat)
      .subscribe((response) => {
        this.declinaisonsConfigured = response.resultList
          .map(mcpd => mcpd.declinaison);

         this.dataSource = this.initCustomStore();
      });
  }

  initCustomStore = (): CustomStore => new CustomStore({
    key: 'id',
    onLoaded: () => {
      const dataStepSix: any = this.wizardSvc.getDataStepSix();
      if (dataStepSix?.idsProducts?.length) {
        this.subProducts = this.graphQlSvc.sendQuery(`{
          allProduits(filters: { ids: [${dataStepSix.idsProducts}] }) {
              id,
              libelle,
              site {
                id, libelle
              },
              produitsDeclinaisons {
                id,
                declinaison {
                  id,
                  libelle
                }
              }
            }
          }`).subscribe((response: any) => {
            this.produitsSelected = response.allProduits;
        });
      }
      this.subUdpMcpProduitsSvc = this.udpMcpProduitsSvc.findAll().subscribe(response => this.allUdpMcpProduits = response.resultList);
    },
    load: async (loadOptions: any) => {

      let pageSize: number = loadOptions.take || this.grid.instance.pageSize();
      let page: number = this.grid.instance.pageIndex() + 1;
      const filters: FilterItem[] = this.dxSvc.dxToGrsFilters(loadOptions.filter);

      loadOptions.filter = [ this.selectedRows, "contains","id" ];
      if (loadOptions && loadOptions.select && loadOptions.select[0] === 'id' && this.selectAll) {
        // Si je coche tout => Il faut omettre la pagination
        page = null;
        pageSize = null;
      }

      const args = {
        code: this.code || '',
        label: this.platLibelle || '',
        declinaisonIds: this.declinaisonIdList.length ? this.declinaisonIdList : this.declinaisonsConfigured.map(d => d.id),
        familleProduitIds: this.familleProduitIdList,
        regimeIds: this.regimeIdList,
        sitesIds: this.siteIdList.length ? this.siteIdList : this.auth2Svc.utilisateur.sites.map(item => item.id),
        tachesIds: this.tachesIdList,
        page,
        size: pageSize
      };
      return this.produitSvc.searchByFilters(args)
        .toPromise()
        .then(response => {
          this.allProducts = response.resultList;
          let data = response.resultList;
          let totalCount = response.totalElements;
          const resultSelectedRows = this.dxSvc.getRowsSelectedForDeferredMode(filters, response.resultList);
          if (resultSelectedRows) return resultSelectedRows;
          if (this.produitsSelected?.length) {
            data = data.filter(p => !this.produitsSelected.filter(ps => ps.id === p.id).length);
            totalCount -= this.produitsSelected.length;
          }
          if (this.allUdpMcpProduits?.length) {
            data = data.filter(p => !this.allUdpMcpProduits.filter(udpMcpP => udpMcpP.produit.id === p.id).length);
            totalCount -= this.allUdpMcpProduits.length;
          }
          return {data, totalCount};
        });
    }
  });

  deleteRows = (): void => {
    const dataStepOne: any = this.wizardSvc.getDataStepOne();
    const dataStepTwo: any = this.wizardSvc.getDataStepTwo();
    if (this.selectedRows.length)
      this.selectedRows.forEach(idProduct => {
        this.implMcpSvc.delete(undefined, dataStepOne.idsUdpForModelePlat, dataStepTwo.id, idProduct)
          .subscribe((res) => {
            this.produitsSelected = this.produitsSelected.filter(ps => ps.id !== idProduct);
            this.selectedRows = this.selectedRows.filter(sr => sr !== idProduct);
            this.wizardSvc.saveStepSix({ idsProducts: this.produitsSelected.map(ps => ps.id) });
            this.grid.instance.refresh();
            this.gridBinds.instance.refresh();
          });
      });
  }

  loadFilterFamillesProduitsSubscription = (): void => {
    this.gds.getAll(this.familleProduitSvc.getEntityName(), this.familleProduitSvc.getSort(), true).subscribe(response => {
      this.allFamillesProduits = response.resultList.filter((r: FamilleProduitDTO) => r.fabrique === true);
    });
  };

  loadFilterDeclinaisonsSubscription = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.graphQlSvc.sendQuery(`
      {
          allDeclinaisons(filters: {
          siteIds: [${idsSites}]
        }) {
              id,
              libelle
          }
      }
    `).subscribe(response => {
      this.allDeclinaisons = response.allDeclinaisons;
    });
  };

  loadFilterRegimesSubscription = (): void => {
    this.getRegimesAlimentaires().subscribe(response => {
      this.allRegimes = response.allRegimesAlimentaires;
    });
  };

  getRegimesAlimentaires = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allRegimesAlimentaires(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            libelle,
        }
      }
    `);
  };

  loadFilterTachesSubscription = (): void => {
    this.tachesSvc.getAll()
      .subscribe(response => {
        this.allTaches = response.resultList;
      });
  };

  loadFilterSites = (): void => {
    this.sitesUtilisateurConnecte = this.auth2Svc.utilisateur.sites;
  };

  onChangeTypeFabricationFilter = ($event): void => {
    this.tachesIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeRegimeFilter = ($event): void => {
    this.regimeIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeSiteFilter = ($event): void => {
    this.siteIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeCodeFilter = ($event): void => {
    this.code = $event.value;
    this.grid.instance.refresh();
  };

  onChangePlatLibelleFilter = ($event): void => {
    this.platLibelle = $event.value;
    this.grid.instance.refresh();
  };

  onChangeParentDenreeSelection = (event: any): void => {
    const selectedNodeKeys: any = event.component.getSelectedNodeKeys();
    this.familleProduitIdList = this.getIdFamilleProduitListSelected(selectedNodeKeys);
    this.grid.instance.refresh();
  }

  getIdFamilleProduitListSelected = (idList: number[]): number[] => {
    let result = [];

    idList.forEach(id => {
      this.allFamillesProduits.forEach(fp => {
        if (fp.parent && fp.parent.id === id) {
          result.push(fp.id);
          result = [...result].concat(this.reflexionSearchId([...this.allFamillesProduits], fp.id));
        }
      })
    });

    result = [...result].concat(idList);
    return [...new Set(result)];
  }

  reflexionSearchId = (familleProduitList: FamilleProduitDTO[], idSearch: number): number[] => {
    let idResultList: number[] = [];
    familleProduitList.forEach(item => {
      if (item.parent && item.parent.id === idSearch) {
        idResultList.push(item.id);
        idResultList = [...idResultList].concat(this.reflexionSearchId([...familleProduitList], item.id));
      }
    });
    return idResultList;
  }

  currentStepPourcentage = (): number => this.wizardGlobalSvc.calculateCurrentStepPourcentage(this.wizardSvc.totalSteps, this.currentStep);

  cellClick = (e: any) => this.selectAll = (e.rowType === 'header');

}
