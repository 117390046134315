import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {STATUTS_MENUS} from "app/core/constants";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {PointDeLivraisonService} from "../../../../core/services/entities/point-de-livraison.service";
import {Subscription} from "rxjs";
import {ConfigurationPortailDTO} from "../../../../core/dtos/portail/configuration-portail-dto";
import {ToastService} from "../../../../core/services/technique/toast.service";
import RepasCommandeClientDTO from "../../../../core/dtos/planification/repas-commande-client-dto";
import PrestationsEffectifsCommandesClientsDTO
  from "../../../../core/dtos/planification/prestations-effectifs-commandes-clients-dto";
import DatesDetailsFromMenuDTO from "../../../../core/dtos/planification/dates-details-from-menu-dto";
import {CommandesClientService} from "../../../../core/services/entities/commandes-client.service";
import {TypeEffectifEnum} from "../../../../core/enums/type-effectif-enum";
import RowEffectifsCommandesClientDTO from "../../../../core/dtos/planification/row-effectifs-commandes-client-dto";
import PrestationCommandeClientDTO from "../../../../core/dtos/planification/prestation-commande-client-dto";

@Component({
  selector: 'yo-generic-repas-plc',
  templateUrl: './generic-repas-plc.component.html',
  styleUrls: ['./generic-repas-plc.component.scss']
})
export class GenericRepasPlcComponent implements OnInit, OnDestroy {

  @Input("configPortailPlc") configPortailPlc: ConfigurationPortailDTO;
  @Input("repas") repas: RepasCommandeClientDTO;
  @Input("prestations") prestations: PrestationsEffectifsCommandesClientsDTO[];
  @Input("datesDetailsFromMenu") datesDetailsFromMenu: DatesDetailsFromMenuDTO;
  @Input("cols") cols: Date[];

  subSelectedCell: Subscription;
  subTypeEffectif: Subscription;

  STATUTS_MENUS = STATUTS_MENUS;
  TypeEffectifEnum = TypeEffectifEnum;
  typeEffectif = TypeEffectifEnum.PREVISIONNEL;
  colWidth: 80;

  selectedCell: RowEffectifsCommandesClientDTO;
  selectedPrestation: PrestationCommandeClientDTO;

  cellsUpdated: RowEffectifsCommandesClientDTO[];

  timer: any;

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private plcSvc: PointDeLivraisonService,
              private toastSvc: ToastService,
              private commandesClientsSvc: CommandesClientService) {
  }

  ngOnInit(): void {
    this.typeEffectifSubscription();
    this.selectedCellSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subTypeEffectif);
    this.utils.unsubscribe(this.subSelectedCell);
  }

  keyDown = event => event.target.value = event.target.value.replace(/[^0-9]/g, "");

  /**
   * Subscription qui permet de récupérer le type d'effectif sélectionné
   */
  typeEffectifSubscription = () => {
    this.subTypeEffectif = this.commandesClientsSvc.selectedTypeEffectif$.subscribe(response => {
      this.typeEffectif = response
    });
  };

  /**
   * Subscription qui permet de récupérer la cellule sélectionnée
   */
  selectedCellSubscription = () => {
    this.subSelectedCell = this.commandesClientsSvc.selectedCell$
      .subscribe(response => this.selectedCell = response);
  };

  /**
   * Méthode qui permet d'afficher les détails de la cellule sélectionnée
   */
  showRepas = (cell: RowEffectifsCommandesClientDTO) => {
    this.selectedCell = cell;
    this.selectedPrestation = this.prestations.find(p => p.rows.find(row => row.cmcrPlcDate.id === cell.cmcrPlcDate.id)).prestation;
    this.commandesClientsSvc.announceSelectedCell(this.selectedCell);
    this.commandesClientsSvc.announceSelectedRepas(this.repas);
    this.commandesClientsSvc.announceSelectedPrestation(this.selectedPrestation);

    if (this.selectedCell) {
      this.commandesClientsSvc
        .announceDetailsEffectifCommandeClient(this.selectedCell.details.filter(detail => detail.typeEffectif.toString() === this.typeEffectif.toString()));
    }
  };

  /**
   * Méthode qui permet de permettre, ou non, à un utilisateur de modifier un effectif selon la cellule
   */
  isEffectifInputDisabled = (cell: RowEffectifsCommandesClientDTO): boolean => {
    if (this.configPortailPlc.dateLimiteSaisie < cell.date) {
      if (this.showStatutMode(cell) != STATUTS_MENUS.SAISIE_EFFECTIFS) return true;
      else return !this.auth2Svc.isSiteLocal(cell.cmcrPlcDate.cmSiteId);
    } else return true;
  };

  /**
   * Méthode qui permet d'afficher le statut du menu
   */
  showStatutMode = (row: RowEffectifsCommandesClientDTO) => {
    if (this.datesDetailsFromMenu.datesCreationMenus.find(d => d === row.date)) return STATUTS_MENUS.CREATION_MENUS;
    else if (this.datesDetailsFromMenu.datesSaisieEffectifs.find(d => d === row.date)) return STATUTS_MENUS.SAISIE_EFFECTIFS;
  }

  /**
   * Méthode qui permet d'afficher le régime associé à la cellule sélectionnée
   */
  showRegime = (item: PrestationsEffectifsCommandesClientsDTO) => item.rows[0].regime.libelle;

  /**
   * Méthode qui permet de modifier l'effectif de la cellule sélectionnée
   */
  changeEffectif = ($event: number, cell: RowEffectifsCommandesClientDTO) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.selectedCell = cell;
      if (this.typeEffectif === TypeEffectifEnum.PREVISIONNEL) cell.effectifPrevisionnel = $event;
      if (this.typeEffectif === TypeEffectifEnum.FABRICATION) cell.effectifFabrication = $event;
      if (this.typeEffectif === TypeEffectifEnum.FACTURATION) cell.effectifFacturation = $event;

      this.commandesClientsSvc.announceRowUpdated(cell);
    }, 25);
  };

  /**
   * Méthode qui permet de récupérer l'effectif lié à la colonne, donc ici lié à la date
   */
  getEffectif = (col: Date, rows: RowEffectifsCommandesClientDTO[]) => {
    return rows.find(row => new Date(row.date).getDate() === col.getDate());
  };
}
