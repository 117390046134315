import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {LitigeDTO} from '../../dtos/litige-dto';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {startCase as _startCase} from 'lodash';
import {Observable, Subject} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";
import {ObjectDTO} from "../../dtos/object-dto";
import {HttpService} from "../technique/http.service";
import {GraphQLService} from "../technique/graphql.service";

@Injectable({
  providedIn: 'root'
})
export class LitigesService extends GenericHandler<LitigeDTO> {

  private subjectOpenDialog = new Subject<LitigeDTO>();
  openDialogEdition$ = this.subjectOpenDialog.asObservable();

  private subjectLitigeSaved = new Subject<LitigeSavedSupplier>();
  litigeSaved$ = this.subjectLitigeSaved.asObservable();

  exportLitigeByFilters = (rowsToExport?: number[]) => {
    let params: string = '?1=1';
    if (rowsToExport && rowsToExport.length) params += `&ids=${rowsToExport.join(',')}`
    return this.http.get(`dolrest/referentiel/reception/litiges/export?${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  };

  importLitigeFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post("dolrest/referentiel/reception/litiges/import", formData, null);
  };

  deleteLitigeByFilters = (rowsToDelete?: number[]) => {
    let params: string = '?1=1';
    if (rowsToDelete && rowsToDelete.length) params += `&ids=${rowsToDelete.join(',')}`
    return this.http.delete(`dolrest/referentiel/reception/litiges/delete?${params}`);
  };

  announceOpenDialogEdition = (litige: LitigeDTO) => {
    this.subjectOpenDialog.next(litige);
  };

  announceLitigeSaved = (litige: LitigeDTO, isUpdate: boolean = false) => {
    this.subjectLitigeSaved.next({isUpdate, litige} as LitigeSavedSupplier);
  }

  save = (litige: LitigeDTO): Observable<ResponseWrapper<LitigeDTO>> => this.httpSvc.post("dolrest/referentiel/reception/litiges/save", litige);

  canModify(element: ObjectDTO): boolean {
    if (this.utils.isNullOrEmpty(element?.site?.id) || element.site.id == 0)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }

  litigesEnvironnements: LitigeDTO[];

  constructor(
    utils: UtilsService,
    auth2Svc: Auth2Service,
    router: Router,
    http: HttpClient,
    title: Title,
    private httpSvc: HttpService,
    private graphQlSvc: GraphQLService) {

    super(utils, auth2Svc, router, http, title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });


  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  createEmptyDTO(): LitigeDTO {
    return undefined;
  }

  getAllFromEnvironnement(): void {

    console.log("litigesService#getAllFromEnvironnement()");
    this.getLitiges().subscribe(response => {
      this.litigesEnvironnements = [];

      if (!this.utils.isCollectionNullOrEmpty(response.allLitiges)) {
        this.litigesEnvironnements = response.allLitiges.map(item => {
          item.libelle = item.libelle.toUpperCase();
          return item;
        });
      }

      this.announceObjects(this.litigesEnvironnements);

    });

  }

  getLitiges = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allLitiges(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            libelle,
        }
      }`);
  }

  getCreateNewObjectLabel(): string {
    return "CRÉER UN LITIGE";
  }

  getEditObjectLabel(data: LitigeDTO): string {
    return "MODIFIER UN LITIGE";
  }

  getEntityName(): string {
    return "litige";
  }

  getFields(dto: LitigeDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut un Litige est actif.
    if (this.utils.isNullOrEmpty(dto) || dto.id == 0 || dto.id == undefined) {
      dto = new LitigeDTO();
      dto.actif = true;
    }

    const readonly = !this.canModifySite(dto);

    let formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),


      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: false,
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        excelType: 'string',
        order: 2
      }),


      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        readonly: false,
        value: dto.code,
        maxLength: 50,
        required: true,
        excelType: 'string',
        order: 4
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        excelType: 'boolean',
        order: 6
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 7
      }),


    ];

    return formFields.sort((a, b) => a.order - b.order);
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getTitle(): string {
    return "GESTION DES LITIGES";
  }
}

export interface LitigeSavedSupplier {
  isUpdate: boolean;
  litige: LitigeDTO;
}
