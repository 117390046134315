import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {ModelViewMatierePremiere} from '../model-view-matiere-premiere';
import {LOTS_PERIMES, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {ToastService} from "../../../../core/services/technique/toast.service";
import {StocksService} from "../../../../core/services/gestion-stock/stocks.service";

@Component({
  selector: 'yo-dialog-perimes',
  templateUrl: './dialog-perimes.component.html',
  styleUrls: ['./dialog-perimes.component.scss']
})
export class DialogPerimesComponent implements OnInit, OnDestroy {

  subAction: Subscription;


  displayDialog = false;
  selectedMatierePremiere: ModelViewMatierePremiere;
  lotsArticlesLoaded: string[];

  LOTS_PERIMES = LOTS_PERIMES;

  // T = tous les périmés, S = produit sélectionné
  deleteOption = LOTS_PERIMES.DENREE_SELECTIONNEE;

  deleteOptions: any[] = [];

  constructor(public utils: UtilsService,
              private stockSvc: StocksService,
              private toastSvc: ToastService) {}

  ngOnInit() {
    this.subAction = this.stockSvc.openDialogPerimes$.subscribe(response => {
      this.displayDialog = true;
      this.selectedMatierePremiere = response.selectedMatierePremiere;
      this.lotsArticlesLoaded = response.lotsArticlesLoaded;
      this.deleteOptions.push({ label: `Éliminer tous les lots périmés qui correspondent aux critères de recherche (page courante)`, value: LOTS_PERIMES.TOUS });
      if (!this.utils.isNullOrEmpty(this.selectedMatierePremiere)) {
        this.deleteOption = this.selectedMatierePremiere.noStock ? LOTS_PERIMES.TOUS : LOTS_PERIMES.DENREE_SELECTIONNEE;
        if (!this.selectedMatierePremiere.noStock)
          this.deleteOptions.push({ label: `Éliminer les lots périmés de la denrée sélectionnée`, value: LOTS_PERIMES.DENREE_SELECTIONNEE });
      } else {
        this.deleteOption = LOTS_PERIMES.TOUS;
      }

    });
  }

  closeDialog = () => {
    this.deleteOptions = [];
    this.displayDialog = false;
  };

  ngOnDestroy(): void {
    this.deleteOptions = [];
    this.utils.unsubscribe(this.subAction);
  }

  deletePerimes = () => {
    let lotsToDisable = this.lotsArticlesLoaded;
    if(this.deleteOption === LOTS_PERIMES.DENREE_SELECTIONNEE)
      lotsToDisable = this.selectedMatierePremiere?.children?.map(c => c.lotArticle);

    this.stockSvc.deleteLotsPerimes(lotsToDisable).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.closeDialog();
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les lots périmés ont été enlevés du stock avec succès`);
        this.stockSvc.announceLotsDeleted();
      }
    });
  };
}
