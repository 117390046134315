<dx-popup
  [width]="900"
  [height]="'auto'"
  [showTitle]="true"
  [(visible)]="displayDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeDialog()"
>
  <div *dxTemplate="let data of 'title'">
    <div style="padding: 10px 0">
      <i class="fas fa-print mg-r-5"></i> Impression des effectifs
    </div>
  </div>


  <form novalidate>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Mode <span class="danger-color">*</span>
      </div>
      <div class="col-md-8">
        <dx-tag-box
          #tagMode
          [multiline]="true"
          [items]="modeOptionsList"
          placeholder="Selection..."
          displayExpr="label"
          valueExpr="value"
          [searchEnabled]="true"
          [value]="selectedMode"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeMode($event)">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Repas <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <dx-tag-box
          #tagRepas
          [multiline]="true"
          [items]="repasList"
          [value]="selectedRepasList"
          placeholder="Filtrer par repas..."
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeRepas($event)">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Régimes <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <dx-tag-box
          #tagRegime
          [multiline]="true"
          [items]="modeRegimesList"
          [value]="selectedRegimes"
          placeholder="Filtrer par régimes..."
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeRegimes($event)">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10 float-right">
      <div class="col-md-12">
        <yo-button
          iconClass="fas fa-print"
          (onClick)="printEffectif()"
          tooltip="Imprimer les effectifs"
          label="Imprimer"
        ></yo-button>
        <span class="mg-l-5">
          <yo-button
            iconClass="fas fa-times"
            buttonClass="cta-inner-delete"
            (onClick)="closeDialog()"
            tooltip="Fermer la boîte de dialogue"
            label="Fermer"
          ></yo-button>
        </span>
      </div>
    </div>
  </form>
</dx-popup>
