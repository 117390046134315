import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Subject} from 'rxjs';
import {WorkflowInstanceDTO} from '../../dtos/workflow-instance-dto';
import {PreferencesUtilisateurService} from '../preferences-utilisateur.service';
import {ListeBesoinsDenreeDto} from '../../dtos/liste-besoins-denree-dto';
import {ListeBesoinsDto} from '../../dtos/liste-besoins-dto';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {HttpService} from "../technique/http.service";
import {UniteDeProductionDTO} from "../../dtos/unite-de-production-dto";
import {catchError} from "rxjs/operators";

export const URL_GET_BESOINS_ARTICLES = `dolrest/gestion-listes-besoins/liste-besoins/articles`;
export const URL_GET_BESOINS_ARTICLES_XLS = `dolrest/gestion-listes-besoins/liste-besoins/articles-xls`;
export const URL_POST_REPLACE_LBD_ARTICLE = `dolrest/gestion-listes-besoins/liste-besoins/lbd/replace-article`;
export const URL_POST_SAVE_LBD_LIST = `dolrest/gestion-listes-besoins/liste-besoins/lbd/save-list`;
export const URL_GET_ARTICLES_DISPONIBLES = `dolrest/gestion-listes-besoins/liste-besoins/lbd/articles-disponibles`;
export const URL_POST_CHANGE_REGLE_PREFERE = `dolrest/gestion-listes-besoins/liste-besoins/change-regle-prefere`;

@Injectable({
  providedIn: 'root'
})
export class BesoinsService {

  private subjectPlatsDemandeurs = new Subject();
  platsDemandeurs$ = this.subjectPlatsDemandeurs.asObservable();

  private subjectLbdView = new Subject();
  lbdView$ = this.subjectLbdView.asObservable();

  private subjectCalculReglePrefereTermine = new Subject();
  calculReglePrefereTermine$ = this.subjectCalculReglePrefereTermine.asObservable();

  private subjectOpenPropositionDialogDenree = new Subject();
  openPropositionDialogDenree$ = this.subjectOpenPropositionDialogDenree.asObservable();

  private subjectOpenAjoutDenreePropositionCommandeDialog = new Subject();
  openAjoutDenreePropositionCommande$ = this.subjectOpenAjoutDenreePropositionCommandeDialog.asObservable();

  constructor(private prefUsrSvc: PreferencesUtilisateurService,
              private gds: GenericDatagridService,
              private utils: UtilsService,
              private httpSvc: HttpService,
              private http: HttpClient) {
  }

  announcePlatsDemandeurs(event: any) {
    this.subjectPlatsDemandeurs.next(event);
  }

  announceLbdView(event: any) {
    this.subjectLbdView.next(event);
  }

  announceCalculReglePrefereTermine(event: any) {
    this.subjectCalculReglePrefereTermine.next(event);
  }

  announceOpenPropositionDialogDenree(event: any) {
    this.subjectOpenPropositionDialogDenree.next(event);
  }

  announceOpenAjoutDenreePropositionCommande(uniteDeProductionSelected: UniteDeProductionDTO, dateLivraison: Date) {
    this.subjectOpenAjoutDenreePropositionCommandeDialog.next({ idUniteProduction: uniteDeProductionSelected ? uniteDeProductionSelected.id : null, dateLivraison });
  }

  /**
   * Indique si la {@link ListeBesoinsDto} passée en paramètre est modifiable.
   * @param listeBesoinDTO
   */
  canModify(listeBesoinDTO: ListeBesoinsDto): boolean {
    const canModify = this.gds.canModify(listeBesoinDTO) && !listeBesoinDTO.archive;
    return canModify;
  }

  getPlatsDemandeursOfArticle(lbd: ListeBesoinsDenreeDto) {
    const params: HttpParams = new HttpParams()
      .set('idListeBesoin', lbd.listeBesoinId + '')
      .set('idUniteDeProduction', lbd.uniteDeProductionId + '')
      .set('denreeInitialeId', lbd.denreeInitialeId + '')
      .set('idProduitDeclinaison', lbd.produitDeclinaisonId + '')
      .set('dateConso', this.utils.convertYYYYMMdd(lbd.dateConsommation));
    return this.httpSvc.get(`dolrest/gestion-listes-besoins/plats-demandeurs/article`, params);
  }

  getLbdList(idWorkflowInstance: number) {
    const params: HttpParams =new HttpParams().set('idWorkflowInstance', idWorkflowInstance + '');
    return this.httpSvc.get(URL_GET_BESOINS_ARTICLES, params);
  }

  getLbdListXls = (idWorkflowInstance: number) => {
    return this.http.get(`${URL_GET_BESOINS_ARTICLES_XLS}?idWorkflowInstance=${idWorkflowInstance}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    }).pipe(
        catchError(error => this.utils.handleError(error))
      );
  };

  saveLbdList(lbdList: ListeBesoinsDenreeDto[]) {
    return this.httpSvc.post(URL_POST_SAVE_LBD_LIST, lbdList.map(l => ({ id: l.id, dateConsommation: l.dateConsommation, dateLivFournisseur: l.dateLivFournisseur,
      dateAppro: l.dateAppro, dateSortie: l.dateSortie, dateFabrication: l.dateFabrication, besoinAjuste: l.besoinAjuste,
      modificationManuelle: true, fournisseurId: l.fournisseurId, catalogueAchatId: l.catalogueAchatId }) ));
  }

  replaceLbdArticle(lbd: ListeBesoinsDenreeDto) {
    return this.httpSvc.post(URL_POST_REPLACE_LBD_ARTICLE, lbd);
  }

  /**
   *
   * Relancer le calcul des articles preferes sur l'instance de workflow
   * @param workflowInstance
   */
  changeReglePrefere(workflowInstance: WorkflowInstanceDTO) {
    return this.httpSvc.post(URL_POST_CHANGE_REGLE_PREFERE, workflowInstance);
  }

  getArticlesDisponibles = (idProduitDeclinaison: number, idUniteDeProduction: number, dateLivraisonFournisseur?: Date) => {
    if(dateLivraisonFournisseur)
      return this.httpSvc.get(`${URL_GET_ARTICLES_DISPONIBLES}?idProduitDeclinaison=${idProduitDeclinaison}&idUniteDeProduction=${idUniteDeProduction}&dateLivraisonFournisseur=${new Date(dateLivraisonFournisseur).toDateString()}`);
    return this.httpSvc.get(`${URL_GET_ARTICLES_DISPONIBLES}?idProduitDeclinaison=${idProduitDeclinaison}&idUniteDeProduction=${idUniteDeProduction}`);
  }
}
