<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <div class="mg-r-5">
        <dx-select-box
          [dataSource]="plcList"
          placeholder="Sélectionner un point de livraison..."
          displayExpr="libelle"
          (onValueChanged)="updatePlc($event)"
          [searchMode]="searchModeOption"
          [searchExpr]="searchExprOption"
          [minSearchLength]="0"
          [searchEnabled]="true"
          [value]="selectedPlc"
          [(ngModel)]="selectedPlc"
        ></dx-select-box>
      </div>

      <div class="mg-r-5">
        <p-calendar
          firstDayOfWeek="1"
          dateFormat="'{{getWeekLabel(week)}}'"
          tooltipPosition="bottom"
          showDelay="500"
          [numberOfMonths]="2"
          [style]="{'width':'280px'}"
          (onSelect)="updateDatesBySelection($event)"
          (onClickOutside)="updtateDatesByClickOutside()"
          (onMonthChange)="updateDatesByMonth($event)"
          (onYearChange)="updateDatesByMonth($event)"
          [readonlyInput]="true"
          [(ngModel)]="selectedDate"
          [locale]="localeFr"
          [showIcon]="true"
          [showWeek]="true"
          [disabledDays]="disabledDays"
          [minDate]="dateMin"
        >
          <ng-template pTemplate="date" let-date>
            <yo-date-dispo [date]="date" [dateSaisieEffectif]="utils.isDateInList(date,datesSaisieEffectifs)"
                           [dateCreationMenu]="utils.isDateInList(date,datesCreationMenus)"></yo-date-dispo>
          </ng-template>
        </p-calendar>
      </div>
    </div>
  </dxi-item>
</dx-toolbar>
<div class="d-flex mg-t-14 flex-wrap container-table" style="overflow-y: scroll">
  <div class="left">

    <div class="sub-left">
      <yo-generic-prestations-semaine
        [cols]="cols"
        [configPortailPlc]="configPortailPlc">
      </yo-generic-prestations-semaine>
      <div class="card-footer mg-t-14">
        <span class="font-weight-normal font-12">
          <div class="text-left">
            <i class="fas fa-square save-color mg-r-5 border"></i>Effectifs en cours de saisie
          </div>
          <div class="text-left">
            <i class="fas fa-square site-secondaire-color mg-r-5 border"></i>Menus en cours de saisie
          </div>
          <div class="text-left">
            <i class="fas fa-square checked-color mg-r-5 border"></i>Menus validés = Saisie des effectifs possible
          </div>
          <div class="text-left">
            <i class="fas fa-square white mg-r-5 border"></i>Aucun menu
          </div>
        </span>
      </div>
    </div>
  </div>
  <div class="right">
    <yo-generic-repas
      [configPortailPlc]="configPortailPlc">
    </yo-generic-repas>
  </div>
</div>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
>
</dx-load-panel>





