import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {OrigineCarneDTO} from "../../../core/dtos/origine-carne-dto";
import {LookUpComponent} from "../lookup/lookup.component";
import {OrigineDTO} from "../../../core/dtos/origine-dto";

@Component({
  selector: 'yo-origine-carne',
  templateUrl: './origine-carne.component.html',
  styleUrls: ['./origine-carne.component.scss']
})
export class OrigineCarneComponent implements OnInit {

  @Input() origines: any[];
  @Input() disabled: boolean = false;

  @Output() onValueChange = new EventEmitter;

  selectedNaissance: OrigineDTO;
  selectedElevage: OrigineDTO;
  selectedAbattage: OrigineDTO;

  visible: boolean = false;

  @ViewChild("naissance") naissanceLookUp: LookUpComponent;
  @ViewChild("elevage") elevageLookUp: LookUpComponent;
  @ViewChild("abattage") abattageLookUp: LookUpComponent;

  ngOnInit(): void {
  }

  getSelectedItems = (): OrigineCarneDTO => {
    return {
      naissance: this.naissanceLookUp?.getSelectedItem(),
      elevage: this.elevageLookUp?.getSelectedItem(),
      abattage: this.abattageLookUp?.getSelectedItem()
    };
  }

  onOriginChange = () => {
    let origines: OrigineCarneDTO = this.getSelectedItems();
    this.updateOrigines(origines)
    this.onValueChange.emit(origines);
  }

  updateOrigines = (origines: OrigineCarneDTO): void => {
    this.selectedNaissance = origines?.naissance;
    this.selectedElevage = origines?.elevage;
    this.selectedAbattage = origines?.abattage;
  }

  openDialog = (defaultOriginesCarne: OrigineCarneDTO) => {
    this.updateOrigines(defaultOriginesCarne);
    this.visible = true;
  }

  getOriginePlaceholder(origineName: string, selectedId: number) {
    if (this.disabled && !selectedId)
      return `Aucun lieu ${origineName} sélectionné`
    return `Choisir un lieu ${origineName}`;
  }
}
